/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateCreateFromJiraIssueRequest
 */
export interface TemplateCreateFromJiraIssueRequest {
    /**
     * ID of the Jira issue to create template from
     * @type {string}
     * @memberof TemplateCreateFromJiraIssueRequest
     */
    sourceId: string;
    /**
     * Name of the template
     * @type {string}
     * @memberof TemplateCreateFromJiraIssueRequest
     */
    name: string;
}

/**
 * Check if a given object implements the TemplateCreateFromJiraIssueRequest interface.
 */
export function instanceOfTemplateCreateFromJiraIssueRequest(value: object): value is TemplateCreateFromJiraIssueRequest {
    if (!('sourceId' in value) || value['sourceId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function TemplateCreateFromJiraIssueRequestFromJSON(json: any): TemplateCreateFromJiraIssueRequest {
    return TemplateCreateFromJiraIssueRequestFromJSONTyped(json, false);
}

export function TemplateCreateFromJiraIssueRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateCreateFromJiraIssueRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceId': json['sourceId'],
        'name': json['name'],
    };
}

export function TemplateCreateFromJiraIssueRequestToJSON(value?: TemplateCreateFromJiraIssueRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sourceId': value['sourceId'],
        'name': value['name'],
    };
}

