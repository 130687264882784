import NewTemplateFromIssue, {
  Props as NewTemplateFromIssueProps,
} from "./NewTemplateFromIssue"

type Props = NewTemplateFromIssueProps

const Inline = ({
  onClose,
  onCompleted
}: Props) => {
  return (
    <div
      data-testid="create-template-inline"
      style={{
        border: "1px solid #dedede",
        padding: "0 16px",
        borderRadius: 8,
      }}
    >
      <NewTemplateFromIssue
        onCompleted={onCompleted}
        onClose={onClose}
      />
    </div>
  )
}

export default Inline
