import { Jira } from "@easy-templates/types";

export const getIssue = async (
  requestJira: Jira.AdapterInterface["request"],
  idOrKey: string
): Promise<Jira.IssueBean> => {
  const response = await requestJira<Jira.IssueBean>(
    `/rest/api/3/issue/${idOrKey}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response;
};
