import { useQuery } from "@tanstack/react-query"
import { TemplateIssue } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

export type DataFetchResult = {
  isLoading: boolean
  error?: Error
  issue: TemplateIssue
}

const useData = (templateId: string, id?: string): DataFetchResult => {
  const { core, project } = useAppContext()

  const { data, isLoading, error } = useQuery<TemplateIssue, Error>({
    enabled: !!id,
    queryKey: templateKeys.issue(templateId, id),
    queryFn: async () => await core.getTemplateIssue(templateId, id, project?.id),
  })

  return { isLoading, error, issue: data }
}

export default useData
