import BasicField from './basic'

export default class AssigneeField extends BasicField {
  prefillValue() {
    if (Object.hasOwnProperty.call(this.body, 'accountId')) {
      return this.body['accountId']
    }

    if (Object.hasOwnProperty.call(this.body, 'id')) {
      return this.body['id']
    }

    if (Object.hasOwnProperty.call(this.body, 'value')) {
      return this.body['value']
    }

    return this.body
  }
}
