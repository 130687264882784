import ModalDialog, {
  ModalBody,
  ModalTitle,
  ModalHeader,
} from "components/ui/ModalDialog"

import { useAppContext } from "components/AppContextProvider"

import Main from "./Main"

const Dialog = () => {
  const { core } = useAppContext()

  return (
    <ModalDialog shouldScrollInViewport={true} autoFocus={false} stackIndex={1}>
      <ModalHeader>
        <ModalTitle>Create Issues from Template</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Main onClose={() => core.closeNewIssueDialog()} />
      </ModalBody>
    </ModalDialog>
  )
}

export default Dialog
