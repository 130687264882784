/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateUpdateRequest
 */
export interface TemplateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TemplateUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateUpdateRequest
     */
    createChildren?: boolean;
    /**
     * 0 - Global
     * 1 - Selected Projects
     * @type {number}
     * @memberof TemplateUpdateRequest
     */
    scope?: TemplateUpdateRequestScopeEnum;
    /**
     * List of project IDs for the scope. Other scope values in the future.
     * @type {Array<string>}
     * @memberof TemplateUpdateRequest
     */
    scopeValue?: Array<string>;
}


/**
 * @export
 */
export const TemplateUpdateRequestScopeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type TemplateUpdateRequestScopeEnum = typeof TemplateUpdateRequestScopeEnum[keyof typeof TemplateUpdateRequestScopeEnum];


/**
 * Check if a given object implements the TemplateUpdateRequest interface.
 */
export function instanceOfTemplateUpdateRequest(value: object): value is TemplateUpdateRequest {
    return true;
}

export function TemplateUpdateRequestFromJSON(json: any): TemplateUpdateRequest {
    return TemplateUpdateRequestFromJSONTyped(json, false);
}

export function TemplateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'createChildren': json['createChildren'] == null ? undefined : json['createChildren'],
        'scope': json['scope'] == null ? undefined : json['scope'],
        'scopeValue': json['scopeValue'] == null ? undefined : json['scopeValue'],
    };
}

export function TemplateUpdateRequestToJSON(value?: TemplateUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'createChildren': value['createChildren'],
        'scope': value['scope'],
        'scopeValue': value['scopeValue'],
    };
}

