import generateId from './generate-id'

export default class VariablesExtractor {
  public userDefinedVariableRegex = /\<\<\s*(?<label>[\w\s?\-]+)(?<required>\*)?\s*:?\s*(?<description>[\w\?\s]*)\>\>/g

  fieldWithoutVariables = [
    "components",
    "created",
    "creator",
    "duedate",
    "issuetype",
    "parent",
    "priority",
    "project",
    "resolution",
    "timespent"
  ]

  from(text: string, fieldName?: string) {
    if (!text || fieldName && this.fieldWithoutVariables.includes(fieldName)) {
      return []
    }

    const result = Array.from(
      text
        .toString()
        .matchAll(this.userDefinedVariableRegex)
    ).reduce((memo, { groups: { label, required, description } }) => {
      const upcaseLabel = label.trim().toUpperCase()
      const existingRecord = memo[upcaseLabel]

      if (existingRecord && existingRecord.required) {
        return memo
      }

      return {
        ...memo,
        [upcaseLabel]: {
          id: generateId(),
          fieldName: fieldName,
          description: description,
          label,
          required: !!required,
          type: 'text',
          source: 'user'
        }
      }
    }, {})

    return Object.values(result)
  }

  replaceVariableLabel(text: string, labelBefore: string, labelAfter?: string) {
    const replacement = labelAfter ? `<<${labelAfter}>>` : ''

    return text.replace(new RegExp(`\<\<\\s*${labelBefore}(\\*)?\\s*:?[^\>]*\>\>`, 'gi'), replacement)
  }

  variableRegex() { return this.userDefinedVariableRegex }
}
