/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotFound,
  ValidationErrors,
  Variable,
  VariableCreateRequest,
  VariableRankRequest,
  VariableResponse,
} from '../models/index';
import {
    NotFoundFromJSON,
    NotFoundToJSON,
    ValidationErrorsFromJSON,
    ValidationErrorsToJSON,
    VariableFromJSON,
    VariableToJSON,
    VariableCreateRequestFromJSON,
    VariableCreateRequestToJSON,
    VariableRankRequestFromJSON,
    VariableRankRequestToJSON,
    VariableResponseFromJSON,
    VariableResponseToJSON,
} from '../models/index';

export interface VariableCreateOperationRequest {
    templateId: string;
    variableCreateRequest: VariableCreateRequest;
}

export interface VariableDeleteRequest {
    templateId: string;
    variableId: string;
}

export interface VariableGetRequest {
    templateId: string;
    variableId: string;
}

export interface VariableListRequest {
    templateId: string;
}

export interface VariableRankOperationRequest {
    templateId: string;
    variableId: string;
    variableRankRequest: VariableRankRequest;
}

export interface VariableUpdateRequest {
    templateId: string;
    variableId: string;
    variable: Variable;
}

/**
 * VariableApi - interface
 * 
 * @export
 * @interface VariableApiInterface
 */
export interface VariableApiInterface {
    /**
     * 
     * @summary Create a new variable
     * @param {string} templateId ID of the template
     * @param {VariableCreateRequest} variableCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApiInterface
     */
    variableCreateRaw(requestParameters: VariableCreateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariableResponse>>;

    /**
     * Create a new variable
     */
    variableCreate(templateId: string, variableCreateRequest: VariableCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariableResponse>;

    /**
     * 
     * @summary Delete a variable
     * @param {string} templateId ID of the template
     * @param {string} variableId ID of the variable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApiInterface
     */
    variableDeleteRaw(requestParameters: VariableDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a variable
     */
    variableDelete(templateId: string, variableId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get a template variable
     * @param {string} templateId ID of the template
     * @param {string} variableId ID of the variable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApiInterface
     */
    variableGetRaw(requestParameters: VariableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariableResponse>>;

    /**
     * Get a template variable
     */
    variableGet(templateId: string, variableId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariableResponse>;

    /**
     * 
     * @summary Get a list of the template variables
     * @param {string} templateId ID of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApiInterface
     */
    variableListRaw(requestParameters: VariableListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VariableResponse>>>;

    /**
     * Get a list of the template variables
     */
    variableList(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VariableResponse>>;

    /**
     * 
     * @summary Rank a variable
     * @param {string} templateId ID of the template
     * @param {string} variableId ID of the variable
     * @param {VariableRankRequest} variableRankRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApiInterface
     */
    variableRankRaw(requestParameters: VariableRankOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Rank a variable
     */
    variableRank(templateId: string, variableId: string, variableRankRequest: VariableRankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Update an existing variable
     * @param {string} templateId ID of the template
     * @param {string} variableId ID of the variable
     * @param {Variable} variable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApiInterface
     */
    variableUpdateRaw(requestParameters: VariableUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariableResponse>>;

    /**
     * Update an existing variable
     */
    variableUpdate(templateId: string, variableId: string, variable: Variable, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariableResponse>;

}

/**
 * 
 */
export class VariableApi extends runtime.BaseAPI implements VariableApiInterface {

    /**
     * Create a new variable
     */
    async variableCreateRaw(requestParameters: VariableCreateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariableResponse>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling variableCreate().'
            );
        }

        if (requestParameters['variableCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'variableCreateRequest',
                'Required parameter "variableCreateRequest" was null or undefined when calling variableCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/variables`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariableCreateRequestToJSON(requestParameters['variableCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableResponseFromJSON(jsonValue));
    }

    /**
     * Create a new variable
     */
    async variableCreate(templateId: string, variableCreateRequest: VariableCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariableResponse> {
        const response = await this.variableCreateRaw({ templateId: templateId, variableCreateRequest: variableCreateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a variable
     */
    async variableDeleteRaw(requestParameters: VariableDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling variableDelete().'
            );
        }

        if (requestParameters['variableId'] == null) {
            throw new runtime.RequiredError(
                'variableId',
                'Required parameter "variableId" was null or undefined when calling variableDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/variables/{variable_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"variable_id"}}`, encodeURIComponent(String(requestParameters['variableId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a variable
     */
    async variableDelete(templateId: string, variableId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.variableDeleteRaw({ templateId: templateId, variableId: variableId }, initOverrides);
    }

    /**
     * Get a template variable
     */
    async variableGetRaw(requestParameters: VariableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariableResponse>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling variableGet().'
            );
        }

        if (requestParameters['variableId'] == null) {
            throw new runtime.RequiredError(
                'variableId',
                'Required parameter "variableId" was null or undefined when calling variableGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/variables/{variable_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"variable_id"}}`, encodeURIComponent(String(requestParameters['variableId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableResponseFromJSON(jsonValue));
    }

    /**
     * Get a template variable
     */
    async variableGet(templateId: string, variableId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariableResponse> {
        const response = await this.variableGetRaw({ templateId: templateId, variableId: variableId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of the template variables
     */
    async variableListRaw(requestParameters: VariableListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VariableResponse>>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling variableList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/variables`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VariableResponseFromJSON));
    }

    /**
     * Get a list of the template variables
     */
    async variableList(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VariableResponse>> {
        const response = await this.variableListRaw({ templateId: templateId }, initOverrides);
        return await response.value();
    }

    /**
     * Rank a variable
     */
    async variableRankRaw(requestParameters: VariableRankOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling variableRank().'
            );
        }

        if (requestParameters['variableId'] == null) {
            throw new runtime.RequiredError(
                'variableId',
                'Required parameter "variableId" was null or undefined when calling variableRank().'
            );
        }

        if (requestParameters['variableRankRequest'] == null) {
            throw new runtime.RequiredError(
                'variableRankRequest',
                'Required parameter "variableRankRequest" was null or undefined when calling variableRank().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/variables/{variable_id}/rank`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"variable_id"}}`, encodeURIComponent(String(requestParameters['variableId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariableRankRequestToJSON(requestParameters['variableRankRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rank a variable
     */
    async variableRank(templateId: string, variableId: string, variableRankRequest: VariableRankRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.variableRankRaw({ templateId: templateId, variableId: variableId, variableRankRequest: variableRankRequest }, initOverrides);
    }

    /**
     * Update an existing variable
     */
    async variableUpdateRaw(requestParameters: VariableUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariableResponse>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling variableUpdate().'
            );
        }

        if (requestParameters['variableId'] == null) {
            throw new runtime.RequiredError(
                'variableId',
                'Required parameter "variableId" was null or undefined when calling variableUpdate().'
            );
        }

        if (requestParameters['variable'] == null) {
            throw new runtime.RequiredError(
                'variable',
                'Required parameter "variable" was null or undefined when calling variableUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/variables/{variable_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"variable_id"}}`, encodeURIComponent(String(requestParameters['variableId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VariableToJSON(requestParameters['variable']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing variable
     */
    async variableUpdate(templateId: string, variableId: string, variable: Variable, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariableResponse> {
        const response = await this.variableUpdateRaw({ templateId: templateId, variableId: variableId, variable: variable }, initOverrides);
        return await response.value();
    }

}
