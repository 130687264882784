import React from "react";
import ErrorState from "components/ErrorState";

type Props = {
  userId?: string;
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<
  Props,
  { hasError: boolean; eventId: string | null; error: Error | undefined }
> {
  constructor(props: Props) {
    super(props);
    this.state = { eventId: null, error: undefined, hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return <ErrorState error={this.state.error} userId={this.props.userId} />;
    }

    //when there's not an error, render children untouched
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
