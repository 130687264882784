/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { VariableRankRequestOneOf } from './VariableRankRequestOneOf';
import {
    instanceOfVariableRankRequestOneOf,
    VariableRankRequestOneOfFromJSON,
    VariableRankRequestOneOfFromJSONTyped,
    VariableRankRequestOneOfToJSON,
} from './VariableRankRequestOneOf';
import type { VariableRankRequestOneOf1 } from './VariableRankRequestOneOf1';
import {
    instanceOfVariableRankRequestOneOf1,
    VariableRankRequestOneOf1FromJSON,
    VariableRankRequestOneOf1FromJSONTyped,
    VariableRankRequestOneOf1ToJSON,
} from './VariableRankRequestOneOf1';

/**
 * @type VariableRankRequest
 * 
 * @export
 */
export type VariableRankRequest = VariableRankRequestOneOf | VariableRankRequestOneOf1;

export function VariableRankRequestFromJSON(json: any): VariableRankRequest {
    return VariableRankRequestFromJSONTyped(json, false);
}

export function VariableRankRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableRankRequest {
    if (json == null) {
        return json;
    }
    if (instanceOfVariableRankRequestOneOf(json)) {
        return VariableRankRequestOneOfFromJSONTyped(json, true);
    }
    if (instanceOfVariableRankRequestOneOf1(json)) {
        return VariableRankRequestOneOf1FromJSONTyped(json, true);
    }

    return {} as any;
}

export function VariableRankRequestToJSON(value?: VariableRankRequest | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfVariableRankRequestOneOf(value)) {
        return VariableRankRequestOneOfToJSON(value as VariableRankRequestOneOf);
    }
    if (instanceOfVariableRankRequestOneOf1(value)) {
        return VariableRankRequestOneOf1ToJSON(value as VariableRankRequestOneOf1);
    }

    return {};
}

