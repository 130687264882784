import {
  TemplatesAdapterInterface,
  SuccessResult,
  ErrorResult,
  ValidationError,
  Result,
  TemplateApi,
  TemplateListItem,
  VariableApi,
  IssueApi,
  FieldApi,
  JobApi,
  Configuration,
  TemplateListItemScopeEnum as ApiTemplateListItemScopeEnum,
  TemplateScopeEnum as ApiTemplateScopeEnum,
  Template as ApiTemplate,
  Issue as ApiIssue,
  Job as ApiJob,
  JobStatusEnum as ApiJobStatusEnum,
  JiraOperationStatusEnum as ApiJiraOperationStatusEnum,
  VariableResponse as ApiVariable,
  VariableTypeEnum,
  ResponseError as ApiResponseError
} from "@easy-templates/lib"

import {
  Job,
  JobStatus,
  SubJob,
  SubJobStatus,
  Template,
  TemplateIssue,
  TemplateScope,
  TemplateVariable,
  VariableType,
  VariableValue,
  VariableValues,
} from "@easy-templates/types"

import platform from './platform'

const buildTemplate = (data: ApiTemplate): Template => {
  const scope = data.scope == ApiTemplateScopeEnum.NUMBER_0 ?
    TemplateScope.GLOBAL : TemplateScope.PROJECTS

  const { root, ...restTree } = data.tree
  const issues =
    Object.fromEntries(
      Object.entries(data.issues)
        .map(([key, value]) => {
          return [key, {
            id: value.id,
            iconUrl: value.iconUrl,
            jiraKey: value.jiraKey,
            level: value.level,
            name: value.name,
            parentId: value.parentId,
            rank: value.rank,
          }]
        })
    )

  return {
    id: data.id,
    name: data.name,
    createChildren: data.createChildren,
    issues,
    rootIssueId: data.rootIssueId,
    issuetype: {
      id: data.issuetype.id,
      name: data.issuetype.name,
      iconUrl: data.issuetype.iconUrl,
    },
    tree: {
      root,
      ...restTree,
    },
    scope,
    scopeValue: data.scopeValue,
    variables: data.variables.map(buildVariable),
    createdBy: data.createdBy,
  }
}

const buildTemplateFromListItem = (data: TemplateListItem): Template => {
  const scopeMapping = {
    [ApiTemplateListItemScopeEnum.NUMBER_0]: TemplateScope.GLOBAL,
    [ApiTemplateListItemScopeEnum.NUMBER_1]: TemplateScope.PROJECTS,
  }

  return {
    id: data.id,
    name: data.name,
    createChildren: data.createChildren,
    rootIssueId: data.rootIssueId,
    scope: scopeMapping[data.scope],
    scopeValue: data.scopeValue,
    issuetype: {
      id: data.issueType.id,
      name: data.issueType.name,
      iconUrl: data.issueType.iconUrl,
    },
    tree: {
      root: []
    },
    issues: {},
    variables: [],
    createdBy: data.createdBy,
  }
}

const buildIssue = (data: ApiIssue): TemplateIssue => {
  const fields = {
    ...data.fields,
    summary: data.fields['summary'] || '(Unknown)',
    description: data.fields['description'] || '',
    issuetype: data.fields['issuetype'],
    project: data.fields['project'],
  }

  return {
    id: data.id,
    parentId: data.parentId,
    jiraKey: data.jiraKey,
    fields,
    disabledFields: data.disabledFields,
  }
}

const buildJob = (data: ApiJob): Job => {
  const statusMap = {
    [ApiJobStatusEnum.Success]: JobStatus.SUCCESS,
    [ApiJobStatusEnum.Error]: JobStatus.ERROR,
    [ApiJobStatusEnum.InProgress]: JobStatus.IN_PROGRESS,
  }

  const subJobStatusMap = {
    [ApiJiraOperationStatusEnum.Ok]: SubJobStatus.OK,
    [ApiJiraOperationStatusEnum.Error]: SubJobStatus.ERROR,
    [ApiJiraOperationStatusEnum.InProgress]: SubJobStatus.IN_PROGRESS,
  }

  console.debug("buildJob()", { data, statusMap, subJobStatusMap })

  const results = data.results ?
    Object.fromEntries(
      Object.entries(data.results)
        .map(([key, subJob]) => {
          return [key, {
            issueId: key,
            status: subJobStatusMap[subJob.status],
            result: subJob.result,
          } as SubJob]
        }
        )
    ) : undefined

  return {
    id: data.id,
    status: statusMap[data.status],
    result: data.result,
    results,
  }
}

const buildVariable = (data: ApiVariable): TemplateVariable => {
  return {
    id: data.id,
    type: VariableType[data.type],
    label: String(data.label),
    default: data.defaultValue,
    description: data.description,
    required: data.required,
  }
}

const normalizeVariableRequestPayload = (inParams: { type?: VariableType, label?: string, description?: string, required?: boolean, default?: VariableValue }) => {
  const { type, label, description, required, default: defaultValue } = inParams

  const typeMapping: Record<VariableType, VariableTypeEnum> = {
    [VariableType.text]: VariableTypeEnum.Text,
    [VariableType.longtext]: VariableTypeEnum.Longtext,
    // TODO: Handle the rest of the types as they get supported by the backend
    [VariableType.number]: VariableTypeEnum.Text,
    [VariableType.date]: VariableTypeEnum.Text,
    [VariableType.select]: VariableTypeEnum.Text,
    [VariableType.multiselect]: VariableTypeEnum.Text,
    [VariableType.user]: VariableTypeEnum.Text,
  }

  return {
    type: typeMapping[type],
    label,
    description,
    required,
    defaultValue,
  }
}


export default class ConnectTemplate implements TemplatesAdapterInterface {
  templateApi: TemplateApi
  issueApi: IssueApi
  fieldApi: FieldApi
  variableApi: VariableApi
  jobApi: JobApi
  constructor() {

    this.initApis()
    this.rotateApiAuthentication()
  }

  private initApis() {
    const basePath = window.document.getElementById('root').dataset.apiUrl + '/api'

    platform.context.getToken().then(jwt => {
      const configuration = new Configuration({ apiKey: `JWT ${jwt}`, fetchApi: fetch, basePath })

      this.templateApi = new TemplateApi(configuration)
      this.issueApi = new IssueApi(configuration)
      this.fieldApi = new FieldApi(configuration)
      this.variableApi = new VariableApi(configuration)
      this.jobApi = new JobApi(configuration)
    })
  }

  private rotateApiAuthentication() {
    setInterval(() => {
      this.initApis()
    }, 1000 * 60 * 10) // 10 minutes
  }


  async all() {
    const templates = await this.templateApi.templateList()

    return templates.map(buildTemplateFromListItem)
  }

  async get(id: string) {
    const template = await this.templateApi.templateGet(id)

    return buildTemplate(template)
  }

  async update(
    id: string,
    data: {
      name?: string
      createChildren?: boolean
      scope?: string
      scopeValue?: string[]
    }
  ) {
    try {
      const attributes = {
        ...data,
        scope: String(data.scope) === '1' ? ApiTemplateListItemScopeEnum.NUMBER_1 : ApiTemplateListItemScopeEnum.NUMBER_0
      }

      const result = await this.templateApi.templateUpdate(id, attributes)

      console.debug("ConnectTemplate.update()", { id, result })

      return new Result(buildTemplate(result))
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async copy(id: string, name: string) {
    try {
      const result = await this.templateApi.templateCopy(id, { name })
      console.debug("ConnectTemplate.copy()", { id, result })

      return new Result({
        id: result.id,
        name: result.name,
        createChildren: result.createChildren,
      })
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async delete(id: string) {
    // return await this.repo.delete(id)
    return await this.templateApi.templateDelete(id)
  }

  // Issues

  async copyIssue(
    templateId: string,
    id: string,
    name: string
  ) {
    try {
      const result = await this.issueApi.issueCopy(templateId, id, { name })
      console.debug("ConnectTemplate.copyIssue()", { id, result })

      return new Result(buildIssue(result))

    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async getIssue(templateId: string, id: string): Promise<TemplateIssue> {
    const data = await this.issueApi.issueGet(templateId, id)

    return buildIssue(data)
  }

  async getIssues(templateId: string): Promise<TemplateIssue[]> {
    const list = await this.issueApi.issueList(templateId)

    return list.map(buildIssue)
  }

  async deleteIssue(templateId: string, id: string): Promise<void> {
    await this.issueApi.issueDelete(templateId, id)
  }

  async createFromIssue({
    sourceId,
    name,
  }: {
    sourceId: string
    name: string
  }) {
    console.debug("ConnectTemplate.createFromIssue()", { sourceId, name })

    try {
      const result = await this.templateApi.templateCreateFromJiraIssue({ name, sourceId })

      return new Result({
        id: result.id,
        name: result.name,
        createChildren: result.createChildren,
      })
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  // Fields

  async updateIssueField(
    templateId: string,
    issueId: string,
    id: string,
    value: unknown
  ) {
    try {
      console.debug('updateIssueField Params', { id, value })
      const result = await this.fieldApi.fieldUpdateBody(templateId, issueId, id, { value })

      return new Result({
        id: result.id,
        name: result.name,
        isDisabled: result.isDisabled,
      })
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async toggleIssueField(
    templateId: string,
    issueId: string,
    id: string,
    enabled: boolean
  ) {
    try {
      const result = await this.fieldApi.fieldToggle(templateId, issueId, id, { enabled })

      return new Result({
        id: result.id,
        name: result.name,
        isDisabled: result.isDisabled,
      })
    } catch (reason) {
      return this._handleError(reason)
    }
  }


  // Jobs

  async getJob(id: string): Promise<Job> {
    const result = await this.jobApi.jobGet(id)

    console.debug("ConnectTemplate.getJob()", { id, result })
    return buildJob(result)
  }

  // Variables

  async deleteVariable(templateId: string, id: string): Promise<void> {
    const result = await this.variableApi.variableDelete(templateId, id)

    console.debug("ConnectTemplate.deleteVariable()", { templateId, id, result })
  }

  async getVariables(templateId: string): Promise<TemplateVariable[]> {
    const result = await this.variableApi.variableList(templateId)

    console.debug("ConnectTemplate.getVariables()", { templateId, result })

    return result.map(buildVariable)
  }

  async createVariable(templateId: string, params: {
    label: string,
    description: string,
    required: boolean,
    default: VariableValue
  }) {
    try {
      const attributes = normalizeVariableRequestPayload(params)
      const result = await this.variableApi.variableCreate(templateId, attributes)

      console.debug("ConnectTemplate.createVariable", { templateId, attributes, result })

      return new SuccessResult(buildVariable(result))
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async updateVariable(
    templateId: string,
    id: string,
    params: { label?: string, description?: string, required?: boolean, default?: VariableValue }
  ) {
    try {
      const attributes = normalizeVariableRequestPayload(params)
      const result = await this.variableApi.variableUpdate(templateId, id, attributes)

      console.debug("ConnectTemplate.updateVariable", { templateId, id, attributes })

      return new SuccessResult(buildVariable(result))
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async rankVariable(templateId: string, variableId: string, body: { before: string } | { after: string }) {
    console.debug("ConnectTemplate.rankVariable", { templateId, variableId, body })
    try {
      await this.variableApi.variableRank(templateId, variableId, body)

      console.debug("ConnectTemplate.rankVariable", { templateId, variableId, body })

      return new SuccessResult(undefined)
    } catch (reason) {
      return this._handleError(reason)
    }
  }

  async createIssuesFromTemplate(
    templateId: string,
    projectId: string,
    variableValues: VariableValues,
    rootIssueKey?: string
  ) {
    const result = await this.templateApi.templateUseAsync(templateId, { projectId, variables: variableValues, rootIssueKey })

    console.debug("ConnectTemplate.createIssuesFromTemplate", { templateId, projectId, variableValues, rootIssueKey, result })

    return { jobId: result.id }
  }

  async applyToIssue(
    id: string,
    issueJiraKey: string,
    projectId: string,
    variableValues: VariableValues
  ) {
    const result = await this.templateApi.templateApplyAsync(id, { issueId: issueJiraKey, projectId, variables: variableValues })

    console.debug("ConnectTemplateApplier.perform()", { id, result })

    return { jobId: result.id }
  }

  private async _handleError(reason: Error | Response | ApiResponseError) {
    let response: Response

    console.debug("ConnectTemplate._handleError()", reason)

    if (reason instanceof ApiResponseError) {
      response = reason.response
    } else if (reason instanceof Response) {
      response = reason
    }

    if (response !== undefined) {
      try {
        const payload = await response.json()
        let error: Error | ValidationError
        console.debug("ConnectTemplate._handleError()", payload)

        if ([409, 422].includes(response.status)) {
          error = new ValidationError(payload.errors)
        } else if ('errors' in payload) {
          error = new Error(payload.errors.detail)
        } else {
          error = new Error(payload.message)
        }

        return new ErrorResult(error)
      } catch (_error) {
        console.debug("Total fail")
        return new ErrorResult(new Error(response.statusText || 'Unknown error'))
      }
    }

    return new ErrorResult(reason as Error)
  }
}
