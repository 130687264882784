import { useState } from 'react'
import { VariableType } from "@easy-templates/types"
import Select from "components/Select"
import Textfield from "components/ui/Textfield"
import Checkbox from "components/ui/Checkbox"
import SectionMessage from "components/ui/SectionMessage"
import { variableFieldFactory, useVariableOptions } from "components/Variables"
import { Field, ErrorMessage, useFormState } from "components/ui/form"

type Value = string | number | undefined

type Values = {
  type?: { id: VariableType }
  label?: string
  description?: string
  default?: Value
  required?: boolean
}

type Props = {
  takenLabels: string[]
  isSubmitting?: boolean
  isTypeDisabled?: boolean
  initialValues?: Values
}

const testIdFor = (name: string) => `variable-form__${name}`

export const labelValidator = ({ initialValue, takenLabels }: { takenLabels: string[], initialValue: string }) => async (value: string) => {
  const validLabelRegex = /^[\w- ]+$/

  if (!value) {
    return "Label cannot be empty"
  }

  if (!value.match(validLabelRegex)) {
    return "Label must contain only letters, numbers, hyphens, and spaces"
  }

  if (value.length > 50) {
    return "Label must be 50 characters or less"
  }

  const normLabel = value.trim().toLowerCase()
  const normInitialLabel = initialValue?.trim().toLowerCase()

  const isLabelChanged = normLabel !== normInitialLabel

  if (isLabelChanged && takenLabels.includes(normLabel)) {
    return "Label must be unique in the template"
  }
}

export const validateDescription = (value: string = '') => {
  if (value.length > 255) {
    return "Description must be 255 characters or less"
  }
}

const DefaultValueField = ({ defaultValue }: { defaultValue?: Value }) => {
  const formState = useFormState({ invalid: true, submitting: true, values: true, initialValues: true })
  const [isDirty, setIsDirty] = useState(false)

  if (!formState) { return null }

  const {
    component: VariableField,
    validation: variableValidation,
    transformer: variableTransformer,
    props: variableFieldProps
  } = variableFieldFactory("default", formState.values.type.id)

  const value = formState.values.default === undefined && !isDirty ? defaultValue : formState.values.default

  return (
    <Field<Value>
      name="default"
      label="Default value"
      isDisabled={formState.submitting}
      defaultValue={variableTransformer.transform(value)}
      testId={testIdFor("default")}
      validate={variableValidation.validate}
      transform={variableTransformer.transform}
    >
      {({ fieldProps, error }) => (
        <>
          {/* @ts-ignore: Doesn't like onBlur prop */}
          <VariableField
            {...fieldProps}
            {...variableFieldProps}
            value={variableTransformer.transform(fieldProps.value)}
            onChange={value => {
              setIsDirty(true)
              fieldProps.onChange(value)
            }}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  )
}

const Form = ({
  initialValues,
  isSubmitting,
  isTypeDisabled,
  takenLabels,
}: Props) => {
  const { options: variableOptions } = useVariableOptions()

  const typeOptions = variableOptions.map(variable => ({
    id: variable.type,
    name: variable.label,
    iconComponent: variable.Icon,
    isDisabled: variable.comingSoon
  }))

  const selectedType = initialValues?.type ? typeOptions.find(type => type.id === initialValues.type.id) : typeOptions[0]

  return (
    <div data-testid="variable-form">
      <Field name="error" testId={testIdFor("error")}>
        {({ error }) => (
          <>
            {error &&
              <SectionMessage appearance="error">
                {error}
              </SectionMessage>}
          </>
        )}
      </Field>
      <Field label="Type" name="type" testId={testIdFor("type")} defaultValue={selectedType}>
        {({ fieldProps }) => (
          <Select
            {...fieldProps}
            isClearable={false}
            isDisabled={isTypeDisabled || fieldProps.isDisabled}
            isMulti={false}
            isSearchable={false}
            options={typeOptions}
            menuPosition="fixed"
          />
        )}
      </Field>
      <Field name="label"
        label="Label"
        isRequired={true}
        isDisabled={isSubmitting}
        defaultValue={initialValues?.label}
        validate={labelValidator({ takenLabels, initialValue: initialValues?.label })}
        testId={testIdFor("label")}
      >
        {({ fieldProps, error }) => (
          <>
            <Textfield
              {...fieldProps}
              autoFocus
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field name="description"
        label="Description"
        isDisabled={isSubmitting}
        defaultValue={initialValues?.description}
        validate={validateDescription}
        testId={testIdFor("description")}>
        {({ fieldProps, error }) => (
          <>
            <Textfield
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <DefaultValueField defaultValue={initialValues?.default} />
      <Field
        name="required"
        testId={testIdFor("required")}
        isDisabled={isSubmitting}
      >
        {({ fieldProps }) => (
          <Checkbox
            {...fieldProps}
            label={"Require non-empty value"}
            defaultChecked={initialValues?.required}
          />
        )}
      </Field>
    </div>
  )
}

export default Form
