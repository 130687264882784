import TableTree, {
  Cell,
  Row,
  Rows,
} from "components/ui/table-tree";
import Spinner from "components/ui/Spinner";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import OkIcon from "components/ui/icons/SuccessIcon";

import InlineMessage from "../InlineMessage";

import useStyles from "./useStyles";

export enum Status {
  IN_PROGRESS = "in-progress",
  WARNING = "warning",
  ERROR = "error",
  OK = "ok",
}

export type Item = {
  title: string;
  message: string;
  id: string;
  iconUrl?: string;
  children?: Item[];
  status: Status;
  onClick?: () => void;
};

export type Props = {
  items: Item[];
};

const Progress = ({ items }: Props) => {
  const classes = useStyles();

  const shouldExpand = ({ id, status, children }) =>
    id === items[0].id || status === "error" || children.length < 3;

  return (
    <div className={classes.root} data-testid="progress-tree">
      <TableTree>
        <Rows
          items={items}
          render={({
            children = [],
            iconUrl,
            id,
            message,
            onClick,
            status,
            title,
          }: Item) => (
            <Row
              itemId={id}
              items={children}
              hasChildren={children.length > 0}
              isDefaultExpanded={shouldExpand({ id, status, children })}
            >
              <Cell>
                <Tooltip content={message}>
                  <div className={classes.itemWrapper} data-testid={`result-for-${id}`}
                  >
                    <div className={classes.iconWrapper} data-testid="status-icon">
                      <StatusIcon status={status} message={message} />
                    </div>
                    <Button
                      appearance="link"
                      spacing="none"
                      isDisabled={[Status.ERROR, Status.IN_PROGRESS].includes(
                        status
                      )}
                      iconBefore={
                        iconUrl ? (
                          <div className={classes.iconWrapper}>
                            <img src={iconUrl} />
                          </div>
                        ) : null
                      }
                      onClick={onClick}
                      target="_blank"
                      shouldFitContainer={true}
                      testId="name-button"
                    >
                      {title || "(Unknown)"}
                    </Button>
                  </div>
                </Tooltip>
              </Cell>
            </Row>
          )}
        />
      </TableTree>
    </div>
  );
};

const StatusIcon = ({
  status,
  message,
}: {
  status: Status;
  message?: string;
}) => {
  if (status === Status.ERROR) {
    // @ts-ignore: Tells, it's not a JSX
    // return <ErrorIcon label="error" size="medium" primaryColor="red" />
    return <InlineMessage appearance="error">{message}</InlineMessage>;
  }

  if (status === Status.WARNING) {
    // @ts-ignore: Tells, it's not a JSX
    // return <WarningIcon label="Warning" primaryColor="orange" />
    return <InlineMessage appearance="warning">{message}</InlineMessage>;
  }

  if (status === Status.OK) {
    // @ts-ignore: Tells, it's not a JSX
    return <OkIcon label="ok" size="medium" primaryColor="green" />;
    // return <InlineMessage appearance="confirmation"></InlineMessage>
  }

  return <Spinner size="small" />;
};

export default Progress;
