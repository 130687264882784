import useStyles from "./useStyles";

type Props = {
  children: string;
  iconUrl?: string;
  icon?: React.ReactNode
};

const Item = ({ children, iconUrl, icon }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="icon-text">
      <div className={classes.container}>
        {iconUrl && (
          <img alt={children} src={iconUrl} className={classes.icon} />
        )}
        {icon && (icon)}
        <div className={classes.content}>
          <span title={children}>{children}</span>
        </div>
      </div>
    </div>
  );
};

export default Item;
