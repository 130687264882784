import { useState, useEffect } from "react"
import { useParams, useNavigate, Outlet, useLocation } from "react-router-dom"

import Maintenance from "components/maintenance"
import ErrorState from "components/ErrorState"
import Loading from "components/Loading"
import HelpLinks from "components/Templates/HelpLinks"
import { useAppContext } from "components/AppContextProvider"
import PageWrapper from "pages/PageWrapper"

import NavigationTree, {
  generateNavigationTree,
} from "components/Admin/Templates/NavigationTree"

import { IssueCopyForm } from "./Form"
import Layout from "./Layout"
import BackButton from "./BackButton"
import Navigation from "./navigation"

import useTemplateData from "./useTemplateData"
import useDeleteIssue from "./useDeleteIssue"
import useCloneIssue from "./useCloneIssue"

const EditTemplatePage = (): JSX.Element => {
  const { core, maintenanceModeOn } = useAppContext()
  const { templateId, issueId, section } = useParams()
  const navigate = useNavigate()
  const { cloneIssue } = useCloneIssue()

  if (!templateId) throw new Error("Template ID is required")

  const [issueCopyFormProps, setIssueCopyFormProps] = useState({
    isVisible: false,
    initialValues: {
      templateId,
      id: "",
      summary: "",
    },
  })

  const { template, isLoading: isTemplateLoading, error } = useTemplateData(templateId)

  const { deleteIssue } = useDeleteIssue({
    templateId,
  })

  const gotoIssue = (id: string) => {
    navigate(`/template/edit/${templateId}/issues/${id}`)
  }

  const gotoVariables = () => {
    navigate(`/template/edit/${templateId}/variables`)
  }

  const gotoTemplate = () => {
    navigate(`/template/edit/${templateId}`)
  }

  const handleIssueSelectClick = (id: string) => {
    window.scrollTo(0, 0)
    gotoIssue(id)
  }

  const handleIssueDeleteClick = async (id: string) => {
    if (confirm("Are you sure?")) {
      try {
        gotoIssue(template.rootIssueId)
        await deleteIssue({ id })

        core.showFlag({
          id: "issue-delete",
          description: "The issue has been successfully deleted",
          type: "success",
        })
      } catch (error) {
        core.showFlag({
          id: "issue-delete",
          description: error.message,
          type: "error",
        })
      }

    }
  }

  const handleIssueCopyClick = (id: string, summary: string) => {
    setIssueCopyFormProps({
      isVisible: true,
      initialValues: {
        id,
        summary,
        templateId: template.id,
      },
    })
  }

  const handleIssueCopyFormDismiss = () => {
    setIssueCopyFormProps({
      isVisible: false,
      initialValues: {
        templateId,
        id: "",
        summary: "",
      },
    })
  }

  const handleIssueCopyFormSumit = async ({
    id,
    summary,
    templateId,
  }) => {
    try {
      await cloneIssue({ issueId: id, name: summary, templateId })

      core.showFlag({
        id: "issue-copy",
        description: "The issue has been successfully copied",
        type: "success",
      })

      handleIssueCopyFormDismiss()
    } catch (error) {
      core.showFlag({
        id: "issue-copy",
        description: error.message,
        type: "error",
      })
    }
  }

  const handleGoBack = () => {
    navigate("/templates")
  }

  useEffect(() => {
    if (!core) return

    setTimeout(core.resizeView, 50)
  }, [core, isTemplateLoading])

  if (maintenanceModeOn) {
    return <Maintenance />
  }

  if (!template) {
    return <Loading />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  if (!template) {
    return <ErrorState error={Error("Template not found...")} />
  }

  const navigationTree = generateNavigationTree(template.tree, template.issues)

  return (
    <PageWrapper>
      <Layout
        navigation={
          <Navigation
            isSettingsSelected={!section}
            isVariablesSelected={section === "variables"}
            onSettingsClick={gotoTemplate}
            onVariablesClick={gotoVariables}
            issuesNavigation={
              <NavigationTree
                selectedId={issueId}
                tree={navigationTree}
                onCopy={handleIssueCopyClick}
                onSelect={handleIssueSelectClick}
                onDelete={handleIssueDeleteClick}
              />
            }
          />
        }
        help={<HelpLinks key="helpLinks" />}
        header={<BackButton onClick={handleGoBack} />}
      >
        <Outlet />
      </Layout>
      {issueCopyFormProps.isVisible && (
        <IssueCopyForm
          initialValues={issueCopyFormProps.initialValues}
          onDismiss={handleIssueCopyFormDismiss}
          onSubmit={handleIssueCopyFormSumit}
        />
      )}
    </PageWrapper>
  )
}

export default EditTemplatePage
