import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Template, TemplateIssue } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

type UpdateParams = {
  templateId: string
  issueId: string
  name: string
  value: unknown
}

type ToggleParams = {
  templateId: string
  issueId: string
  name: string
  isEnabled: boolean
}

type Props = {
  onError?: (error: Error) => void
}

export default ({ onError }: Props) => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutate: updateFieldValue, isLoading: isProcessingUpdate } =
    useMutation({
      mutationFn: async ({
        templateId,
        issueId,
        name,
        value,
      }: UpdateParams) => {
        const result = await core.updateTemplateIssueField(
          templateId,
          issueId,
          name,
          value
        )

        if (result.isOk) {
          return result
        }

        throw result.error
      },
      onMutate: async ({ templateId, issueId, name, value }: UpdateParams) => {
        const issueQueryKey = templateKeys.issue(templateId, issueId)
        const templateQueryKey = templateKeys.details(templateId)

        let oldTemplate: Template

        const oldIssue: TemplateIssue = queryClient.getQueryData(issueQueryKey)

        if (name === "summary") {
          oldTemplate = queryClient.getQueryData(templateQueryKey)

          const newIssues = {
            ...oldTemplate.issues,
            [issueId]: {
              ...oldTemplate.issues[issueId],
              name: value,
            },
          }

          queryClient.setQueryData(
            templateQueryKey,
            (old: TemplateIssue) => ({ ...old, issues: newIssues })
          )
        }

        const newFields = {
          ...oldIssue.fields,
          [name]: {
            ...oldIssue.fields[name],
            body: value,
          },
        }

        queryClient.setQueryData(
          issueQueryKey,
          (old: TemplateIssue) => ({ ...old, fields: newFields })
        )

        return { oldIssue, oldTemplate }
      },
      onError: (err: Error, { templateId, issueId, name }, context) => {
        onError?.(err)

        queryClient.setQueryData(
          templateKeys.issue(templateId, issueId),
          context.oldIssue
        )

        if (name === "summary") {
          queryClient.setQueryData(
            ["template", templateId],
            context.oldTemplate
          )
        }
      },
      onSettled: (_data, _error, { templateId, issueId }) => {
        queryClient.invalidateQueries({ queryKey: templateKeys.details(templateId) })
        queryClient.invalidateQueries({ queryKey: templateKeys.issue(templateId, issueId) })
      },
    })

  const { mutate: toggleField, isLoading: isProcessingToggle } = useMutation({
    mutationFn: async ({
      templateId,
      issueId,
      name,
      isEnabled,
    }: ToggleParams) => {
      return await core.toggleTemplateIssueField(
        templateId,
        issueId,
        name,
        isEnabled
      )
    },
    onMutate: async ({
      templateId,
      issueId,
      name,
      isEnabled,
    }: ToggleParams) => {
      const issueQueryKey = templateKeys.issue(templateId, issueId)
      await queryClient.cancelQueries({ queryKey: issueQueryKey })

      const oldIssue: TemplateIssue = queryClient.getQueryData(issueQueryKey)

      queryClient.setQueryData(issueQueryKey, (issue: TemplateIssue) => {
        const disabledFieldsSet = new Set(issue.disabledFields)

        if (isEnabled) {
          disabledFieldsSet.delete(name)
        } else {
          disabledFieldsSet.add(name)
        }

        const disabledFields = Array.from(disabledFieldsSet.values())

        return {
          ...issue,
          disabledFields,
          fields: {
            ...issue.fields,
            [name]: {
              ...issue.fields[name],
              isDisabled: !isEnabled,
            },
          },
        }
      })

      return { issueQueryKey, oldIssue }
    },
    onError: (_err, _field_params, context) => {
      queryClient.setQueryData(context.issueQueryKey, context.oldIssue)
    },
    onSettled: (_data, _err, _vars, context) => {
      queryClient.invalidateQueries(context.issueQueryKey)
    },
  })

  return {
    updateFieldValue,
    toggleField,
    isProcessing: isProcessingUpdate || isProcessingToggle,
  }
}
