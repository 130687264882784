const TemplateHint = () => (
  <>
    <p>
      <strong> Please choose the template for the new issue</strong>
    </p>
    <p>
      Type a part of the template name in order to find it faster
      <div>
        <i>Example: </i>
        If you want to find "The bug <strong>repo</strong>rt template" type "
        <strong>repo</strong>"
      </div>
    </p>
    <p>
      Type the name of the issue type to filter templates by the type
      <div>
        <i>Example: </i>
        Type "bug" to find all templates of the <i>Bug</i> issue type
      </div>
    </p>
    <p>
      Or type issue type followed by a part of the template name for more
      precise searching
      <div>
        <i>Example: </i>
        Type "bug <strong>repo</strong>" to find "The problem{" "}
        <strong>repo</strong>rt template" of the <i>Bug</i> issue type
      </div>
    </p>
    <p></p>
    <p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {/* <a href="#">Learn more</a> */}
    </p>
  </>
);

export default TemplateHint;
