import React from "react"

import styles from "./styles"

type Props = {
  src: string
}

const Icon: React.FC<Props> = ({ src }) => (
  <img alt="icon" src={src} height="16" width="16" style={styles.icon} />
)

export default Icon
