import { useQuery } from "@tanstack/react-query"
import { TemplateVariable } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from 'lib/queryKeys'

export type DataFetchResult = {
  isLoading: boolean
  error?: Error
  variables: TemplateVariable[]
}

const useData = (id: string): DataFetchResult => {
  const { core } = useAppContext()

  const { data, isLoading, error } = useQuery<TemplateVariable[], Error>({
    enabled: !!id,
    queryKey: templateKeys.variables(id),
    queryFn: async () => {
      return await core.getTemplateVariables(id)
    }
  })

  return {
    isLoading,
    error,
    variables: data || [],
  }
}

export default useData
