/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AsyncOperationResult
 */
export interface AsyncOperationResult {
    /**
     * ID of the async job
     * @type {string}
     * @memberof AsyncOperationResult
     */
    id?: string;
}

/**
 * Check if a given object implements the AsyncOperationResult interface.
 */
export function instanceOfAsyncOperationResult(value: object): value is AsyncOperationResult {
    return true;
}

export function AsyncOperationResultFromJSON(json: any): AsyncOperationResult {
    return AsyncOperationResultFromJSONTyped(json, false);
}

export function AsyncOperationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsyncOperationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function AsyncOperationResultToJSON(value?: AsyncOperationResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

