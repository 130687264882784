import { Jira } from "@easy-templates/types";

type Options = {
  currentIssueKey?: string;
  currentJQL?: string;
};

export const getIssuePickerSuggestions = async (
  requestJira: Jira.AdapterInterface["request"],
  query: string,
  options: Options
) => {
  const response = await requestJira<Jira.SuggestionsResult>(
    `/rest/api/3/issue/picker?query=${query}&currentIssueKey=${options.currentIssueKey}&currentJQL=${options.currentJQL}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response;
};

export default getIssuePickerSuggestions;
