import React, { useState } from 'react'

import useStyles from './useStyles'

type Props = {
  src: string
}

const Icon = ({ src }: Props): JSX.Element | null => {
  const classes = useStyles()
  const [isVisible, setIsVisible] = useState(true)

  return isVisible ? (
    <img
      alt='icon'
      src={src}
      height='16'
      width='16'
      className={classes.icon}
      onError={() => setIsVisible(false)}
    />
  ) : null
}

export default Icon
