import { useReducer, useRef, useEffect } from "react"
import sortBy from "lodash/sortBy"

import { StringField } from "@easy-templates/lib"
import { Job, SubJob, SubJobStatus, Jira, Template, TemplateVariable, JobStatus, VariableValues } from "@easy-templates/types"

import { useAnalytics, UIEvent, UIScope } from "components/Analytics"
import BackIcon from "components/ui/icons/BackIcon"
import PrefillIcon from "components/ui/icons/PrefillIcon"
import NextIcon from "components/ui/icons/NextIcon"
import RestartIcon from "components/ui/icons/RestartIcon"
import { normalizeVariableValuesKeys } from "components/Variables"
import { useAppContext } from "components/AppContextProvider"

import useTemplate from "hooks/useTemplate"
import useJob from "hooks/useJob"

import { State, StepPage, StepStatus } from "./types"
import useTemplates from "./useTemplates"
import useCreateIssue from "./useCreateIssue"
import usePrefillIssue from "./usePrefillIssue"

import {
  ActionType,
  ContextChangedAction,
  FullTemplateLoadedAction,
  JobUpdatedAction,
  IssueCreatedAction,
  IssueCreationRequestedAction,
  IssuePrefillRequestedAction,
  IssuePrefillCompletedAction,
  IssuetypeSelectedAction,
  ProjectSelectedAction,
  RandomlyFailedAction,
  TemplateSelectedAction,
  TemplatesLoadedAction,
  StepChangedAction,
} from "./actions"

type Action = {
  id: string
  label: string
  onClick: () => void
  isDisabled?: boolean
  isProcessing?: boolean
  appearance?: "default" | "primary" | "subtle"
  leftIcon?: React.Component
  rightIcon?: React.Component
}

export enum CreationStatus {
  IN_PROGRESS = "in-progress",
  WARNING = "warning",
  ERROR = "error",
  OK = "ok",
}

type CreationResult = {
  title: string
  message: string
  id: string
  children?: CreationResult[]
  status: CreationStatus
  onClick?: () => void
}

const defaultState = {
  allProjects: [],
  allTemplates: [],
  currentStep: StepPage.SELECT,
  errorMessage: null,
  fullSelectedTemplate: null,
  isLoading: true,
  isPrefilling: false,
  isValid: false,
  issuetypes: [],
  noTemplates: false,
  projects: [],
  results: {},
  selectedIssueType: null,
  selectedProject: null,
  selectedTemplate: null,
  steps: [],
  templates: [],
  warnings: [],
}

interface Return {
  actions: Action[]
  creationResults: CreationResult[]
  handleIssueTypeChange: (issuetype: Jira.IssueTypeDetails) => void
  handleProjectChange: (project: Jira.Project) => void
  handleTemplateChange: (template: Template) => void
  isLoading: boolean
  isSubmitting: boolean
  issuetypes: Jira.IssueTypeDetails[]
  noTemplates: boolean
  projects: Jira.Project[]
  templates: Template[]
  errorMessage?: string | null | undefined
  handleCreateClicked: () => void
  handlePrefillClicked: () => void
  handleNextClicked: () => void
  isPrefilling: boolean
  isValid: boolean
  missingFields: string[]
  selectedIssueType: Jira.IssueTypeDetails | null | undefined
  selectedProject: Jira.Project | null | undefined
  selectedTemplate: Template | null | undefined
  steps: State["steps"]
  currentStep: State["currentStep"]
  variables: TemplateVariable[]
  warnings: string[]
}

type Params = {
  getFormState: () => {
    values: { [key: string]: unknown }
    valid: boolean
    invalid: boolean
    dirty: boolean
  }
  initialState?: { [key: string]: unknown }
  onClose?: () => void
}


const useHandllers = (props: Params): Return => {
  const { core, featureFlags, ...context } = useAppContext()
  const { initialState, onClose, getFormState } = props
  const { instrument } = useAnalytics(UIScope.NEW_ISSUE_FORM)

  const reducer = (state: State, action: ActionType): State =>
    action.apply(state)

  const [
    {
      errorMessage,
      fullSelectedTemplate,
      jobId,
      isLoading,
      isPrefilling,
      isValid,
      issuetypes,
      noTemplates,
      projects,
      results,
      selectedIssueType,
      selectedProject,
      selectedTemplate,
      currentStep,
      templates,
      warnings,
    },
    dispatch,
  ] = useReducer(reducer, { ...defaultState, ...initialState })

  const variablesFormRef = useRef<HTMLFormElement>()

  useEffect(() => {
    if (selectedProject?.id) {
      dispatch(new ContextChangedAction())
    }
  }, [selectedProject?.id])

  const resetVariablesForm = () => {
    if (variablesFormRef.current) {
      variablesFormRef.current.reset()
    }
  }

  const selectedTemplateId = selectedTemplate?.id

  const { createIssuesFromTemplate, isSubmitting: isSubmittingCreation } =
    useCreateIssue({
      onSuccess: (data: { jobId: string } | Job) => {
        if ("jobId" in data) {
          dispatch(new IssueCreatedAction(data.jobId))
        } else {
          handleJobChange(data)
        }

        resetVariablesForm()
      },
      onError: (error) => {
        dispatch(new RandomlyFailedAction(error.message))
      },
    })

  const { prefill } = usePrefillIssue({
    onComplete: (issues: Jira.IssueBean[]) => {
      dispatch(new IssuePrefillCompletedAction(issues))

      if (issues.length) {
        if (
          fullSelectedTemplate?.createChildren &&
          fullSelectedTemplate?.tree[fullSelectedTemplate.rootIssueId]?.length
        ) {
          dispatch(new StepChangedAction(StepPage.CREATION))
          const { values } = getFormState()

          createIssuesFromTemplate({
            templateId: fullSelectedTemplate.id,
            projectId: selectedProject.id,
            variableValues: normalizeVariableValuesKeys(values.variableValues),
            rootIssueKey: issues[0].key,
          })
        }
      } else {
        dispatch(new RandomlyFailedAction("No issues were prefilled"))
      }

      resetVariablesForm()
    },
    onError: (error) => {
      dispatch(
        new RandomlyFailedAction(
          error.message || "Can't collect the template details"
        )
      )
    },
  })

  const isSubmitting = isSubmittingCreation || !!jobId

  const handleCreateClicked = () => {
    const { invalid, values } = getFormState()

    if (!selectedProject || !selectedTemplate || invalid) return

    dispatch(new IssueCreationRequestedAction())
    dispatch(new StepChangedAction(StepPage.CREATION))

    createIssuesFromTemplate({
      templateId: selectedTemplate.id,
      projectId: selectedProject.id,
      variableValues: normalizeVariableValuesKeys(values.variableValues),
    })
  }

  const handleTemplateChange = async (template: Template) => {
    if (selectedTemplateId === template.id) return

    dispatch(new TemplateSelectedAction(template))
  }

  const handleIssueTypeChange = async (issueType: Jira.IssueTypeDetails) => {
    dispatch(new IssuetypeSelectedAction(issueType))
  }

  useTemplates({
    onCompleted: async ({ templates, projects }) => {
      dispatch(
        new TemplatesLoadedAction({
          templates,
          projects,
          contextProjectId: context.project?.id,
        })
      )
    },
    onError: (error: Error) => {
      dispatch(new RandomlyFailedAction(error.message))
    },
  })

  useTemplate({
    id: selectedTemplateId,
    onCompleted: async (template: Template) => {
      dispatch(new FullTemplateLoadedAction(template))
    },
    onError: (error: Error) => {
      dispatch(
        new RandomlyFailedAction(error.message || "Error fetching template")
      )
    },
  })

  const handleJobChange = (job: Job) => {
    dispatch(new JobUpdatedAction(job))

    if (job.status === JobStatus.ERROR) {
      dispatch(new RandomlyFailedAction(job.result))
    } else if (job.status === JobStatus.SUCCESS) {
      core.showIssueCreatedMessage({ issueKey: job.result })
    }
  }

  useJob({
    id: jobId,
    onChange: handleJobChange,
    onError: (error: Error) => {
      dispatch(
        new RandomlyFailedAction(
          error.message || "Error updating creation status"
        )
      )
    },
  })

  const handleProjectChange = (project: Jira.Project) => {
    dispatch(new ProjectSelectedAction(project))
  }

  const handlePrefillClicked = async () => {
    const { invalid, values } = getFormState()

    if (invalid || !selectedProject || !selectedTemplate) return

    dispatch(new IssuePrefillRequestedAction())

    prefill({
      templateId: selectedTemplate.id,
      projectId: selectedProject.id,
      issueTypeId: selectedTemplate.issuetype.id,
      variableValues: normalizeVariableValuesKeys(values.variableValues),
      // issueTypeId: selectedIssueType.id,
    })
  }

  const handleNextClicked = () => {
    dispatch(new StepChangedAction(StepPage.VARIABLES))
  }

  const handleStepClicked = (stepPage: StepPage) => {
    dispatch(new StepChangedAction(stepPage))
  }

  const generateSteps = () => {
    if (!selectedTemplate || !currentStep) {
      return []
    }

    const steps = [
      {
        id: StepPage.SELECT,
        label: "Template selection",
        percentageComplete: 0,
        status: StepStatus.CURRENT,
        onClick: () => handleStepClicked(StepPage.SELECT),
      },
    ]

    if (fullSelectedTemplate?.variables.length) {
      steps.push({
        id: StepPage.VARIABLES,
        label: "Variables",
        percentageComplete: 0,
        status: StepStatus.UNVISITED,
        onClick: () => handleStepClicked(StepPage.VARIABLES),
      })
    }

    steps.push({
      id: StepPage.CREATION,
      label: "Creation",
      percentageComplete: 0,
      status: StepStatus.UNVISITED,
      onClick: () => { },
    })

    const currentStepIndex = steps.findIndex((step) => step.id === currentStep)

    const currentStepObject = steps[currentStepIndex]

    let prevSteps = steps.slice(0, currentStepIndex)
    let nextSteps = steps.slice(currentStepIndex)

    prevSteps = prevSteps.map((step) => ({
      ...step,
      percentageComplete: 100,
      status: StepStatus.VISITED,
    }))

    nextSteps = nextSteps.map((step) => ({
      ...step,
      percentageComplete: 0,
      status: StepStatus.UNVISITED,
    }))

    const newSteps = [...prevSteps, ...nextSteps]

    newSteps[currentStepIndex] = {
      ...currentStepObject,
      percentageComplete: 0,
      status: StepStatus.CURRENT,
    }

    return newSteps
  }

  const generateActions = (): Action[] => {
    if (isLoading) {
      return [
        {
          id: "loading",
          appearance: "subtle",
          label: "Loading",
          isProcessing: true,
          onClick: () => { },
        },
      ]
    }

    if (!fullSelectedTemplate) {
      return []
    }

    if (
      currentStep === StepPage.SELECT &&
      fullSelectedTemplate?.variables.length
    ) {
      return [
        {
          id: "next",
          appearance: "primary",
          // @ts-ignore
          rightIcon: NextIcon,
          label: "Next",
          onClick: handleNextClicked,
          isProcessing: isSubmitting,
        },
      ]
    }

    if (currentStep === StepPage.SELECT) {
      const actions = []

      if (featureFlags.prefill) {
        actions.push({
          id: "prefill",
          label: "Prefill",
          // @ts-ignore
          rightIcon: PrefillIcon,
          onClick: instrument(
            UIEvent.PREFILL_BUTTON_CLICKED,
            handlePrefillClicked),
          isProcessing: isPrefilling,
          isDisabled: isPrefilling,
        })
      }

      actions.push({
        id: "create",
        appearance: "primary",
        label: "Create",
        onClick: instrument(
          UIEvent.CREATE_BUTTON_CLICKED,
          handleCreateClicked),
        // @ts-ignore
        rightIcon: NextIcon,
        isProcessing: isSubmitting,
        isDisabled: isSubmitting,
      })

      return actions
    }

    if (currentStep === StepPage.VARIABLES) {
      const actions: Action[] = [
        {
          id: "back",
          appearance: "default",
          label: "Back",
          onClick: () => {
            dispatch(new StepChangedAction(StepPage.SELECT))
          },
          // @ts-ignore
          leftIcon: BackIcon,
        },
      ]

      if (featureFlags.prefill) {
        actions.push({
          id: "prefill",
          label: "Prefill",
          onClick: instrument(
            UIEvent.PREFILL_BUTTON_CLICKED,
            handlePrefillClicked),
          isProcessing: isPrefilling,
          isDisabled: isPrefilling,
          // @ts-ignore
          rightIcon: PrefillIcon,
        })
      }

      actions.push({
        id: "create",
        appearance: "primary",
        // @ts-ignore
        rightIcon: NextIcon,
        label: "Create",
        onClick: instrument(
          UIEvent.CREATE_BUTTON_CLICKED,
          handleCreateClicked),
        isProcessing: isSubmitting,
        isDisabled: isSubmitting,
      })

      return actions
    }

    if (currentStep === StepPage.CREATION && !isSubmitting) {
      return [
        {
          id: "restart",
          appearance: "primary",
          // @ts-ignore
          leftIcon: RestartIcon,
          label: "Start over",
          onClick: () => {
            handleStepClicked(StepPage.SELECT)
          },
        },
      ]
    }

    return []
  }

  const buildResultItems = (
    itemIds: string[],
    template: Template
  ) => {
    if (!itemIds?.length || !template) return []
    const { values } = getFormState()


    const getResultMessage = (result: SubJob): String => {
      return result?.status === "error" ? result?.result : ""
    }

    const items = itemIds
      .filter((id) => {
        if (template.createChildren) {
          return true
        }

        return id === template.rootIssueId
      })
      .map((id) => {
        const issue = template.issues[id]
        const result = errorMessage
          ? {
            issueId: id,
            status: SubJobStatus.ERROR,
            result: results[issue.id]?.result || errorMessage,
          }
          : results[issue.id]

        const summary = new StringField(
          { body: issue.name, name: "summary" },
          { variableValues: normalizeVariableValuesKeys(values.variableValues) }
        )

        return {
          id: issue.id,
          iconUrl: issue.iconUrl,
          title: summary.value(),
          message: getResultMessage(result),
          children: buildResultItems(
            template.tree[id],
            template
          ),
          rank: issue.rank,
          status: result?.status,
          onClick: () => {
            if (result.result && typeof result.result == "string") {
              core.navigateToIssue({ key: result.result })
              onClose?.()
            } else if (typeof result.result == "object") {
              const job = result.result as SubJob
              core.navigateToIssue({ key: job.result })
              onClose?.()
            }
          },
        }
      })

    return sortBy(items, "rank")
  }

  return {
    actions: generateActions(),
    errorMessage,
    creationResults: buildResultItems(
      fullSelectedTemplate?.tree.root,
      fullSelectedTemplate
    ),
    handleIssueTypeChange,
    handleProjectChange,
    handleTemplateChange,
    isLoading: isLoading || (!selectedProject && projects.length > 0),
    isSubmitting,
    issuetypes,
    noTemplates,
    projects,
    selectedIssueType,
    selectedProject,
    selectedTemplate,
    steps: generateSteps(),
    currentStep,
    templates,
    handleCreateClicked,
    handlePrefillClicked,
    handleNextClicked,
    isPrefilling,
    isValid,
    // FIXME: maybe replace with some message from the backend
    missingFields: [],
    variables: fullSelectedTemplate?.variables || [],
    warnings,
  }
}

export default useHandllers
