import { Template } from '@easy-templates/types'
import BaseAction from './base'
import { State } from '../types'

export default class ContextChangedAction extends BaseAction {
  constructor() {
    super()
  }

  apply(state: State): State {
    const {
      allTemplates,
      selectedProject,
      selectedTemplate,
    } = state

    const issuetypes = this.getProjectIssuetypes(
      selectedProject,
      state.allTemplates
    )

    const projectTemplates = this.getProjectTemplates(
      selectedProject,
      allTemplates
    )

    const selectedIssueType = (issuetypes || [])[0]
    return {
      ...state,
      selectedIssueType,
      selectedProject,
      selectedTemplate: this.injectIcon(
        this.getSelectedTemplate(selectedTemplate, projectTemplates)
      ),
      templates: projectTemplates,
      warnings: [],
      errorMessage: null,
    }
  }

  getSelectedTemplate(prevSelectedTemplate: Template, projectTemplates: Template[]) {
    const currentTemplateApplicableToProject =
      projectTemplates.find(
        ({ id }) => id === prevSelectedTemplate?.id
      )

    if (currentTemplateApplicableToProject) {
      return prevSelectedTemplate
    }

    return projectTemplates[0]
  }

  injectIcon(template: Template) {
    if (!template?.issuetype) {
      return template
    }

    return {
      ...template,
      iconUrl: template.issuetype.iconUrl,
    }
  }
}
