import BasicField from "./basic";

export default class DateField extends BasicField {
  value() {
    return this.body;
  }

  prefillValue(): unknown {
    if (!Date.parse(this.body as string)) return;
    const date = new Date(this.value());

    return this.formatDate(date);
  }

  formatDate(date: Date): string {
    return `${this.getDay(date)}/${this.getMonth(date)}/${this.getYear(date)}`;
  }

  getDay(date: Date): string {
    return date.toLocaleDateString("en", {
      day: "2-digit",
    });
  }

  getMonth(date: Date): string {
    return date.toLocaleDateString("en", {
      month: "short",
    });
  }
  getYear(date: Date): string {
    return date.toLocaleDateString("en", {
      year: "2-digit",
    });
  }
}
