import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppContext } from "components/AppContextProvider"

import { templateKeys } from "lib/queryKeys"

const useCreateTemplate = () => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutateAsync: createTemplate, isLoading: isProcessing } = useMutation({
    mutationFn: async ({
      issueId,
      name,
      subtasksEnabled,
    }: {
      issueId: string
      name: string
      subtasksEnabled: boolean
    }) => {
      const result = await core.createTemplateFromIssue(issueId, name, subtasksEnabled)
      console.debug("Template created (mutateAsync)", result)

      if (result.isOk) {
        return result.data
      }

      throw result.error
    },
    onSuccess: (tempalte) => {
      console.debug("Template created (onSuccess)", tempalte)

      queryClient.invalidateQueries({ queryKey: templateKeys.list() })
    },
  })

  return { createTemplate, isProcessing }
}

export default useCreateTemplate
