import BasicField from "./basic";

export default class FixVersionsField extends BasicField {
  value() {
    if (Array.isArray(this.body)) {
      return this.body.map(this.valueFinder.objectValue);
    }

    return this.body;
  }
}
