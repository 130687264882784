const ProjectHint = () => (
  <>
    <p>
      <strong>Target project</strong>
    </p>
    <p>Please choose the project for the new issue</p>
    {/* <p> */}
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    {/*   <a href="#">Learn more</a> */}
    {/* </p> */}
  </>
);

export default ProjectHint;
