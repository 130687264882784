import uniqBy from 'lodash/uniqBy'
import { Jira } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"

const useIssueSearch = () => {
  const { core } = useAppContext()
  return async (query: string) => {
    const { sections }: Jira.SuggestionsResult =
      await core.getIssuePickerSuggestions(query)

    const allIssues = sections.flatMap(({ issues }) => issues)

    const issues = uniqBy(allIssues, 'key')
      .map((issue) => ({
        key: issue.key,
        name: issue.summaryText,
        iconUrl: core.normalizeImageUrl(issue.img),
      }))

    return issues
  }
}

export default useIssueSearch
