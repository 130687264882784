import { InitializerInterface, Jira } from "@easy-templates/types"

import platform from "./platform"

enum JiraModule {
  ApplyTemplate = "com.appliger.jira.templates__template-apply-link",
  ManageProjectTemplates = "com.appliger.jira.templates__manage-templates-project-admin-panel",
  ManageTemplates = "com.appliger.jira.templates__manage-templates-link",
  SaveTemplate = "com.appliger.jira.templates__template-new-link",
  NewIssueGlobal = "com.appliger.jira.templates__issue-new-link",
  NewIssueProject = "com.appliger.jira.templates__project-issue-new-link",
}

type Context = {
  license: {
    active: boolean
  }
  jira?: {
    project?: {
      id: string
      key: string
    }
    issue?: {
      id: string
      issuetype: {
        id: string
      }
      key: string
    }
  }
}

type User = {
  atlassianAccountId: string
}

const moduleToPath = (module: JiraModule): string => {
  switch (module) {
    case JiraModule.ManageTemplates:
      return "/templates"
    case JiraModule.ManageProjectTemplates:
      return "/templates"
    case JiraModule.SaveTemplate:
      return "/template/new"
    case JiraModule.ApplyTemplate:
      return "/template/apply"
    case JiraModule.NewIssueGlobal:
      return "/issue/new-dialog"
    case JiraModule.NewIssueProject:
      return "/issue/new-dialog"
    default:
      return "/start"
  }
}

const getCurrentUser = () =>
  new Promise((resolve) => {
    platform.user.getCurrentUser(resolve)
  })

const observer = new ResizeObserver((_entries) => {
  platform.resize()
})

export default class Initializer implements InitializerInterface {
  async run() {
    const [platformContext, jwt, currentUser] =
      // @ts-ignore
      await Promise.all<[Context, string, User]>([
        platform.context.getContext(),
        platform.context.getToken(),
        getCurrentUser(),
      ])

    const path = moduleToPath(
      platform._data.options.uniqueKey as unknown as JiraModule
    )

    const context: Jira.PlatformContext = {
      accountId: currentUser.atlassianAccountId,
      cloudId: platform._hostOrigin,
      license: platformContext.license,
      issue: platformContext.jira?.issue,
      project: platformContext.jira?.project,
    }

    return {
      path,
      context,
      host: platform._hostOrigin,
      apiUrl: window.document.getElementById("root")?.dataset?.apiUrl,
      jwt,
      platform: "connect",
      onMount: () => {
        platform.theming.initializeTheming()
        observer.observe(document.body)
        setTimeout(platform.resize, 200) // Try to force a resize event
      },
      // @ts-ignore: This global constant is defined at compile time
      maintenanceModeOn: String(GLOBAL.MAINTENANCE_ON) === "true",
    }
  }
}
