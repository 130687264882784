import ModalDialog, { ModalBody } from 'components/ui/ModalDialog'
import Page from './ApplyTemplate'

const Dialog = () => {
  return (<ModalDialog shouldScrollInViewport={true} autoFocus={false}>
    <ModalBody>
      <Page />
    </ModalBody>
  </ModalDialog>)
}

export default Dialog
