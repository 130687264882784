import BasicField from "./basic";

export default class ProjectField extends BasicField {
  isSubmitable() {
    return true;
  }

  value() {
    return { id: this.context?.projectId }
  }
}
