/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableRankRequestOneOf1
 */
export interface VariableRankRequestOneOf1 {
    /**
     * ID of the variable to rank after
     * @type {string}
     * @memberof VariableRankRequestOneOf1
     */
    after: string;
}

/**
 * Check if a given object implements the VariableRankRequestOneOf1 interface.
 */
export function instanceOfVariableRankRequestOneOf1(value: object): value is VariableRankRequestOneOf1 {
    if (!('after' in value) || value['after'] === undefined) return false;
    return true;
}

export function VariableRankRequestOneOf1FromJSON(json: any): VariableRankRequestOneOf1 {
    return VariableRankRequestOneOf1FromJSONTyped(json, false);
}

export function VariableRankRequestOneOf1FromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableRankRequestOneOf1 {
    if (json == null) {
        return json;
    }
    return {
        
        'after': json['after'],
    };
}

export function VariableRankRequestOneOf1ToJSON(value?: VariableRankRequestOneOf1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'after': value['after'],
    };
}

