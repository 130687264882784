import React from 'react'
import Icon from './Icon'

import styles from './styles'

type Props = {
  children: React.ReactNode
  iconUrl?: string
  testId?: string
}

const Container = ({ children, iconUrl, testId }: Props): JSX.Element => (
  <div style={styles.root} data-testid={testId}>
    {iconUrl && <Icon src={iconUrl} />}
    {children}
  </div>
)
export default Container
