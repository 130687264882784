import { useParams } from "react-router-dom"

import { LogOnMount, UIEvent } from "components/Analytics"

import useTemplateData from "../useTemplateData"
import TemplateForm from "./Form"

const EditTemplatePage = (): JSX.Element => {
  const { templateId, issueId } = useParams()
  const { template, isLoading: isTemplateLoading, error } = useTemplateData(templateId)

  // FIXME: Add spinner and error handling
  return (
    <>
      <TemplateForm template={template} />
      <LogOnMount eventType={UIEvent.TEMPLATE_EDIT_PAGE_VISITED} />
    </>
  )
}

export default EditTemplatePage
