import { Template } from "@easy-templates/types"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "components/AppContextProvider"
import { formDataKeys } from "lib/queryKeys"

type Data = {
  templates: Template[]
  hasPermissions: boolean
}

type Props = {
  projectId: string
}

export const useFormData = (params: Props) => {
  const { core } = useAppContext()

  const { data, isLoading, error } = useQuery<Data, Error>({
    enabled: Boolean(params.projectId),
    queryKey: formDataKeys.keyFor("apply", params),
    queryFn: () => {
      return core.getApplyFormData(params)
    }
  })

  if (isLoading) {
    return {
      templates: [],
      hasPermissions: false,
      isLoading,
      error,
    }
  }

  if (error) {
    console.warn(error)

    return {
      templates: [],
      hasPermissions: false,
      isLoading: false,
      error,
    }
  }

  return {
    templates: data.templates || [],
    hasPermissions: Boolean(data.hasPermissions),
    isLoading,
    error,
  }
}

export default useFormData
