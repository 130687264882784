import { PerfTimer } from "@easy-templates/lib";
import { Jira } from "@easy-templates/types";

type API = (
  requestJira: Jira.AdapterInterface["request"],
  issueUpdates: Jira.IssueUpdateDetails[]
) => Promise<Jira.CreatedIssues>;

export const bulkCreateIssues: API = async (requestJira, issueUpdates) => {
  const timer = new PerfTimer("Bulk create issues");
  timer.start();

  var bodyData = JSON.stringify({ issueUpdates });

  const response = await requestJira<Jira.CreatedIssues>(
    `/rest/api/2/issue/bulk`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyData,
    }
  );

  timer.finish();

  return response;
};

export default bulkCreateIssues;
