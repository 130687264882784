interface ValueFinderInterface {
  idOrKey<T>(overrideValue?: T): { id: T } | { key: T } | unknown;
  sprintId(): string | null;
  objectValue(data: unknown): unknown;
}

export default class ValueFinder implements ValueFinderInterface {
  constructor(public value: unknown) {
    this.value = value;
  }

  idOrKey(overrideValue?: unknown) {
    if (overrideValue && typeof overrideValue === "string") {
      if (overrideValue.match(/-+/g)) {
        return { key: overrideValue };
      }

      return { id: overrideValue };
    }

    return this.value;
  }

  sprintId() {
    if (!this.value) {
      return null;
    }

    if (Array.isArray(this.value)) {
      if (this.value.length > 0) {
        return new ValueFinder(this.value[0]).sprintId();
      }

      return null;
    }

    if (Object.keys(this.value).includes("id")) {
      // @ts-ignore
      return this.value["id"];
    }

    if (typeof this.value === "string") {
      const idMatchResult = /id=(\d+),/.exec(this.value);

      if (idMatchResult) {
        return parseInt(idMatchResult[1]);
      }
    }

    return this.value;
  }

  getValue(data: Object | undefined | string) {
    if (!data) return data;
    if (Array.isArray(data)) return data;
    if (typeof data !== "object") return data;

    if ("id" in data) {
      return data["id"];
    }

    if ("value" in data) {
      return data["value"];
    }

    return data;
  }

  objectValue(data: unknown) {
    if (data && typeof data === "object") {
      if ("id" in data) {
        // @ts-ignore
        return { id: data.id };
      }

      if ("value" in data) {
        // @ts-ignore
        return { value: data.value };
      }

      if ("accountId" in data) {
        // @ts-ignore
        return { accountId: data.accountId };
      }
    }

    return data;
  }
}
