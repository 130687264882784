import { useState } from "react"

import Link from "components/Link"
import { NewTemplateFromIssueInline } from "components/NewTemplateFromIssue"
import { useAppContext } from "components/AppContextProvider"

import Button from "components/ui/Button"

import useStyles from "./useStyles"

const Description = () => {
  const { core } = useAppContext()
  const classes = useStyles()

  const [isCreateTemplateVisible, setIsCreateTemplateVisible] = useState(false)

  const handleShow = () => setIsCreateTemplateVisible(true)
  const handleHide = () => setIsCreateTemplateVisible(false)

  const handleTemplateCreated = ({ name }: { name: string }) => {
    core.showFlag({
      id: "template-created-flag",
      type: "success",
      title: name,
      description: "has been successfully created!",
    })

    handleHide()
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        Effortlessly create new Jira issues by utilizing pre-saved Templates.
        Transform Jira tasks into Templates and include issue subtasks for
        intricate, repetitive processes.
      </div>

      <div className={classes.section}>
        <Link href="https://help.appliger.com/easy-templates-for-jira/get-started">
          To Begin
        </Link>{" "}
        - navigate to any issue, save it as a template from the drop-down menu.
      </div>

      <div className={classes.section}>
        {isCreateTemplateVisible ? (
          <NewTemplateFromIssueInline
            onClose={handleHide}
            onCompleted={handleTemplateCreated}
          />
        ) : (
          <>
            Alternatively,{" "}
            <Button
              onClick={handleShow}
              appearance="primary"
              testId="get-started-create-template-button"
            >
              create Template from existing Issue
            </Button>
          </>
        )}
      </div>

      <div className={classes.section}>
        To Manage Saved Templates - access Project or System Administration
        Section "Easy Templates for Jira - Manage templates".
      </div>

      <div className={classes.section}>
        For detailed documentation, visit{" "}
        <Link href="https://help.appliger.com/easy-templates-for-jira/">
          Knowledge Base
        </Link>
      </div>

      <div className={classes.section}>
        Have issues or feature requests?{" "}
        <Link href="https://appliger.atlassian.net/servicedesk/customer/portal/1">
          Let’s get in touch!
        </Link>
      </div>
    </div>
  )
}

export default Description
