import { ButtonItem, MenuGroup, Section } from 'components/ui/Menu';

import SettingsIcon from 'components/ui/icons/SettingsIcon'
import VariableIcon from 'components/ui/icons/variable'

import useStyles from "./useStyles";

export default ({ issuesNavigation,
  onSettingsClick, isSettingsSelected, isVariablesSelected, onVariablesClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <MenuGroup>
        <Section title="Template">
          <ButtonItem
            onClick={onSettingsClick}
            iconBefore={<SettingsIcon label="Template settings" />}
            description={"Name, scope, etc"}
            isSelected={isSettingsSelected}
          >
            Details
          </ButtonItem>
          <ButtonItem
            onClick={onVariablesClick}
            iconBefore={<VariableIcon label="Form & Variables" />}
            isSelected={isVariablesSelected}
          >
            Form & Variables
          </ButtonItem>
        </Section>
        {issuesNavigation && <Section title="Issues">
          <div style={{ padding: 16 }}>{issuesNavigation}</div>
        </Section>}
      </MenuGroup>
    </div>
  )
}
