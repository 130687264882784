import BasicField from "./basic"

export default class ParentField extends BasicField {
  value() {
    if (this.context?.parentKey) {
      return { key: this.context?.parentKey }
    }

    return { key: this.body.key }
  }
}
