import { createUseStyles } from "react-jss";

export default createUseStyles({
  root: {
    margin: "48px auto",
    width: "500px",
    textAlign: "left" as const,
  },
  section: {
    paddingTop: "16px",
  },
});
