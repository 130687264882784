import BasicField from "./basic";

export default class TeamField extends BasicField {
  value() {
    return this.valueFinder.getValue(this.body);
  }

  prefillValue() {
    return this.valueFinder.getValue(this.body);
  }
}
