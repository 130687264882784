import { Jira } from "@easy-templates/types";

type Searcher = (
  requestJira: Jira.AdapterInterface["request"],
  jql: string,
  offset: number,
  maxResults: number
) => Promise<Jira.SearchResults>;

const searchPage: Searcher = async (
  requestJira,
  jql,
  offset = 0,
  maxResults = 50
) => {
  const response = await requestJira<Jira.SearchResults>(
    `/rest/api/2/search?jql=${jql}&startAt=${offset}&maxResults=${maxResults}&fields=*all,-subtasks,-comment,-attachment`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response;
};

// Searches for issues using JQL
export default class Search {
  maxResults: number;
  searcher: Searcher;

  constructor(
    public requestJira: Jira.AdapterInterface["request"],
    public jql: string,
    maxResults?: number
  ) {
    this.jql = jql;
    this.searcher = searchPage;
    this.maxResults = maxResults || 50;
  }

  setSearcher(searcher: Searcher) {
    this.searcher = searcher;
  }

  async page(page = 1) {
    if (page < 1) {
      throw new Error("The page must be > 0");
    }

    const startAt = this.maxResults * (page - 1);

    return await this.searcher(
      this.requestJira,
      this.jql,
      startAt,
      this.maxResults
    );
  }

  async all(): Promise<Jira.IssueBean[]> {
    const { issues, total } = await this.page();

    const totalPages = Math.ceil(total / this.maxResults);

    if (totalPages > 1) {
      const results = await Promise.all(
        [...this.pagesRange(totalPages, 2)].map((page) => this.page(page))
      );

      return results.reduce((allIssues, { issues }) => {
        return [...allIssues, ...issues];
      }, issues);
    }

    return issues;
  }

  pagesRange(end = 1, start = 0, step = 1) {
    function* generateRange() {
      let x = start - step;
      while (x < end) yield (x += step);
    }
    return {
      [Symbol.iterator]: generateRange,
    };
  }
}
