import { VariableType } from "@easy-templates/types"
import Button from "components/ui/Button"
import DropdownMenu, { DropdownItemGroup, DropdownItem } from "components/ui/DropdownMenu"
import Lozenge from "components/ui/Lozenge"
import { ChevronDownIcon } from "components/ui/icons"
import { useVariableOptions } from "components/Variables"

type Props = {
  onClick: (type: VariableType) => void
}

const AddButton = ({ onClick }: Props) => {
  const soon = <Lozenge appearance="new">Soon</Lozenge>

  const trigger = ({ triggerRef, ...props }) => (
    <Button
      {...props}
      iconAfter={<ChevronDownIcon label="add" />}
      ref={triggerRef}
      testId="add-variable-button"
    >
      Add variable
    </Button >
  )

  const { options } = useVariableOptions()

  return (
    <DropdownMenu<HTMLButtonElement> trigger={trigger}>
      <DropdownItemGroup>
        {options.map(({ type, Icon, label, comingSoon }) => (
          <DropdownItem
            key={label}
            elemBefore={<Icon label={label} />}
            elemAfter={comingSoon && soon}
            isDisabled={comingSoon}
            onClick={() => onClick(type)}
          >
            {label}
          </DropdownItem>
        ))}
      </DropdownItemGroup>
    </DropdownMenu>
  )
}
export default AddButton
