import { createUseStyles } from "react-jss";

export default createUseStyles({
  root: {
    margin: 0,
    maxWidth: 700,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "16px",
  },
  buttons: {
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  help: {
    position: "absolute",
    right: 20,
    top: 20,
  },
  links: {
    display: "flex",
    alignItems: "center",
    "& > :not(:first-child)": {
      paddingLeft: 16,
    },
  },
});
