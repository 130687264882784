import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Template } from '@easy-templates/types'
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

const useTemplateDeletion = () => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutate } = useMutation({
    mutationFn: async (id: string) => {
      return await core.deleteTemplate(id)
    },
    onMutate: async (id: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: templateKeys.list() })
      await queryClient.cancelQueries({ queryKey: templateKeys.details(id) })

      // Snapshot the previous value
      const oldTemplates = queryClient.getQueryData(templateKeys.list())

      // Optimistically update to the new value
      queryClient.setQueryData(templateKeys.list(), (oldTemplates: Template[]) => {
        return oldTemplates.filter((tmpl) => tmpl.id !== id)
      })

      // Return a context object with the snapshotted value
      return { oldTemplates }
    },
    onError: (_err, _id, context) => {
      queryClient.setQueryData(templateKeys.list(), context.oldTemplates)
    },
    onSuccess: () => {
      core.showFlag({
        id: "template-deleted",
        description: "Template deleted",
        type: "success",
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.list() })
    },
  })

  return { deleteTemplate: mutate }
}

export default useTemplateDeletion
