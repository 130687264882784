import { useAnalytics, UIEvent, UIScope } from "components/Analytics"

import Actions, { Action } from "./Actions"
import useStyles from "./useStyles"

import Tree from "../Tree"
import type { TreeNode } from "../Tree"

type Props = {
  id: string
  parentId?: string
  title: string
  nodes: TreeNode[]
  onCopy: (id: string, name: string) => void
  onSelect: (id: string) => void
  onDelete: (id: string) => void
  selectedId?: string
  iconUrl?: string
}

const Item = ({
  id,
  parentId,
  title,
  nodes,
  onCopy,
  onSelect,
  selectedId,
  iconUrl,
  onDelete,
}: Props): JSX.Element => {
  const isSelected = id === selectedId
  const hasActions = !isSelected || parentId

  const handleCopyClick = () => {
    onCopy(id, `Copy of ${title}`)
  }

  const handleDeleteClick = () => {
    onDelete(id)
  }

  const handleEditClick = () => !isSelected && onSelect(id)

  const { instrument } = useAnalytics(UIScope.TEMPLATE_NAVIGATION_TREE)
  const classes = useStyles({ isSelected })

  return (
    <div className={classes.root} data-testid="navigation-item">
      <div className={classes.issueContainer}>
        <div onClick={handleEditClick} className={classes.issueNameContainer}>
          {iconUrl && (
            <img alt={title} src={iconUrl} className={classes.issueIcon} />
          )}
          <div className={classes.issueName}>
            <span title={title}>{title}</span>
          </div>
        </div>
        <div className={classes.actions} data-testid="actions-button">
          {hasActions && (
            <Actions>
              {!isSelected && (
                <Action
                  onClick={instrument(
                    UIEvent.EDIT_BUTTON_CLICKED,
                    handleEditClick
                  )}
                  data-testid="issues-list__item__actions-edit"
                >
                  Edit
                </Action>
              )}
              {parentId && (
                <Action
                  onClick={instrument(
                    UIEvent.COPY_BUTTON_CLICKED,
                    handleCopyClick
                  )}
                  data-testid="issues-list__item__actions-copy"
                >
                  Copy
                </Action>
              )}
              {parentId && (
                <Action
                  onClick={instrument(
                    UIEvent.DELETE_BUTTON_CLICKED,
                    handleDeleteClick
                  )}
                  data-testid="issues-list__item__actions-delete"
                >
                  Delete
                </Action>
              )}
            </Actions>
          )}
        </div>
      </div>

      <div className={classes.nestedTree}>
        {nodes?.map((node) => (
          <Tree
            key={node.id}
            tree={node}
            parentId={id}
            onCopy={onCopy}
            onSelect={onSelect}
            onDelete={onDelete}
            selectedId={selectedId}
          />
        ))}
      </div>
    </div>
  )
}

export default Item
