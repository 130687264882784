import { useParams } from "react-router-dom"
import { LogOnMount, UIEvent } from "components/Analytics"

import IssueForm from "./Form"
import VariablesProvider from "components/VariablesProvider"

const EditTemplatePage = (): JSX.Element => {
  const { templateId, issueId } = useParams()

  return (
    <VariablesProvider templateId={templateId}>
      <IssueForm
        templateId={templateId}
        issueId={issueId}
      />
      <LogOnMount eventType={UIEvent.ISSUE_EDIT_PAGE_VISITED} />
    </VariablesProvider>
  )
}

export default EditTemplatePage
