import Select, {
  AsyncSelect,
  AsyncSelectProps,
  SelectProps,
} from "components/ui/Select";

import OptionLabel, { getOptionLabel, getOptionValue } from "./Option";

import styles from "./styles";

const SelectWithIcon = (props: SelectProps<any, false>) => (
  <div style={styles} data-testid={props.testId || "select"}>
    <Select
      isClearable={false}
      formatOptionLabel={OptionLabel}
      // @ts-ignore
      getOptionLabel={getOptionLabel}
      // @ts-ignore
      getOptionValue={getOptionValue}
      isMulti={false}
      {...props}
    />
  </div>
);

export const AsyncSelectWithIcon = (
  props: AsyncSelectProps<{ name: string; key: string; iconUrl: string }, false>
) => (
  <div style={styles} data-testid={props.testId || "async-select"}>
    <AsyncSelect
      isClearable={false}
      formatOptionLabel={OptionLabel}
      getOptionLabel={getOptionLabel}
      // @ts-ignore
      getOptionValue={getOptionValue}
      isMulti={false}
      {...props}
    />
  </div>
);

export default SelectWithIcon;
