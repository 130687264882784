import { Jira } from "@easy-templates/types"
import { JiraAPI, PlatformAdapterInterface } from "@easy-templates/lib"

import platform from "./platform"

const request = async (url: string, opts: { [key: string]: unknown } = {}) => {
  console.debug("Jira: request", { url, opts })

  const { method, body, ...rest } = opts

  const options = {
    ...rest,
    url,
    contentType: "application/json",
  }

  if (body) {
    options["data"] = body
  }

  if (method) {
    options["type"] = method
  }

  const result = await platform.request(options)

  const response = new Response(result.body)

  console.debug(`Response: ${response.status} ${response.statusText}`)

  return response.json()
}

export default class JiraClient implements PlatformAdapterInterface {
  closeDialog() {
    return platform.dialog.close()
  }

  showFlag(options: {
    id: string
    title?: string
    description: string
    type?: "error" | "success" | "warning" | "info"
    actions?: { text: string; onClick: () => void }[]
  }) {
    console.debug("Jira: showFlag", { options })

    const { actions, description, ...rest } = options

    // TODO: Implement actions
    // const handleFlagAction = ({ actionIdentifier }) => {
    //   console.debug("flag handler", { actions, actionIdentifier })
    //   const action = actions.find(
    //     ({ text }) => {
    //       console.debug({ text, actionIdentifier })
    //       return text === actionIdentifier
    //     }
    //   )
    //
    //   console.debug("flag handler", { action })
    //
    //   if (action && action.onClick) {
    //     action.onClick()
    //   }
    // }
    //
    // const flagActions = Object.fromEntries(
    //   (actions || []).map(({ text }) => [text, text])
    // )
    //
    // platform.events.off('flag.action', handleFlagAction)
    // platform.events.on('flag.action', handleFlagAction)

    return platform.flag.create({
      close: "auto",
      body: description,
      // actions: flagActions,
      ...rest,
    })
  }

  async getIssue(id: string) {
    console.debug("Jira: Get issue", { id })
    const jiraAPI = new JiraAPI(request)

    return jiraAPI.getIssue(id)
  }

  openCreateIssueDialog(context, onClose) {
    console.debug("Jira: openCreateIssueDialog", { context, onClose })
    const { issueTypeId, project, issuetype, reporter, ...fields } = context

    try {
      platform.jira.openCreateIssueDialog(onClose, {
        // @ts-ignore
        pid: project.id,
        issueType: issueTypeId as string,
        fields,
      })
    } catch (_error) {
      throw new Error("Desription should be a string in the Connect app")
    }
  }

  closeApplyTemplateDialog() {
    console.debug("Jira: closeApplyTemplateDialog")

    platform.dialog.close()
  }

  closeSaveAsTemplateDialog() {
    console.debug("Jira: closeSaveAsTemplateDialog")
    platform.dialog.close()
  }

  closeNewIssueDialog() {
    console.debug("Jira: closeNewIssueDialog")
    platform.dialog.close()
  }

  openExternalLink(path: string) {
    console.debug("Jira: openExternalLink", path)
    window.open(path, "_blank")
  }

  refreshIssuePage() {
    console.debug("Jira: refreshIssuePage")
    platform.jira.refreshIssuePage()
  }

  async request(url: string, opts: { [key: string]: unknown } = {}) {
    console.debug("Jira: request", { url, opts })

    return request(url, opts)
  }

  showIssueCreatedMessage({ issueKey }) {
    console.debug("Jira: showIssueCreatedMessage", { issueKey })

    const navigateToIssue = () => {
      platform.navigator.go("issue", { issueKey })
      platform.dialog.close()
    }

    const handleFlagAction = ({ actionIdentifier }) => {
      if (actionIdentifier === "visit") {
        navigateToIssue()
      }
    }

    platform.events.off("flag.action", handleFlagAction)
    platform.events.on("flag.action", handleFlagAction)

    platform.flag.create({
      body: `Issue ${issueKey} has been successfully created`,
      type: "success",
      close: "auto",
      actions: { visit: "Open" },
    })
  }

  navigateToIssue({ key }) {
    platform.navigator.go("issue", { issueKey: key })
  }

  resize() {
    console.debug("Jira: resize")
    // platform.resize()
  }

  initializeTheming() {
    platform.theming.initializeTheming()
  }

  async getIssuePickerSuggestions(query: string) {
    console.debug("Jira: Get issue picker suggestion", { query })
    const jiraAPI = new JiraAPI(request)

    return jiraAPI.getIssuePickerSuggestions(query, {
      currentJQL: "",
    })
  }

  normalizeImageUrl(url: string) {
    const baseUrl = platform._hostOrigin

    return new URL(url, baseUrl).toString()
  }

  async getProjects(params) {
    console.debug("Jira: Get projects", params)

    const jiraAPI = new JiraAPI(request)

    const projects = await jiraAPI.getProjects(params)

    return projects
  }

  async getPermittedProjects() {
    console.debug("Jira: Get permitted projects")

    const jiraAPI = new JiraAPI(request)

    const projects = await jiraAPI.getPermittedProjects()

    return projects
  }

  async getCreateMeta(params: { projectIds: string[] }) {
    console.debug("Jira: Get create meta", { params })

    const jiraAPI = new JiraAPI(request)

    return jiraAPI.getCreateMeta(params)
  }

  async getUser(accountId: string) {
    console.debug("Jira: Get user", { accountId })

    const jiraAPI = new JiraAPI(request)

    return jiraAPI.getUser(accountId)
  }
}
