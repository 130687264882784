import { token } from "@atlaskit/tokens";
import { createUseStyles } from "react-jss";

type Props = {
  isSelected?: boolean;
};

export default createUseStyles({
  root: {},
  container: {
    display: "flex",
    padding: "4px 4px 4px 6px",
    justifyContent: "space-between",
    overflowWrap: "break-word",
    overflow: "hidden",
    flexGrow: 1,
  },
  icon: {
    backgroundColor: token("color.border", "#dfdfdf"),
    borderRadius: 2,
    textIndent: "-10000px",
    height: 16,
    width: 16,
    flexGrow: 0,
    marginRight: 4,
    marginTop: 2,
  },
  content: {
    color: ({ isSelected }: Props = {}) =>
      isSelected ? token("color.text.selected", "#0052cc") : "inherit",
    flexGrow: 1,
  },
});
