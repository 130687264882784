import sortBy from "lodash/sortBy";

import Tree from "./Tree";
import type { TreeNode } from "./Tree";

type Item = {
  id: string;
  name: string;
  iconUrl: string;
  rank: string;
  children: string[];
};

type Tree = {
  rootId: string;
  items: {
    [key: string]: Item;
  }
};

type Props = {
  selectedId?: string;
  tree: Tree;
  onCopy: (id: string, name: string) => void;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
};

const getNode = (itemId: string, items: { [id: string]: Item }): TreeNode | null => {
  const item = items[itemId];

  if (!item) {
    return null;
  }

  const children = item?.children
    .map((id: string) => getNode(id, items))
    .filter(Boolean);

  return {
    ...item,
    children: sortBy(children, ["rank"]),
  };
};

const NavigationTree = ({
  tree,
  onCopy,
  onDelete,
  onSelect,
  selectedId,
}: Props): JSX.Element => {
  return (
    <Tree
      tree={getNode(tree.rootId, tree.items)}
      onCopy={onCopy}
      onDelete={onDelete}
      onSelect={onSelect}
      selectedId={selectedId}
    />
  );
};

export default NavigationTree;
