import { Jira } from "@easy-templates/types"

export const getUser = async (
  requestJira: Jira.AdapterInterface["request"],
  accountId: string
) => {
  const response = await requestJira<Jira.User>(
    `/rest/api/3/user?accountId=${accountId}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  )

  return response
}
