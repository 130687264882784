import { useCallback } from "react"
import { Link } from "react-router-dom"

import { Template, Jira } from "@easy-templates/types"

import DynamicTable from "components/ui/DynamicTable"
import IconText from "components/ui/IconText"
import User from "components/user"
import { useAppContext } from "components/AppContextProvider"

import Actions from "./Actions"
import Scope from "./Scope"

type Props = {
  templates: Template[]
  projects: Jira.Project[]
  onCopyClick: (template: Template) => void
  onDeleteClick: (id: string) => void
}

const TemplatesList = ({
  templates,
  projects,
  onCopyClick,
  onDeleteClick,
}: Props): JSX.Element => {
  const { core } = useAppContext()

  const onSetPage = useCallback(() => {
    if (!core) return

    setTimeout(core.resizeView, 50)
  }, [core])

  const head = {
    cells: [
      { content: "Name", isSortable: true, key: "name" },
      { content: "Issue type", isSortable: true, key: "type" },
      { content: "Scope", isSortable: false, key: "scope" },
      { content: "Created by", isSortable: false, key: "createdBy" },
      { width: 8 },
    ],
  }

  const rows = templatesToRows({
    onCopyClick,
    onDeleteClick,
    templates: templates || [],
    projects: projects || [],
  })

  return (
    <div style={{ padding: "0 16px" }}>
      <DynamicTable
        head={head}
        rows={rows}
        onSetPage={onSetPage}
        rowsPerPage={20}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={false}
        isFixedSize={true}
        defaultSortKey="name"
        defaultSortOrder="ASC"
        testId="templatesList"
        emptyView={<div>Not found</div>}
      />
    </div>
  )
}

const templatesToRows = ({
  templates,
  projects,
  onCopyClick,
  onDeleteClick,
}) => {
  return templates.map((template: Template) => {
    return {
      key: template.id,
      cells: [
        {
          key: template.name,
          content: (
            <Link to={`/template/edit/${template.id}`}>{template.name}</Link>
          ),
        },
        {
          key: template.issuetype.name,
          content: (
            <IconText iconUrl={template.issuetype.iconUrl}>
              {template.issuetype.name}
            </IconText>
          ),
        },
        {
          key: template.scope,
          content: (
            <Scope
              type={template.scope}
              projects={projects.filter(({ id }) =>
                template.scopeValue.includes(id)
              )}
            />
          ),
        },
        {
          key: template.createdBy,
          content: (<User accountId={template.createdBy} />),
        },
        {
          content: (
            <div style={{ textAlign: "center" }}>
              <Actions
                templateId={template.id}
                onCopyClick={() => onCopyClick(template)}
                onDeleteClick={() => onDeleteClick(template.id)}
              />
            </div>
          ),
        },
      ],
    }
  })
}

export default TemplatesList
