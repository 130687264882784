import BasicField from "./basic";

export default class AgileRankField extends BasicField {
  value() {
    return { value: this.body };
  }

  isSubmitable() {
    return false
  }
}
