import { createUseStyles } from "react-jss";

type Props = {
  isLoading?: boolean;
};

export default createUseStyles({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // Forge:
    // height: '100vh',
    opacity: ({ isLoading }: Props) => (isLoading ? 0.7 : 1),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    // border: '1px solid green',
    flex: 1,
  },
  title: {
    flexGrow: 0,
    // padding: '24px 24px 12px 24px',
    padding: "24px 0px 12px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    flex: 1,
    // padding: '12px 24px 12px 24px',
    padding: "12px 0px 12px 0px",
  },
  barContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttons: {
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  footer: {
    // padding: '12px 24px 20px 24px',
    padding: "12px 0px 20px 0px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toolbar: {
    position: "absolute",
    // right: 26,
    right: 2,
    top: 26,
  },
});
