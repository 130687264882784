import { Jira } from "@easy-templates/types"
import BaseAction from "./base"
import { State } from "../types"

export default class IssuetypeSelectedAction extends BaseAction {
  selectedIssuetype: Jira.IssueTypeDetails

  constructor(selectedIssuetype: Jira.IssueTypeDetails) {
    super()
    this.selectedIssuetype = selectedIssuetype
  }

  apply(state: State): State {
    const issuetypeTemplates = this.getIssuetypeTemplates(
      this.selectedIssuetype,
      state.allTemplates
    )

    if (!state.selectedProject) {
      return state
    }

    return {
      ...state,
      selectedIssueType: this.selectedIssuetype,
      templates: issuetypeTemplates,
      selectedTemplate: (issuetypeTemplates || [])[0],
      errorMessage: null,
      warnings: [],
    }
  }
}
