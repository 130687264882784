import { useQuery, useQueryClient } from "@tanstack/react-query"

import { useAppContext } from 'components/AppContextProvider'
import { jobKeys } from 'lib/queryKeys'

interface Return {
  isLoading: boolean
}

type Props = {
  id: string
  onChange: (job: unknown) => void
  onError: (error: Error) => void
}

const useJob = ({ id, onChange, onError }: Props): Return => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  return useQuery({
    enabled: !!id,
    queryKey: jobKeys.details(id),
    queryFn: async () => {
      try {
        const job = await core.getJob(id)

        console.debug("ConnectTemplate.getJob()", { id, job })

        onChange(job)

        return job
      } catch (error) {
        onError(error)
      }
    },
    onSettled: () => {
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: jobKeys.details(id) }),
        1000
      )
    },
  })
}

export default useJob
