import type { CustomGlyphProps } from '@atlaskit/icon/types'
import Icon, { IconProps } from '@atlaskit/icon'

const CustomGlyph = (props: CustomGlyphProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <defs>
      <mask id="text-mask">
        <rect width="100%" height="100%" fill="white" />
        <g fill="black">
          <g fill="#000000" transform="translate(4.5, 7.5)">
            <path d="M2.58 6.01L3.87 1.73L5.18 1.73L3.20 7.42L1.97 7.42L0 1.73L1.30 1.73L2.58 6.01ZM8.63 6.25L6.57 6.25L6.18 7.42L4.94 7.42L7.05 1.73L8.14 1.73L10.27 7.42L9.02 7.42L8.63 6.25M6.89 5.30L8.31 5.30L7.60 3.17L6.89 5.30ZM12.91 5.34L11.97 5.34L11.97 7.42L10.80 7.42L10.80 1.73L12.91 1.73Q13.92 1.73 14.47 2.18Q15.02 2.63 15.02 3.45Q15.02 4.04 14.76 4.42Q14.51 4.81 14 5.04L15.23 7.37L15.23 7.42L13.97 7.42L12.91 5.34M11.97 4.39L12.92 4.39Q13.36 4.39 13.60 4.17Q13.84 3.94 13.84 3.55Q13.84 3.14 13.62 2.91Q13.39 2.68 12.91 2.68L11.97 2.68L11.97 4.39Z" />
          </g>
        </g>
      </mask>
    </defs>

    <rect width="20" height="14" fill="currentcolor" x="2" y="5" rx="2" ry="2" mask="url(#text-mask)" />
  </svg>)

const VariableIcon = (props: IconProps) => {
  return <Icon glyph={CustomGlyph} {...props} />
}

export default VariableIcon