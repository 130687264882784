/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    isDisabled?: boolean;
}

/**
 * Check if a given object implements the Field interface.
 */
export function instanceOfField(value: object): value is Field {
    return true;
}

export function FieldFromJSON(json: any): Field {
    return FieldFromJSONTyped(json, false);
}

export function FieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): Field {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'isDisabled': json['isDisabled'] == null ? undefined : json['isDisabled'],
    };
}

export function FieldToJSON(value?: Field | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'isDisabled': value['isDisabled'],
    };
}

