import BaseAction from "./base";
import { State } from "../types";

export default class IssueCreationRequestedAction extends BaseAction {
  constructor() {
    super();
  }

  apply(state: State): State {
    return {
      ...state,
      errorMessage: null,
      results: {},
    };
  }
}
