import React from 'react'
import Container from './Container'

type Option = {
  name: string
  value?: string
  summary?: string
  key?: string
  id?: string
}

type Props =
  | {
    key?: string
    avatarUrls?: {
      '16x16': string
    }
    displayName?: string
    name?: string
    value?: string
    iconUrl?: string
    avatarUrl?: string
    summary?: string
  }
  | string

type Value = string | number | null | undefined | unknown

export const getOptionLabel = (opt: Option): Value => {
  const label =
    typeof opt === 'object' ? opt.name || opt.summary || opt.value : opt

  return label
}
export const getOptionValue = (opt: Option): Value => {
  const value = typeof opt === 'object' ? opt.id || opt.key || opt.value : opt

  return value
}

const Option = (option: Props): JSX.Element => {
  if (typeof option === 'string') {
    return <Container>{option}</Container>
  }

  const { avatarUrl, displayName, avatarUrls, name, iconUrl, summary, value } = option

  const label = stripHtml(displayName || name || summary || value)
  const testId = `option-${label.replace(/\s/g, '-')}`

  return (
    <Container iconUrl={iconUrl || avatarUrl || (avatarUrls && avatarUrls['16x16'])} testId={testId}>
      {label}
    </Container>
  )
}

const stripHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ""
}

export default Option
