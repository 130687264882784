/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableCreateRequest
 */
export interface VariableCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof VariableCreateRequest
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof VariableCreateRequest
     */
    type?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof VariableCreateRequest
     */
    required?: boolean;
    /**
     * Label of the variable, used in markup
     * @type {string}
     * @memberof VariableCreateRequest
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof VariableCreateRequest
     */
    defaultValue?: string | null;
}



/**
 * Check if a given object implements the VariableCreateRequest interface.
 */
export function instanceOfVariableCreateRequest(value: object): value is VariableCreateRequest {
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function VariableCreateRequestFromJSON(json: any): VariableCreateRequest {
    return VariableCreateRequestFromJSONTyped(json, false);
}

export function VariableCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'type': json['type'] == null ? undefined : json['type'],
        'required': json['required'] == null ? undefined : json['required'],
        'label': json['label'],
        'defaultValue': json['defaultValue'] == null ? undefined : json['defaultValue'],
    };
}

export function VariableCreateRequestToJSON(value?: VariableCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'type': value['type'],
        'required': value['required'],
        'label': value['label'],
        'defaultValue': value['defaultValue'],
    };
}

