import { ButtonGroup, LoadingButton } from "components/ui/Button";

type Item = {
  id: string;
  label: string;
  onClick: () => void;
  isProcessing?: boolean;
  isDisabled?: boolean;
  appearance?: "default" | "primary" | "subtle";
  leftIcon?: React.Component;
  rightIcon?: React.Component;
};

interface Props {
  items: Item[];
  onClose?: () => void;
}

const Actions = ({ items, onClose }: Props): JSX.Element | null => {
  return (
    <ButtonGroup>
      {onClose && (
        <LoadingButton onClick={onClose} appearance="subtle-link">
          Close
        </LoadingButton>
      )}
      {items.map(
        ({
          appearance,
          id,
          isDisabled,
          isProcessing,
          label,
          onClick,
          leftIcon: IconBefore,
          rightIcon: IconAfter,
        }) => (
          <LoadingButton
            testId={`new-issue-${id}-button`}
            key={label}
            onClick={onClick}
            appearance={appearance || "default"}
            isDisabled={isDisabled}
            isLoading={isProcessing}
            iconBefore={
              // @ts-ignore
              IconBefore ? <IconBefore label={label} size="small" /> : null
            }
            iconAfter={
              // @ts-ignore
              IconAfter ? <IconAfter label={label} size="small" /> : null
            }
          >
            {label}
          </LoadingButton>
        )
      )}
    </ButtonGroup>
  );
};

export default Actions;
