import { VariableValues, Templates, Jira } from "@easy-templates/types"
import { useAppContext } from "@easy-templates/ui"

type Props = {
  onComplete: (data: unknown) => void
  onError: (error: Error) => void
}

type PrefillParams = {
  variableValues: VariableValues
  projectId: string
  issueTypeId?: string
  templateId: string
}

interface Return {
  prefill: (params: PrefillParams) => Promise<void>
}

const usePrefillIssue = (props: Props): Return => {
  const { onError, onComplete } = props
  const { core } = useAppContext()

  const prefill = async ({
    templateId,
    variableValues,
    projectId,
    issueTypeId,
  }: PrefillParams) => {
    try {
      const issueContext = await core.getIssuePrefillData({
        templateId,
        projectId,
        issueTypeId,
        variableValues,
      })

      core.openCreateIssueDialog(
        {
          ...issueContext,
          issueType: issueTypeId, // DC
          issueTypeId // Connect, Forge?
        },
        (data: unknown) => {
          onComplete(data)
        }
      )
    } catch (error) {
      onError(error)
    }
  }

  return {
    prefill,
  }
}

export default usePrefillIssue
