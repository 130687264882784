import React from 'react'

import styles from './styles'

type Props = {
  value: string
}

const Key = ({ value }: Props): JSX.Element => (
  <span style={styles.key}>
    {'('}
    {value.toUpperCase()}
    {')'}
  </span>
)

export default Key
