import BasicField from "./basic";

type Value = {
  id: string;
  value: string;
  child?: Value;
};

export default class CascadingSelectField extends BasicField {
  isCascading(): boolean {
    return true;
  }

  value() {
    return this.body;
  }

  prefillValue(): string[] {
    return this.getValues(this.value());
  }

  getValues(value: Value): string[] {
    if (value.child) {
      return [
        this.valueFinder.getValue(value),
        this.getValues(value.child),
      ].flat();
    }

    return [this.valueFinder.getValue(value)];
  }
}
