import { useMutation, useQueryClient } from "@tanstack/react-query"
import { TemplateVariable } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from 'lib/queryKeys'

type Params = {
  id: string
}

const useDeleteVariable = ({ templateId }: { templateId: string }) => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutate } = useMutation({
    mutationFn: async ({ id }: Params) => {
      return core.deleteTemplateVariable(templateId, id)
    },
    onMutate: async ({ id }) => {
      const variablesQueryKey = templateKeys.variables(templateId)
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: variablesQueryKey })

      // Snapshot the previous value
      const oldVariables = queryClient.getQueryData(variablesQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(variablesQueryKey, (oldVariables: TemplateVariable[]) => {
        return oldVariables.filter(variable =>
          variable.id !== id
        )
      })

      // Return a context object with the snapshotted value
      return { oldVariables }
    },
    onSettled: (_data, _error, { }) => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.variables(templateId),
      })

      queryClient.invalidateQueries({
        queryKey: templateKeys.details(templateId),
      })
    },
  })

  return { deleteVariable: mutate }
}

export default useDeleteVariable
