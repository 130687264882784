// A workaround to support numeric field names.
// See https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object

import { VariableValues } from "@easy-templates/types"
export const fieldNameRealToForm = (name: string) => `variableValues[_${name.toLocaleLowerCase()}]`
const fieldNameFormToReal = (name: string) => name.replace(/^_/, '')

/**
 * Transforms the variable values object keys to the real field names.
 */
export const normalizeVariableValuesKeys = (values: unknown): VariableValues => {
  if (!values) { return {} }

  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => [fieldNameFormToReal(key), value])
  )
}
