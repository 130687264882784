import { Jira } from "@easy-templates/types";

export const updateIssue = async (
  requestJira: Jira.AdapterInterface["request"],
  id: string,
  issueUpdates: Jira.IssueUpdateDetails
) => {
  var bodyData = JSON.stringify(issueUpdates);

  const response = await requestJira(`/rest/api/2/issue/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: bodyData,
  });

  return response;
};

export default updateIssue;
