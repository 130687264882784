import { Jira } from "@easy-templates/types";
import BaseAction from "./base";
import { State } from "../types";

export default class ProjectSelectedAction extends BaseAction {
  selectedProject: Jira.Project;

  constructor(selectedProject: Jira.Project) {
    super();
    this.selectedProject = selectedProject;
  }

  apply(state: State): State {
    return {
      ...state,
      selectedProject: this.selectedProject
    };
  }
}
