import React from 'react'
import Spinner from 'components/ui/Spinner'

type Props = {
  size?: number | 'large' | 'small' | 'xsmall' | 'medium' | 'xlarge' | undefined
}

const Loading: React.FC<Props> = ({ size }) => (
  <div
    data-testid="loading"
    style={{
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    }}
  >
    <div style={{ margin: 32 }}>
      <Spinner size={size} />
    </div>
  </div>
)

Loading.defaultProps = {
  size: 'large',
}

export default Loading
