// import React from 'react'
// import Link from 'components/Link'
//
// import useStyles from './useStyles'

const SupportUkraine = (): JSX.Element => {
  return null
  // const classes = useStyles()
  //
  // return (
  //   <div className={classes.root}>
  //     <div className={classes.flag}>&nbsp;</div>
  //     <Link href='https://go.appliger.com/support-ua'>Support Ukraine</Link>
  //   </div>
  // )
}

export default SupportUkraine
