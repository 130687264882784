import { Jira } from "@easy-templates/types";

export const getServerInfo = async (
  requestJira: Jira.AdapterInterface["request"]
) => {
  const response = await requestJira(`/rest/api/3/serverInfo`, {
    headers: {
      Accept: "application/json",
    },
  });

  return response;
};
