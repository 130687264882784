/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableRankRequestOneOf
 */
export interface VariableRankRequestOneOf {
    /**
     * ID of the variable to rank before
     * @type {string}
     * @memberof VariableRankRequestOneOf
     */
    before: string;
}

/**
 * Check if a given object implements the VariableRankRequestOneOf interface.
 */
export function instanceOfVariableRankRequestOneOf(value: object): value is VariableRankRequestOneOf {
    if (!('before' in value) || value['before'] === undefined) return false;
    return true;
}

export function VariableRankRequestOneOfFromJSON(json: any): VariableRankRequestOneOf {
    return VariableRankRequestOneOfFromJSONTyped(json, false);
}

export function VariableRankRequestOneOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableRankRequestOneOf {
    if (json == null) {
        return json;
    }
    return {
        
        'before': json['before'],
    };
}

export function VariableRankRequestOneOfToJSON(value?: VariableRankRequestOneOf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'before': value['before'],
    };
}

