/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateUsingResult
 */
export interface TemplateUsingResult {
    /**
     * 
     * @type {string}
     * @memberof TemplateUsingResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateUsingResult
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateUsingResult
     */
    self?: string;
}

/**
 * Check if a given object implements the TemplateUsingResult interface.
 */
export function instanceOfTemplateUsingResult(value: object): value is TemplateUsingResult {
    return true;
}

export function TemplateUsingResultFromJSON(json: any): TemplateUsingResult {
    return TemplateUsingResultFromJSONTyped(json, false);
}

export function TemplateUsingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateUsingResult {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'key': json['key'] == null ? undefined : json['key'],
        'self': json['self'] == null ? undefined : json['self'],
    };
}

export function TemplateUsingResultToJSON(value?: TemplateUsingResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'key': value['key'],
        'self': value['self'],
    };
}

