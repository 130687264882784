import { createContext, useContext, useEffect } from "react"

export enum UIScope {
  NONE = "",
  NEW_TEMPLATE_FROM_ISSUE = "New Template From Issue",
  TEMPLATE_NAVIGATION_TREE = "Template Navigation Tree",
  NEW_ISSUE_FORM = "New Issue Form",
}

export enum UIEvent {
  CANCEL_BUTTON_CLICKED = "Cancel clicked",
  COPY_BUTTON_CLICKED = "Copy clicked",
  CREATE_BUTTON_CLICKED = "Create clicked",
  DELETE_BUTTON_CLICKED = "Delete clicked",
  EDIT_BUTTON_CLICKED = "Edit clicked",
  PREFILL_BUTTON_CLICKED = "Prefill clicked",
  ERROR_STATE = "Error State Appeared",
  GET_STARTED_VISITED = "Get Started Visited",
  INVALID_CONTEXT_PARAMS_STATE = "Invalid Context Params Faced",
  LINK_CLICKED = "Link Clicked",
  LOCKED_STATE = "Locked State Appeared",
  NEW_ISSUE_PAGE_VISITED = "New Issue Page Opened",
  PAGE_NOT_FOUND = "Page Not Found",
  TEMPLATES_EMPTY_STATE = "Templates Empty State Shown",
  TEMPLATE_EDIT_PAGE_VISITED = "Template Edit Page Visited",
  ISSUE_EDIT_PAGE_VISITED = "Issue Edit Page Visited",
  VARIABLES_EDIT_PAGE_VISITED = "Variables Edit Page Visited",
}

type Callback = (arg0?: any, arg1?: any) => any

export type LogEvent = (
  eventType: UIEvent,
  eventPropertiesIn?: object,
  callback?: Callback
) => any

export type Instrument = (eventType: UIEvent, fn: Callback, eventPropertiesIn?: object) => any

export type AnalyticsTools = {
  logEvent: LogEvent,
  instrument: Instrument
}

const AnalyticsContext = createContext<AnalyticsTools>(null)

export const useAnalytics = (scope?: UIScope): AnalyticsTools => {
  const { logEvent, instrument } = useContext(AnalyticsContext)

  return {
    logEvent: (eventType, eventPropertiesIn, callback) =>
      logEvent(eventType, { scope, ...eventPropertiesIn }, callback),
    instrument: (eventType, fn, eventPropertiesIn) =>
      instrument(eventType, fn, { scope, ...eventPropertiesIn }),
  }
}

type LogOnMountProps = {
  eventProperties?: object
  eventType: UIEvent
  scope?: UIScope
  instanceName?: string
  children?: React.ReactNode
}

export const LogOnMount = ({ children, eventType, scope, ...props }: LogOnMountProps) => {
  const { logEvent } = useAnalytics(scope)

  useEffect(() => {
    logEvent(eventType, props.eventProperties)
  }, [])

  return <>{children}</>
}

export default ({ children, ...props }: {
  children: React.ReactNode,
  logEvent?: LogEvent,
  instrument?: Instrument,
  userId?: string,
  host?: string
}): JSX.Element => {
  const logEvent = props.logEvent || console.debug

  const instrument = (eventType, fn, eventPropertiesIn?) => (
    (params) => {
      logEvent(eventType, eventPropertiesIn)
      return fn(params)
    }
  )

  return (
    <AnalyticsContext.Provider value={{ logEvent, instrument: props.instrument || instrument }}>
      {children}
    </AnalyticsContext.Provider>
  )
}
