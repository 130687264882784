import { Jira } from "@easy-templates/types";

export const getComments = async (
  requestJira: Jira.AdapterInterface["request"],
  issueIdOrKey: string
) => {
  const response = await requestJira<{ comments: Jira.Comment[] }>(
    `/rest/api/3/issue/${issueIdOrKey}/comment`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response?.comments;
};

export default getComments;
