import { TemplateVariable, VariableType } from "@easy-templates/types"

import useStyles from "./useStyles"
import Item from "./Item"
import { useVariableOptions } from "components/Variables"

type Props = {
  onSelectItem: (id: string) => void
  onDeleteItem: (id: string) => void
  variables: TemplateVariable[]
}

const List = ({ variables, onSelectItem, onDeleteItem }: Props) => {
  const classes = useStyles()
  const { getVariableTypeIcon } = useVariableOptions()

  return (
    <div className={classes.root} data-testid="variables-list">
      {variables.map(({ id, label, description, required, type }) => {
        return <Item
          key={id}
          id={id}
          label={label}
          description={description}
          isRequired={required}
          icon={getVariableTypeIcon(type)}
          onEdit={onSelectItem}
          onDelete={onDeleteItem}
        />
      })}
    </div>
  )
}

export default List
