import { FieldInterface } from "./types";

export default class NullField implements FieldInterface {
  name = "invalid-field";

  value() {
    return null;
  }

  prefillValue() {
    return null;
  }

  isSubmitable() {
    return false;
  }

  isCascading() {
    return false;
  }

  isDefined() {
    return false;
  }
}
