/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JiraOperation } from './JiraOperation';
import {
    JiraOperationFromJSON,
    JiraOperationFromJSONTyped,
    JiraOperationToJSON,
} from './JiraOperation';

/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    result?: string;
    /**
     * 
     * @type {{ [key: string]: JiraOperation; }}
     * @memberof Job
     */
    results?: { [key: string]: JiraOperation; };
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    status?: JobStatusEnum;
}


/**
 * @export
 */
export const JobStatusEnum = {
    Success: 'success',
    Error: 'error',
    InProgress: 'in_progress'
} as const;
export type JobStatusEnum = typeof JobStatusEnum[keyof typeof JobStatusEnum];


/**
 * Check if a given object implements the Job interface.
 */
export function instanceOfJob(value: object): value is Job {
    return true;
}

export function JobFromJSON(json: any): Job {
    return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'result': json['result'] == null ? undefined : json['result'],
        'results': json['results'] == null ? undefined : (mapValues(json['results'], JiraOperationFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function JobToJSON(value?: Job | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'result': value['result'],
        'results': value['results'] == null ? undefined : (mapValues(value['results'], JiraOperationToJSON)),
        'status': value['status'],
    };
}

