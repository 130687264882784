import BaseAction from './base'
import { State } from '../types'

export default class RandomlyFailedAction extends BaseAction {
  message: string

  constructor(message: string) {
    super()
    this.message = message
  }

  apply(state: State): State {
    return { 
      ...state, 
      errorMessage: this.message, 
      isLoading: false, 
      isPrefilling: false,
      jobId: null,
    }
  }
}
