import Select, {
  AsyncSelect,
  OptionsType,
  CheckboxSelect,
  RadioSelect,
} from "components/ui/Select"

import { useAppContext } from "components/AppContextProvider"

import OptionLabel, { getOptionLabel, getOptionValue } from "./Option"

import styles from "./styles"

type Options = unknown[] | { [key: string]: unknown }

const getComponent = ({ options, type }) => {
  if (type === "radiobuttons") return RadioSelect
  if (type === "checkboxes") return CheckboxSelect
  if (!options) return AsyncSelect

  return Select
}

// @ts-ignore
const SelectWithIcon: typeof Select = ({
  autoCompleteUrl,
  options,
  type,
  ...props
}) => {
  const { core } = useAppContext()
  const SelectComponent = getComponent({ options, type })

  const prepareOptions = (options: Options) => {
    if (Array.isArray(options)) return options

    if (typeof options === "object") {
      return Object.entries(options)
        .map(([, sections]) => {
          if (Array.isArray(sections)) {
            return sections
              .map((item): unknown => {
                const entry = Object.entries(item).find(([, value]) => {
                  return Array.isArray(value)
                }) || [null, []]

                return entry[1]
              })
              .flat()
          } else {
            return (
              // @ts-ignore
              Object.entries(sections).find(([, value]) =>
                Array.isArray(value)
              ) || []
            )
          }
        })
        .flat()
    }

    return []
  }

  const getFallbackOptions = (value: unknown) => {
    if (!value) {
      return []
    }

    if (Array.isArray(value)) {
      return value
    }

    return [value]
  }

  const optionsGetter = () => {
    if (props.name.toLocaleLowerCase() === "reporter") {
      return async (query: string) => {
        const result = await core.request<{
          "header": string,
          "total": number,
          "users":
          {
            "accountId": string,
            "accountType": string,
            "avatarUrl": string,
            "displayName": string,
            "html": string,
            "key": string,
            "name": string
          }[]
        }
        >(`/rest/api/3/user/picker?showAvatar=true&query=${query}`)

        return result.users
      }
    }

    if (autoCompleteUrl) {
      return async (query: string) => {
        try {
          const result = await core.request<unknown[]>(`${autoCompleteUrl}${query}`)
          return result
        } catch (error) {
          return getFieldSuggestions(query)
        }
      }
    }

    return getFieldSuggestions
  }

  const loadOptions = async (
    inputValue: string,
  ) => {
    try {
      const options = await optionsGetter()(inputValue)

      return prepareOptions(options)
    } catch (error) {
      return getFallbackOptions(props.value)
    }
  }

  const getFieldSuggestions = async (query: string) => {
    const url = query ?
      `/rest/api/2/jql/autocompletedata/suggestions?&fieldName=${props.name}&fieldValue=${query}` :
      `/rest/api/2/jql/autocompletedata/suggestions?predicateName=${props.name}&fieldName=${props.name}&predicateValue=${query}`

    const result = await core.request<{ results: { displayName: string, value: string }[] }>(url)

    return result.results
  }

  return (
    <div style={styles} data-testid={props.testId || "select"}>
      <SelectComponent
        // @ts-ignore
        formatOptionLabel={OptionLabel}
        // @ts-ignore
        getOptionLabel={getOptionLabel}
        // @ts-ignore
        getOptionValue={getOptionValue}
        // @ts-ignore
        options={options || undefined}
        loadOptions={props.loadOptions || loadOptions}
        defaultOptions={true}
        cacheOptions={true}
        {...props}
      />
    </div>
  )
}

export default SelectWithIcon
