import React from "react";
import Link from "components/Link";
import QuestionCircleIcon from "components/ui/icons/QuestionCircleIcon";

import styles from "./styles";

const HelpLinks: React.FC = () => (
  <div style={styles.helpLinks}>
    <Link href="http://go.appliger.com/manage1e4b4">
      <QuestionCircleIcon label="documentation" primaryColor="#8777D9" />
    </Link>
    &nbsp;
    <Link href="http://go.appliger.com/manage1e4b4">Documentation</Link>
  </div>
);

export default HelpLinks;
