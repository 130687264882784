import { Jira } from "@easy-templates/types";

interface GetCreateMeta {
  projectIds: string[];
}

export const getCreateMeta = async (
  requestJira: Jira.AdapterInterface["request"],
  { projectIds }: GetCreateMeta
): Promise<Jira.IssueCreateMetadata> => {
  const response = await requestJira<Jira.IssueCreateMetadata>(
    `/rest/api/3/issue/createmeta?projectIds=${projectIds.join(
      ","
    )}&expand=projects.issuetypes.fields`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response;
};
