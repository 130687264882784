/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * Identifier of the issue
     * @type {string}
     * @memberof Issue
     */
    id: string;
    /**
     * Unspecified
     * @type {string}
     * @memberof Issue
     */
    jiraKey: string | null;
    /**
     * Identifier of the parent issue
     * @type {string}
     * @memberof Issue
     */
    parentId: string;
    /**
     * List of the disabled field names
     * @type {Array<string>}
     * @memberof Issue
     */
    disabledFields: Array<string>;
    /**
     * A map of field names and values
     * @type {object}
     * @memberof Issue
     */
    fields: object;
}

/**
 * Check if a given object implements the Issue interface.
 */
export function instanceOfIssue(value: object): value is Issue {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('jiraKey' in value) || value['jiraKey'] === undefined) return false;
    if (!('parentId' in value) || value['parentId'] === undefined) return false;
    if (!('disabledFields' in value) || value['disabledFields'] === undefined) return false;
    if (!('fields' in value) || value['fields'] === undefined) return false;
    return true;
}

export function IssueFromJSON(json: any): Issue {
    return IssueFromJSONTyped(json, false);
}

export function IssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Issue {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jiraKey': json['jiraKey'],
        'parentId': json['parentId'],
        'disabledFields': json['disabledFields'],
        'fields': json['fields'],
    };
}

export function IssueToJSON(value?: Issue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'jiraKey': value['jiraKey'],
        'parentId': value['parentId'],
        'disabledFields': value['disabledFields'],
        'fields': value['fields'],
    };
}

