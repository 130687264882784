import { Jira } from "@easy-templates/types"
import GenericSerch, { Searcher, Getter } from "./generic-search"

type Params = {
  typeKey?: string
  id?: number[] | string[]
}

type Value = Jira.Project

type Result = {
  total: number
  values: Value[]
}

const searchPage: Searcher<Result> = async (
  requestJira: Jira.AdapterInterface["request"],
  offset: number,
  maxResults: number
) => {
  const routeUrl = `/rest/api/3/project/search?expand=issueTypes&startAt=${offset}&maxResults=${maxResults}`

  const response = await requestJira<Result>(routeUrl, {
    headers: {
      Accept: "application/json",
    },
  })

  return response
}

const getter: Getter<Result, Value> = ({ values, total }) => ({
  values,
  total,
})


export const getProjects = async (
  requestJira: Jira.AdapterInterface["request"],
  params: Params
) => {
  const search = new GenericSerch<{ values: Value[]; total: number }, Value>(
    requestJira,
    50
  )

  search.setSearcher(searchPage)
  search.setGetter(getter)

  const projects = await search.all()

  if (params?.id) {
    const projectIds = params.id.map((id: string | number) => String(id))

    return projects.filter((project) => {
      return projectIds.includes(project.id)
    })
  }

  return projects
}

export const getProject = async (
  requestJira: Jira.AdapterInterface["request"],
  idOrKey: string
): Promise<Jira.Project> => {
  const routeUrl = `/rest/api/3/project/${idOrKey}`

  const response = await requestJira<Jira.Project>(routeUrl, {
    headers: {
      Accept: "application/json",
    },
  })

  return response
}
