import BasicField from './basic'

export default class ReporterField extends BasicField {
  prefillValue() {
    if (this.body?.accountId) {
      return this.body.accountId
    }

    if (this.body?.id) {
      return this.body.id
    }

    if (this.body?.value) {
      return this.body.value
    }

    return this.body
  }

  value() {
    return this.body
  }
}
