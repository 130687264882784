import React from 'react'
import Icon from './Icon'

import useStyles from './useStyles'

type Props = {
  children: React.ReactNode
  iconUrl?: string
  iconComponent?: React.ReactNode
  icon?: React.ReactNode
  label?: string
}

const OptionIcon = ({ iconComponent: IconComponent, label, iconUrl, icon, className }) => {
  if (IconComponent) return <div className={className}><IconComponent label={label} /></div>
  if (icon) return <div className={className}>{icon}</div>
  if (iconUrl) return <Icon src={iconUrl} />

  return null
}

const Container = ({ children, iconUrl, icon, iconComponent, label }: Props): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <OptionIcon iconUrl={iconUrl} icon={icon} iconComponent={iconComponent} className={classes.icon} label={label} />
      {children}
    </div>
  )
}

export default Container
