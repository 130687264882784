import { IssueField } from "@easy-templates/types"

import Field from "./Field";

type Props = {
  issueId: string;
  templateId: string;
  fields: IssueField[];
};

const fieldsOrder = [
  "Summary",
  "Priority",
  "Security",
  "Components",
  "Assignee",
  "Reporter",
  "Description",
].reverse();

const fieldsSorter = (a: IssueField, b: IssueField): number =>
  fieldsOrder.indexOf(b.name) - fieldsOrder.indexOf(a.name);

const hiddenFields = new Set(["Attachment", "Issue Type", "Rank"]);
const keepUnhidden = (field: IssueField): boolean =>
  !hiddenFields.has(field.name);

const overriddenFields = ({ meta }: IssueField): boolean => {
  if (!meta) {
    return false;
  }

  if (!meta) {
    return false;
  }

  return !["Project"].includes(meta.name);
};

const FieldsList = ({ fields, issueId, templateId }: Props): JSX.Element => {
  const fieldsMapper = (field: IssueField) => {
    const meta = field?.meta;

    if (!meta) {
      return null;
    }

    const value = field.body || "";

    return (
      <Field
        key={field.id}
        templateId={templateId}
        issueId={issueId}
        id={field.id}
        isDisabled={field.isDisabled}
        meta={meta}
        name={field.name}
        value={value}
        isVirtual={field.isVirtual}
      />
    );
  };

  return (
    <div>
      {[...fields]
        .sort(fieldsSorter)
        .filter(keepUnhidden)
        .filter(overriddenFields)
        .map(fieldsMapper)}
    </div>
  );
};

export default FieldsList;
