import useStyles from './useStyles'

type Props = {
  title: string
  iconUrl?: string
}

const Item = ({
  title,
  iconUrl,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="navigation-item">
      <div className={classes.issueContainer}>
        <div className={classes.issueNameContainer}>
          {iconUrl && <img alt={title} src={iconUrl} className={classes.issueIcon} />}
          <div className={classes.issueName}>
            <span title={title}>{title}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Item
