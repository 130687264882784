export const queryFilterFor = <T>(indexer: (item: T) => string) => {
  const matchesWord = (item: T, query: string) => {
    const sanitizedQuery = query.replace(/[\\\.\+\*\?\^\$\[\]\(\)\{\}\/\'\#\:\!\=\|]/ig, "\\$&")
    return indexer(item).match(new RegExp(sanitizedQuery, "gi"))
  }

  const reducer = (list: T[], query: string) => {
    return list.filter((item) => {
      return matchesWord(item, query)
    }, this)
  }

  return {
    filter: (collection: T[], query: string) => {
      if (!query) return collection

      const queryChunks = query.split(/\s+/g).filter(Boolean)

      return queryChunks.reduce(reducer, [...collection])
    },

    match: (item: T, query: string) => {
      if (!query) return true

      const queryChunks = query.split(/\s+/g).filter(Boolean)

      return queryChunks.reduce(reducer, [item]).length > 0
    },
  }
}
