// Editor Quick Insert ("/" menu) Items provider
import { QuickInsertProvider } from '@atlaskit/editor-core';
import Icon from './Icon'

const items = ({ onAdd }) => Promise.resolve([
  {
    title: 'Variable',
    description: 'Insert a variable',
    icon: () => <Icon label="Insert Variable" />,
    // It shouldn't be a Promise, but it works so...
    async action() {
      await onAdd();
      // Don't render anything, it will be inserted into the document later
      return false
    },
  }
])

export default function quickInsertProviderFactory({ onAdd }): QuickInsertProvider {
  return {
    // @ts-ignore
    getItems() {
      return items({ onAdd })
    },
  };
}

