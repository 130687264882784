/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnalyticsSendRequest,
} from '../models/index';
import {
    AnalyticsSendRequestFromJSON,
    AnalyticsSendRequestToJSON,
} from '../models/index';

export interface AnalyticsSendOperationRequest {
    analyticsSendRequest?: AnalyticsSendRequest;
}

/**
 * AnalyticsApi - interface
 * 
 * @export
 * @interface AnalyticsApiInterface
 */
export interface AnalyticsApiInterface {
    /**
     * 
     * @summary Send analytics data
     * @param {AnalyticsSendRequest} [analyticsSendRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    analyticsSendRaw(requestParameters: AnalyticsSendOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Send analytics data
     */
    analyticsSend(analyticsSendRequest?: AnalyticsSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI implements AnalyticsApiInterface {

    /**
     * Send analytics data
     */
    async analyticsSendRaw(requestParameters: AnalyticsSendOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/telemetry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyticsSendRequestToJSON(requestParameters['analyticsSendRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send analytics data
     */
    async analyticsSend(analyticsSendRequest?: AnalyticsSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsSendRaw({ analyticsSendRequest: analyticsSendRequest }, initOverrides);
    }

}
