import { useMutation, useQueryClient } from "@tanstack/react-query"
import { TemplateVariable } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from 'lib/queryKeys'

type Params = {
  templateId: string
}

type Values = {
  type: string
  default: unknown
  label: string
  description: string
  required: boolean
}

export default ({ templateId }: Params) => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutateAsync: createVariable, isLoading: isCreating, error } = useMutation({
    mutationFn: async (params: Values) => {
      const result = await core.createTemplateVariable(templateId, params)

      if (result.isOk) {
        return result.data
      }

      throw result.error
    },
    onMutate: async (params: Values) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const variablesQueryKey = templateKeys.variables(templateId)

      await queryClient.cancelQueries({ queryKey: variablesQueryKey })

      // Snapshot the previous value
      const oldVariables = queryClient.getQueryData(variablesQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(variablesQueryKey, (oldVariables: TemplateVariable[]) => {
        return [
          ...oldVariables,
          { ...params, id: params.label }
        ]
      })

      // Return a context object with the snapshotted value
      return { oldVariables }
    },
    onSettled: (_data, _error, { }) => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.variables(templateId),
      })

      queryClient.invalidateQueries({
        queryKey: templateKeys.details(templateId),
      })
    },
  })

  return { createVariable, isCreating, error }
}
