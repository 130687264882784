import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left'

import Button from '@atlaskit/button'

const BackButton = ({ onClick }): JSX.Element => {
  return (
    <Button
      onClick={onClick}
      // @ts-ignore
      iconBefore={<ArrowLeftIcon label="Templates" size="small" />}
      appearance="subtle"
    >
      Templates
    </Button>
  )
}

export default BackButton
