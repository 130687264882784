import { Jira, Template } from "@easy-templates/types";
import BaseAction from "./base";
import { State } from "../types";

export default class TemplatesLoadedAction extends BaseAction {
  templates: Template[];
  projects: Jira.Project[];
  contextProjectId?: string;
  errorMessage?: string;

  constructor({
    templates,
    projects,
    contextProjectId,
    errorMessage,
  }: {
    templates: Template[];
    projects: Jira.Project[];
    contextProjectId?: string;
    errorMessage?: string;
  }) {
    super();
    this.templates = templates;
    this.projects = projects;
    this.contextProjectId = contextProjectId;
    this.errorMessage = errorMessage;
  }

  sortTemplates(templates: Template[]): Template[] {
    return [...templates].sort((left: Template, right: Template) =>
      ("" + left.name).localeCompare(right.name)
    );
  }

  apply(state: State): State {
    if (this.templates.length < 1) {
      return {
        ...state,
        isLoading: false,
        noTemplates: true,
      };
    }

    const allTemplates = this.sortTemplates(this.templates);

    const currentProject: Jira.Project =
      (this.contextProjectId &&
        this.projects.find(
          (project) => project.id === this.contextProjectId
        )) ||
      this.projects[0];

    return {
      ...state,
      allProjects: this.projects,
      allTemplates,
      isLoading: false,
      selectedProject: currentProject,
      noTemplates: false,
      projects: this.projects,
    };
  }
}
