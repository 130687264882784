/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VariableResponse } from './VariableResponse';
import {
    VariableResponseFromJSON,
    VariableResponseFromJSONTyped,
    VariableResponseToJSON,
} from './VariableResponse';
import type { Issuetype } from './Issuetype';
import {
    IssuetypeFromJSON,
    IssuetypeFromJSONTyped,
    IssuetypeToJSON,
} from './Issuetype';
import type { IssuesItem } from './IssuesItem';
import {
    IssuesItemFromJSON,
    IssuesItemFromJSONTyped,
    IssuesItemToJSON,
} from './IssuesItem';
import type { IssuesTree } from './IssuesTree';
import {
    IssuesTreeFromJSON,
    IssuesTreeFromJSONTyped,
    IssuesTreeToJSON,
} from './IssuesTree';

/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * Whether child issues and sub-tasks should be created
     * @type {boolean}
     * @memberof Template
     */
    createChildren?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    name?: string;
    /**
     * ID of the main (root) issue in the tempalte
     * @type {string}
     * @memberof Template
     */
    rootIssueId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Template
     */
    insertedAt?: Date;
    /**
     * 
     * @type {{ [key: string]: IssuesItem; }}
     * @memberof Template
     */
    issues?: { [key: string]: IssuesItem; };
    /**
     * 
     * @type {Issuetype}
     * @memberof Template
     */
    issuetype?: Issuetype;
    /**
     * Template scope (0 - global, 1 - projects)
     * @type {number}
     * @memberof Template
     */
    scope?: TemplateScopeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Template
     */
    scopeValue?: Array<string>;
    /**
     * 
     * @type {IssuesTree}
     * @memberof Template
     */
    tree?: IssuesTree;
    /**
     * 
     * @type {Date}
     * @memberof Template
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<VariableResponse>}
     * @memberof Template
     */
    variables?: Array<VariableResponse>;
    /**
     * Account ID of the user who created the template
     * @type {string}
     * @memberof Template
     */
    createdBy?: string | null;
}


/**
 * @export
 */
export const TemplateScopeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type TemplateScopeEnum = typeof TemplateScopeEnum[keyof typeof TemplateScopeEnum];


/**
 * Check if a given object implements the Template interface.
 */
export function instanceOfTemplate(value: object): value is Template {
    return true;
}

export function TemplateFromJSON(json: any): Template {
    return TemplateFromJSONTyped(json, false);
}

export function TemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Template {
    if (json == null) {
        return json;
    }
    return {
        
        'createChildren': json['createChildren'] == null ? undefined : json['createChildren'],
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'rootIssueId': json['rootIssueId'] == null ? undefined : json['rootIssueId'],
        'insertedAt': json['insertedAt'] == null ? undefined : (new Date(json['insertedAt'])),
        'issues': json['issues'] == null ? undefined : (mapValues(json['issues'], IssuesItemFromJSON)),
        'issuetype': json['issuetype'] == null ? undefined : IssuetypeFromJSON(json['issuetype']),
        'scope': json['scope'] == null ? undefined : json['scope'],
        'scopeValue': json['scopeValue'] == null ? undefined : json['scopeValue'],
        'tree': json['tree'] == null ? undefined : IssuesTreeFromJSON(json['tree']),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
        'variables': json['variables'] == null ? undefined : ((json['variables'] as Array<any>).map(VariableResponseFromJSON)),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

export function TemplateToJSON(value?: Template | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createChildren': value['createChildren'],
        'id': value['id'],
        'name': value['name'],
        'rootIssueId': value['rootIssueId'],
        'insertedAt': value['insertedAt'] == null ? undefined : ((value['insertedAt']).toISOString()),
        'issues': value['issues'] == null ? undefined : (mapValues(value['issues'], IssuesItemToJSON)),
        'issuetype': IssuetypeToJSON(value['issuetype']),
        'scope': value['scope'],
        'scopeValue': value['scopeValue'],
        'tree': IssuesTreeToJSON(value['tree']),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
        'variables': value['variables'] == null ? undefined : ((value['variables'] as Array<any>).map(VariableResponseToJSON)),
        'createdBy': value['createdBy'],
    };
}

