import { useQuery } from "@tanstack/react-query"
import { Jira } from "@easy-templates/types"
import { userKeys } from "lib/queryKeys"
import { useAppContext } from "./AppContextProvider"

import Avatar, { AvatarItem } from "./ui/Avatar"

type Props = {
  accountId: string | null
}

const User = ({ accountId }: Props) => {
  const { core } = useAppContext()
  const { data, isLoading, error } = useQuery<Jira.User>({
    enabled: !!accountId,
    queryKey: userKeys.details(accountId),
    queryFn: async () => await core.getUser(accountId),
  })

  if (!accountId || isLoading || error) {
    return null
  }

  return (
    <AvatarItem
      avatar={<Avatar
        name={data.displayName}
        src={data.avatarUrls["24x24"]}
        size="small"
      />}
      primaryText={data.displayName}
    />
  )
}

export default User