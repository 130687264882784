import { useQuery } from "@tanstack/react-query"
import { Template } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

export type DataFetchResult = {
  isLoading: boolean
  error?: Error
  template: Template
}

const useData = (id: string): DataFetchResult => {
  const { core, maintenanceModeOn } = useAppContext()

  const { data, isLoading, error } = useQuery<Template, Error>({
    enabled: !!id && !maintenanceModeOn,
    queryKey: templateKeys.details(id),
    queryFn: async () => await core.getTemplate(id),
  })

  return {
    isLoading,
    error,
    template: data,
  }
}

export default useData
