import { Jira } from "@easy-templates/types"
import { Result } from "@easy-templates/lib"

type LinkType = {
  id: string
  name?: string
}

export const createIssueLink = async (
  requestJira: Jira.AdapterInterface["request"],
  inwardIssueKey: string,
  outwardIssueKey: string,
  type: LinkType
) => {
  const bodyData = JSON.stringify({
    outwardIssue: {
      key: outwardIssueKey,
    },
    inwardIssue: {
      key: inwardIssueKey,
    },
    type,
  })

  const response = await requestJira(`/rest/api/3/issueLink`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: bodyData,
  })

  return new Result(response, true)
}

export default createIssueLink
