import Button from '@atlaskit/button'
import MoreIcon from '@atlaskit/icon/glyph/more'

const Trigger = ({ triggerRef, ...props }): JSX.Element => (
  <span data-testid='templates-list__item__actions'>
    <Button ref={triggerRef} {...props} spacing='compact'>
      {/* @ts-ignore */}
      <MoreIcon label='More' />
    </Button>
  </span>
)

export default Trigger
