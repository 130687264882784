import React from 'react'

import { DropdownItem } from 'components/ui/DropdownMenu'

type Props = {
  children: React.ReactNode
  onClick: () => void
}

export const Action = (props: Props): JSX.Element => <DropdownItem {...props} />

export default Action
