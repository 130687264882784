import { useContext, useState, useRef, useCallback, createContext } from "react"
import AddDialog from "./AddDialog"

import useTemplateVariables from "./useTemplateVariables"

const Context = createContext(null)

export const useVariablesContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useVariablesContext must be used within a VariablesProvider")
  }

  return context
}

export const Wrapper = ({ templateId, children }) => {
  const cbRef = useRef(null)
  const [isVariablesDialogOpen, setIsVariablesDialogOpen] = useState(false)
  const closeVariablesDialog = useCallback(() => setIsVariablesDialogOpen(false), [])
  const { variables } = useTemplateVariables(templateId)

  const openVariablesDialog = useCallback((cb) => {
    cbRef.current = (value: string) => {
      cb(value)
      closeVariablesDialog()
    }
    setIsVariablesDialogOpen(true)
  }, [])

  const handleVariableAdd = (value: string) => {
    cbRef?.current(value)
  }

  return (
    <Context.Provider value={{ onVariableRequest: openVariablesDialog, variables }}>
      {children}
      <AddDialog isOpen={isVariablesDialogOpen} onClose={closeVariablesDialog} onAdd={handleVariableAdd} variables={variables} />
    </Context.Provider>
  )
}
