import Page from "components/ui/Page";
import EmptyState from "components/ui/EmptyState";
import { LogOnMount, UIEvent } from "components/Analytics";
import logo from "images/logo.png";
import Description from "./Description";
import PoweredBy from "./PoweredBy";

const GetStarted = (): JSX.Element => (
  <Page>
    <EmptyState header="Welcome to Easy Templates for Jira" imageUrl={logo} />
    <Description />
    <PoweredBy />
    <LogOnMount eventType={UIEvent.GET_STARTED_VISITED} />
  </Page>
);

export default GetStarted;
