export default {
  root: {
    alignItems: "center",
    display: "flex",
    lineHeight: "1.2",
    zIndex: 99
  },
  icon: {
    backgroundColor: '#dfdfdf',
    borderRadius: 2,
    textIndent: '-10000px',
    marginRight: "8px",
    height: "16px"
  }
}
