import { createUseStyles } from 'react-jss'

export default createUseStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: '1.2',
    zIndex: '99',
  },
  icon: {
    marginRight: '8px',
  },
})
