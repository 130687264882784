/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateApplyingResult
 */
export interface TemplateApplyingResult {
    /**
     * 
     * @type {string}
     * @memberof TemplateApplyingResult
     */
    issueId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateApplyingResult
     */
    originalJiraId?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateApplyingResult
     */
    result?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateApplyingResult
     */
    status?: TemplateApplyingResultStatusEnum;
}


/**
 * @export
 */
export const TemplateApplyingResultStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;
export type TemplateApplyingResultStatusEnum = typeof TemplateApplyingResultStatusEnum[keyof typeof TemplateApplyingResultStatusEnum];


/**
 * Check if a given object implements the TemplateApplyingResult interface.
 */
export function instanceOfTemplateApplyingResult(value: object): value is TemplateApplyingResult {
    return true;
}

export function TemplateApplyingResultFromJSON(json: any): TemplateApplyingResult {
    return TemplateApplyingResultFromJSONTyped(json, false);
}

export function TemplateApplyingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateApplyingResult {
    if (json == null) {
        return json;
    }
    return {
        
        'issueId': json['issueId'] == null ? undefined : json['issueId'],
        'originalJiraId': json['originalJiraId'] == null ? undefined : json['originalJiraId'],
        'result': json['result'] == null ? undefined : json['result'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function TemplateApplyingResultToJSON(value?: TemplateApplyingResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issueId': value['issueId'],
        'originalJiraId': value['originalJiraId'],
        'result': value['result'],
        'status': value['status'],
    };
}

