import BasicField from './basic'

export default class UserPickerField extends BasicField {
  value() {
    if (this.body?.accountId) {
      return this.body.accountId
    }

    if (this.body?.id) {
      return this.body.id
    }

    if (this.body?.value) {
      return this.body.value
    }

    return this.body
  }
}
