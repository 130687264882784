/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldUpdateBodyRequest
 */
export interface FieldUpdateBodyRequest {
    /**
     * New value of the field
     * @type {any}
     * @memberof FieldUpdateBodyRequest
     */
    value: any | null;
}

/**
 * Check if a given object implements the FieldUpdateBodyRequest interface.
 */
export function instanceOfFieldUpdateBodyRequest(value: object): value is FieldUpdateBodyRequest {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function FieldUpdateBodyRequestFromJSON(json: any): FieldUpdateBodyRequest {
    return FieldUpdateBodyRequestFromJSONTyped(json, false);
}

export function FieldUpdateBodyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldUpdateBodyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function FieldUpdateBodyRequestToJSON(value?: FieldUpdateBodyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

