/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateCopyRequest
 */
export interface TemplateCopyRequest {
    /**
     * Name of the template
     * @type {string}
     * @memberof TemplateCopyRequest
     */
    name: string;
}

/**
 * Check if a given object implements the TemplateCopyRequest interface.
 */
export function instanceOfTemplateCopyRequest(value: object): value is TemplateCopyRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function TemplateCopyRequestFromJSON(json: any): TemplateCopyRequest {
    return TemplateCopyRequestFromJSONTyped(json, false);
}

export function TemplateCopyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateCopyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function TemplateCopyRequestToJSON(value?: TemplateCopyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

