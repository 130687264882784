/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1: parent level task, such as epics
 * 
 * 0: standard level issues, such as stories or tasks
 * 
 * -1: subtasks
 * @export
 */
export const HierarchyLevel = {
    NUMBER_MINUS_1: -1,
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type HierarchyLevel = typeof HierarchyLevel[keyof typeof HierarchyLevel];


export function instanceOfHierarchyLevel(value: any): boolean {
    for (const key in HierarchyLevel) {
        if (Object.prototype.hasOwnProperty.call(HierarchyLevel, key)) {
            if (HierarchyLevel[key as keyof typeof HierarchyLevel] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HierarchyLevelFromJSON(json: any): HierarchyLevel {
    return HierarchyLevelFromJSONTyped(json, false);
}

export function HierarchyLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HierarchyLevel {
    return json as HierarchyLevel;
}

export function HierarchyLevelToJSON(value?: HierarchyLevel | null): any {
    return value as any;
}

