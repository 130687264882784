import Button from "@atlaskit/button"
import MoreIcon from "@atlaskit/icon/glyph/more"

const Trigger = ({ triggerRef, ...props }): JSX.Element => (
  <Button
    {...props}
    ref={triggerRef}
  >
    <MoreIcon label="More" size="small" />
  </Button>
)

export default Trigger
