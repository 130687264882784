import { useNavigate } from 'react-router-dom'

import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from 'components/ui/DropdownMenu'

import Trigger from './Trigger'

type Props = {
  templateId: string
  onCopyClick: () => void
  onDeleteClick: () => void
}

const Actions = ({
  templateId,
  onCopyClick,
  onDeleteClick,
}: Props): JSX.Element => {
  const navigate = useNavigate()

  return (
    <DropdownMenu trigger={Trigger}>
      <DropdownItemGroup>
        <DropdownItem
          testId='templates-list__item__actions-edit'
          onClick={() => { navigate(`/template/edit/${templateId}`) }}
        >
          Edit
        </DropdownItem>
        <DropdownItem
          onClick={onCopyClick}
          testId='templates-list__item__actions-copy'
        >
          Copy
        </DropdownItem>
        <DropdownItem
          onClick={onDeleteClick}
          testId='templates-list__item__actions-delete'
        >
          Delete
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  )
}

export default Actions
