import { LogOnMount, UIEvent } from "components/Analytics";
import EmptyState from "components/ui/EmptyState";

// @ts-ignore
import bug from "./bug.png";
import useStyles from "./useStyles";

type RequestErrorType = {
  status: number;
  responseText: string;
};

type ErrorType = RequestErrorType | Error;

type Props = {
  error?: ErrorType;
  header?: string;
  illustrationUrl?: string;
  userId?: string;
};

export const getMessage = (
  error: ErrorType | undefined
): string | undefined => {
  if (!error) {
    return "Unknown error";
  }

  if (error instanceof Error) {
    return error.message;
  }

  try {
    return JSON.parse(error.responseText).message;
  } catch (_) {
    return "Unknown error";
  }
};

const ErrorState = ({ error, header, illustrationUrl }: Props): JSX.Element => {
  const classes = useStyles();
  let errorData = { message: "Unknown error", details: "" }

  if ("responseText" in error) {
    errorData = {
      message: error.responseText,
      details: error.status.toString()
    }
    error
  } else if ("message" in error) {
    errorData = {
      message: error.message,
      details: error.stack
    }
  }

  return (
    <div className={classes.root} data-testid="error-state">
      <EmptyState
        header={header || ""}
        description={getMessage(error)}
        imageUrl={bug}
      />
      {illustrationUrl && (
        <img alt="Error" src={illustrationUrl} className={classes.icon} />
      )}
      <LogOnMount eventType={UIEvent.ERROR_STATE} eventProperties={errorData} />
    </div>
  );
};

ErrorState.defaultProps = {
  header: "Something went wrong!",
};

export default ErrorState;
