import { LogOnMount, UIEvent } from "components/Analytics";
import { PageHeader } from "components/ui/Page";
import Main from "./Main";

import styles from "./styles";

const NewIssuePage = (): JSX.Element => {
  return (
    <div style={styles.page}>
      <PageHeader>Create Issues from Template</PageHeader>
      <Main />
      <LogOnMount eventType={UIEvent.NEW_ISSUE_PAGE_VISITED} />
    </div>
  );
};

export default NewIssuePage;
