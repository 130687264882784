/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HierarchyLevel } from './HierarchyLevel';
import {
    HierarchyLevelFromJSON,
    HierarchyLevelFromJSONTyped,
    HierarchyLevelToJSON,
} from './HierarchyLevel';

/**
 * 
 * @export
 * @interface IssuesItem
 */
export interface IssuesItem {
    /**
     * 
     * @type {string}
     * @memberof IssuesItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuesItem
     */
    jiraKey?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuesItem
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuesItem
     */
    iconUrl?: string;
    /**
     * Based on the Summary field of the issue
     * @type {string}
     * @memberof IssuesItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuesItem
     */
    rank?: string;
    /**
     * 
     * @type {HierarchyLevel}
     * @memberof IssuesItem
     */
    level?: HierarchyLevel;
}



/**
 * Check if a given object implements the IssuesItem interface.
 */
export function instanceOfIssuesItem(value: object): value is IssuesItem {
    return true;
}

export function IssuesItemFromJSON(json: any): IssuesItem {
    return IssuesItemFromJSONTyped(json, false);
}

export function IssuesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuesItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'jiraKey': json['jiraKey'] == null ? undefined : json['jiraKey'],
        'parentId': json['parentId'] == null ? undefined : json['parentId'],
        'iconUrl': json['iconUrl'] == null ? undefined : json['iconUrl'],
        'name': json['name'] == null ? undefined : json['name'],
        'rank': json['rank'] == null ? undefined : json['rank'],
        'level': json['level'] == null ? undefined : HierarchyLevelFromJSON(json['level']),
    };
}

export function IssuesItemToJSON(value?: IssuesItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'jiraKey': value['jiraKey'],
        'parentId': value['parentId'],
        'iconUrl': value['iconUrl'],
        'name': value['name'],
        'rank': value['rank'],
        'level': HierarchyLevelToJSON(value['level']),
    };
}

