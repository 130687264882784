import React, { useCallback } from "react"
import groupBy from "lodash/groupBy"

import { VariablesExtractor } from "@easy-templates/lib"
import { TemplateVariable } from "@easy-templates/types"

import TextArea from "components/ui/Textarea"
import Textfield, { TextFieldProps } from "components/ui/Textfield"
import Checkbox from "components/ui/Checkbox"
import { DatePicker, DateTimePicker } from "components/ui/DatetimePicker"
import Toggle from "components/ui/Toggle"
import InlineEdit from "components/ui/InlineEdit"
import { useAppContext } from "components/AppContextProvider"

// import Debug from 'components/Debug'

import UnsupportedField from "./UnsupportedField"
import SelectWithIcon from "./Select"
import Labeled from "./Labeled"
import Issue from "./Issue"
import JiraMarkupRenderer from "./JiraMarkupRenderer"
import { RichTextEditor } from "./RichEditor"
import { TextEditor, TextAreaEditor } from "./RichEditor/fallback"

import getComponentFromSchema, {
  Schema,
  ComponentType,
} from "./getComponentTypeFromSchema"

type ValueObject = {
  id?: string
  label?: string
  name?: string
  key?: string
  summary?: string
  value?: string | number | undefined
  child?: ValueObject
}

type Value = ValueObject | string | number | boolean | undefined

type Props = {
  onAddVariable?: () => void
  onEditVariable?: () => void
  autoCompleteUrl?: string
  allowedValues?: Value[]
  defaultValue?: Value
  label?: string
  name: string
  required?: boolean
  schema: Schema
  skipDisabling?: boolean
  disabled?: boolean
  value?: Value
  onConfirm?: (value: Value) => void
  onVariableRequest?: (onAddVariable: (label: string) => void) => void
  variables?: TemplateVariable[]
  validateVariables?: boolean
  testId?: string
}

const variablesExtractor = new VariablesExtractor()

const FormField = ({
  schema,
  label,
  required,
  allowedValues,
  defaultValue,
  autoCompleteUrl,
  disabled,
  name,
  value,
  onConfirm,
  onVariableRequest,
  variables,
  validateVariables,
  testId
}: Props) => {
  const { featureFlags } = useAppContext()
  const componentType: ComponentType = getComponentFromSchema(schema)
  const isDisabledField = disabled

  const handleConfirm = (value: Value) => {
    onConfirm && onConfirm(value)
  }

  const textFieldValidator = useCallback((isRequired: boolean) => (value: string) => {
    if (isRequired && !value) {
      return "This field is required"
    }

    if (validateVariables) {
      const variableLabels = (variables || []).map((variable) => variable.label.toLowerCase())

      for (const fieldVariableLabel of value.matchAll(variablesExtractor.variableRegex())) {
        const variableLabel = fieldVariableLabel[1].trim().toLowerCase()
        if (!variableLabels.includes(variableLabel)) {
          return `Variable "${variableLabel}" is not defined. Please add it first in "Form & Variables" section.`
        }
      }
    }

    return undefined
  }, [variables])

  const textFieldEditView = (
    fieldProps: TextFieldProps & { errorMessage?: string | undefined },
    ref: React.RefObject<HTMLInputElement>
  ) => {
    return (
      <TextEditor
        {...fieldProps}
        allowInsertVariable={variables?.length > 0}
        isDisabled={isDisabledField}
        onVariableRequest={onVariableRequest}
        inputRef={ref}
        testId={testId ? testId + '-edit' : undefined}
      />
    )
  }

  const numberFieldEditView = (fieldProps: TextFieldProps) => (
    <Textfield
      {...fieldProps}
      autoFocus={true}
      isDisabled={isDisabledField}
      type="number"
      testId={testId ? testId + '-edit' : undefined}
    />
  )

  const textAreaEditView = (
    fieldProps: any,
    ref: React.RefObject<HTMLTextAreaElement>
  ) => <TextAreaEditor
      {...fieldProps}
      allowInsertVariable={variables?.length > 0}
      isDisabled={isDisabledField}
      onVariableRequest={onVariableRequest}
      inputRef={ref}
      testId={testId ? testId + '-edit' : undefined}
    />

  const textAreaReadView = () => {
    return (
      // @ts-ignore: No idea what it complains about
      <TextArea
        value={value ? String(value) : ""}
        onClick={(e) => e.preventDefault()}
      />
    )
  }

  const descriptionReadView = () => {
    return (
      <JiraMarkupRenderer jiraMarkup={value || ""} />
    )
  }

  const richTextEditView = (
    fieldProps: TextFieldProps,
  ) => <RichTextEditor
      {...fieldProps}
      autoFocus={true}
      allowInsertVariable={variables?.length > 0}
      onVariableRequest={onVariableRequest}
    />

  if (componentType === "description") {
    const editView = featureFlags.richTextEditor ? richTextEditView : textAreaEditView

    return (
      <InlineEdit
        defaultValue={value ? String(value) : ""}
        isRequired={required}
        label={label || name}
        readViewFitContainerWidth={true}
        editView={editView}
        readView={descriptionReadView}
        onConfirm={handleConfirm}
        keepEditViewOpenOnBlur={true}
        validate={textFieldValidator(required)}
      />
    )
  }

  if (componentType === "parent") {
    const parent = value as { fields: { summary: string } } | undefined

    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <Textfield
          isDisabled
          value={(parent && parent.fields?.summary) || "Unknown"}
        />
      </Labeled>
    )
  }

  if (componentType === "sprint") {
    if (!Array.isArray(value) || value.length < 1) {
      return (
        <Labeled name={name} label={label || name} isRequired={required}>
          (none)
        </Labeled>
      )
    }

    const getValue = (value: ValueObject | Value) => {
      if (!Array.isArray(value)) {
        return value
      }

      return value.map((sprintString: string) => {
        try {
          const match = sprintString.match(/name=([^,]*),/)
          return match[1]
        } catch {
          sprintString
        }
      })
    }

    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <SelectWithIcon
          isMulti={true}
          autoCompleteUrl={autoCompleteUrl}
          value={getValue(value)}
          name={name}
          isRequired={required}
          // @ts-ignore
          onChange={handleConfirm}
          placeholder={label || name}
          isDisabled={true}
          label={label || name}
        />
      </Labeled>
    )
  }

  if (componentType === "textArea") {
    return (
      <InlineEdit
        defaultValue={value ? String(value) : ""}
        isRequired={required}
        label={label || name}
        readViewFitContainerWidth={true}
        editView={textAreaEditView}
        readView={textAreaReadView}
        onConfirm={handleConfirm}
        keepEditViewOpenOnBlur={true}
        validate={textFieldValidator(required)}
      />
    )
  }

  if (componentType === "textField") {
    return (
      <InlineEdit
        defaultValue={value}
        label={label || name}
        isRequired={required}
        readViewFitContainerWidth={true}
        editView={textFieldEditView}
        readView={() => (
          <Textfield
            placeholder="Click to enter value"
            value={String(value)}
            onClick={(e) => e.preventDefault()}
            testId={testId}
          />
        )}
        validate={textFieldValidator(required)}
        onConfirm={handleConfirm}
        keepEditViewOpenOnBlur={true}
      />
    )
  }

  if (componentType === "numberField") {
    return (
      <InlineEdit
        defaultValue={value}
        isRequired={required}
        label={label || name}
        readViewFitContainerWidth={true}
        editView={numberFieldEditView}
        readView={() => (
          <Textfield
            onClick={(e) => e.preventDefault()}
            placeholder="Click to enter value"
            value={String(value)}
            testId={testId}
          />
        )}
        onConfirm={handleConfirm}
        keepEditViewOpenOnBlur={true}
      />
    )
  }

  if (componentType === "cascadingSelect") {
    const fieldValue = value as ValueObject

    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <div style={{ display: "flex" }}>
          <SelectWithIcon
            label={label || name}
            isMulti={false}
            autoCompleteUrl={autoCompleteUrl}
            value={fieldValue}
            name={name}
            isRequired={required}
            defaultOptions={true}
            onChange={handleConfirm}
            options={allowedValues}
            placeholder={label || name}
            isDisabled={true}
          />
          {fieldValue.child && (
            <div style={{ marginLeft: "8px" }}>
              <SelectWithIcon
                label={label || name}
                isMulti={false}
                value={fieldValue.child}
                placeholder={label || name}
                isDisabled={true}
              />
            </div>
          )}
          {fieldValue.child?.child && (
            <div style={{ marginLeft: "8px" }}>
              <SelectWithIcon
                label={label || name}
                isMulti={false}
                value={fieldValue.child?.child}
                placeholder={label || name}
                isDisabled={true}
              />
            </div>
          )}
        </div>
      </Labeled>
    )
  }

  if (componentType === "singleSelect") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <SelectWithIcon
          label={label || name}
          isMulti={false}
          autoCompleteUrl={autoCompleteUrl}
          value={value}
          name={name}
          isRequired={required}
          defaultOptions={true}
          onChange={handleConfirm}
          options={allowedValues}
          placeholder={label || name}
          isDisabled={isDisabledField}
          testId={testId}
        />
      </Labeled>
    )
  }

  if (componentType === "multiSelect") {
    const getValue = (value: ValueObject | Value) => {
      if (Array.isArray(value)) {
        return value
      }

      return defaultValue
    }

    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <SelectWithIcon
          isMulti={true}
          autoCompleteUrl={autoCompleteUrl}
          value={getValue(value)}
          name={name}
          isRequired={required}
          defaultOptions={true}
          // @ts-ignore
          onChange={handleConfirm}
          options={allowedValues}
          placeholder={label || name}
          isDisabled={isDisabledField}
          label={label || name}
        />
      </Labeled>
    )
  }

  if (componentType === "radiobuttons") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <SelectWithIcon
          autoCompleteUrl={autoCompleteUrl}
          value={value}
          name={name}
          isRequired={required}
          defaultOptions={true}
          onChange={handleConfirm}
          options={allowedValues}
          placeholder={label || name}
          isDisabled={isDisabledField}
          type="radiobuttons"
        />
      </Labeled>
    )
  }

  if (componentType === "checkboxes") {
    const getValue = (value: Value) => {
      if (Array.isArray(value)) {
        return value
      }

      return defaultValue
    }

    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <SelectWithIcon
          isMulti={true}
          autoCompleteUrl={autoCompleteUrl}
          value={getValue(value)}
          name={name}
          isRequired={required}
          defaultOptions={true}
          // @ts-ignore
          onChange={handleConfirm}
          options={allowedValues}
          placeholder={label || name}
          isDisabled={isDisabledField}
          type="checkboxes"
          label={label || name}
        />
      </Labeled>
    )
  }

  if (componentType === "dateSelect") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <DatePicker
          //@ts-ignore
          value={value || defaultValue}
          onChange={handleConfirm}
          name={name}
          isDisabled={isDisabledField}
        />
      </Labeled>
    )
  }

  if (componentType === "dateTimeSelect") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <DateTimePicker
          //@ts-ignore
          value={value || defaultValue}
          onChange={handleConfirm}
          name={name}
          isDisabled={isDisabledField}
        />
      </Labeled>
    )
  }

  if (componentType === "checkbox") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <Checkbox
          name={name}
          label={label || name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleConfirm(event.target.checked)
          }
          isChecked={Boolean(value)}
          isDisabled={isDisabledField}
        />
      </Labeled>
    )
  }

  if (componentType === "toggle") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <Toggle
          name={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleConfirm(event.target.checked)
          }
          defaultChecked={Boolean(value)}
          isDisabled={isDisabledField}
          testId={testId}
        />
      </Labeled>
    )
  }

  if (componentType === "epicLink") {
    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        <div>{value ? String(value) : "(none)"}</div>
      </Labeled>
    )
  }

  if (componentType === "issueLinks") {
    if (!Array.isArray(value) || value.length < 1) {
      return (
        <Labeled name={name} label={label || name} isRequired={required}>
          (none)
        </Labeled>
      )
    }

    const linkType = (link: {
      inwardIssue?: unknown
      outwardIssue?: unknown
      type: {
        inward: string
        outward: string
      }
    }) =>
      Object.keys(link).includes("inwardIssue")
        ? link.type.inward
        : link.type.outward

    const links = groupBy(value, linkType)

    return (
      <Labeled name={name} label={label || name} isRequired={required}>
        {Object.keys(links).map((type) => (
          <div key={type} style={{ padding: 8 }}>
            <div>{type}</div>
            <div>
              {links[type].map(
                (link: {
                  id: string
                  outwardIssue?: {
                    key: string
                    fields: { summary: string; issuetype: { iconUrl: string } }
                  }
                  inwardIssue?: {
                    key: string
                    fields: { summary: string; issuetype: { iconUrl: string } }
                  }
                }) => {
                  const issue = link.inwardIssue || link.outwardIssue

                  return (
                    <Issue
                      key={link.id}
                      title={issue.fields.summary}
                      iconUrl={issue.fields.issuetype.iconUrl}
                    />
                  )
                }
              )}
            </div>
          </div>
        ))}
      </Labeled>
    )
  }

  if (componentType === "hidden") return null

  return (
    <Labeled name={name} label={label || name} isRequired={required}>
      <UnsupportedField
        label={label || name}
        value={value}
        type={schema.system || schema.custom || "unknown"}
        schema={schema}
      />
    </Labeled>
  )
}

export default FormField
