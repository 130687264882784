import EmptyState from "components/ui/EmptyState"
import Button from "components/ui/Button"
import illustration from './maintenance.png'

type Props = {
  onDismiss?: () => void
}

export default ({ onDismiss }: Props) => {
  return (<EmptyState
    header="Enhancements Underway"
    description="We are currently enhancing our application to improve your experience. Thank you for your understanding and patience."
    imageUrl={illustration}
    secondaryAction={onDismiss && <Button onClick={onDismiss}>Dismiss</Button>}
    testId="maintenance"
  />)
}