import { Jira } from "@easy-templates/types";

import { getUser } from "./user";

import {
  Search,
  addComment as addIssueComment,
  bulkCreateIssues,
  createIssueLink,
  getComments as getIssueComments,
  getCreateMeta,
  getEditMeta,
  getIssue,
  getIssuePickerSuggestions,
  updateIssue,
} from "./issue";

import { getProjects, getProject } from "./project";

import { getPermittedProjects } from "./permissions";

import { getServerInfo } from "./server-info";

import {
  getIssue as agileGetIssue,
  rankIssuesAfter,
  rankIssuesBefore,
} from "./agile/issue";

export class JiraAPI implements Jira.APIInterface {
  agile: Jira.APIInterface["agile"];
  constructor(public requestJira: Jira.AdapterInterface["request"]) {
    this.agile = {
      getIssue: (idOrKey: string) => agileGetIssue(this.requestJira, idOrKey),
      rankIssuesAfter: (keys: string[], afterKey: string) =>
        rankIssuesAfter(this.requestJira, keys, afterKey),

      rankIssuesBefore: (keys: string[], beforeKey: string) =>
        rankIssuesBefore(this.requestJira, keys, beforeKey),
    };
  }

  async addIssueComment(idOrKey: string, comment: unknown) {
    return addIssueComment(this.requestJira, idOrKey, comment);
  }

  async bulkCreateIssues(issueUpdates: Jira.IssueUpdateDetails[]) {
    return bulkCreateIssues(this.requestJira, issueUpdates);
  }

  async createIssueLink(
    inwardIssueKey: string,
    outwardIssueKey: string,
    typeId: string
  ) {
    return createIssueLink(this.requestJira, inwardIssueKey, outwardIssueKey, {
      id: typeId,
    });
  }

  async getCreateMeta(params: { projectIds: string[] }) {
    return getCreateMeta(this.requestJira, params);
  }

  async getEditMeta(params: { projectIds: string[] }) {
    return getEditMeta(this.requestJira, params);
  }

  async getUser(accountId: string) {
    return getUser(this.requestJira, accountId);
  }

  async getIssue(idOrKey: string) {
    return getIssue(this.requestJira, idOrKey);
  }

  async getProject(idOrKey: string) {
    return getProject(this.requestJira, idOrKey);
  }

  async getProjects(params: { typeKey?: string; id?: string[] }) {
    return getProjects(this.requestJira, params);
  }

  async getPermittedProjects() {
    return getPermittedProjects(this.requestJira);
  }

  async getIssueComments(idOrKey: string) {
    return getIssueComments(this.requestJira, idOrKey);
  }

  async getIssuePickerSuggestions(
    query: string,
    params: { currentIssueKey?: string; currentJQL?: string }
  ) {
    return getIssuePickerSuggestions(this.requestJira, query, params);
  }

  async search(jql: string) {
    return new Search(this.requestJira, jql).all();
  }

  async getServerInfo() {
    return getServerInfo(this.requestJira);
  }

  async updateIssue(id: string, issueUpdates: Jira.IssueUpdateDetails) {
    return updateIssue(this.requestJira, id, issueUpdates);
  }
}

export default JiraAPI;
