import { useReducer, useRef, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"

import { Template } from "@easy-templates/types"

import {
  useAppContext,
} from "components/AppContextProvider"

import { queryFilterFor } from "lib/query-filter"
import { jiraKeys } from "lib/queryKeys"

import Loading from "components/Loading"
import HelpLinks from "components/Templates/HelpLinks"
import TemplatesEmptyState from "components/Templates/Empty"
import { NavLink } from "react-router-dom"
import Button, { ButtonGroup } from "components/ui/Button"
import TextField from "components/ui/Textfield"
import { NewTemplateFromIssueDialog } from "components/NewTemplateFromIssue"
import Maintenance from "components/maintenance"

import PageWrapper from "../../PageWrapper"

import TemplatesList from "./List"
import CopyDialog, { CopyDialogRef } from "./CopyDialog"

import useTemplateDeletion from "./useTemplateDeletion"
import useData from "./useData"

const testIdFor = (elementTestId: String) =>
  `manage-templates__${elementTestId}`

type State = {
  query?: string
  isCreateDialogOpen: boolean
}

class Action<T> {
  constructor(public payload?: T) {
    this.payload = payload
  }

  apply(state: State): State {
    return state
  }
}

class CreateTemplateClicked extends Action<void> {
  apply(state: State): State {
    return { ...state, isCreateDialogOpen: true }
  }
}

class CreateDialogClosed extends Action<void> {
  apply(state: State): State {
    return { ...state, isCreateDialogOpen: false }
  }
}

class QueryChangedAction extends Action<void> {
  constructor(public query: string) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      query: this.query,
    }
  }
}

const reducer = (state: State, action: Action<unknown>) => {
  return action.apply(state)
}

const initialState: State = {
  isCreateDialogOpen: false,
}

const ManagePage = (): JSX.Element => {
  const { core, project, featureFlags, maintenanceModeOn } = useAppContext()

  const [{ isCreateDialogOpen, query }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const copyDialog = useRef<CopyDialogRef>(null)
  const { deleteTemplate } = useTemplateDeletion()
  const { isLoading: isLoadingTemplates, templates, error } = useData({ projectId: project?.id })

  const { isLoading: isLoadingProjects, data: projects } = useQuery({
    queryKey: jiraKeys.projects.list(),
    queryFn: () => core.getProjects({}),
  })

  const isLoading = isLoadingProjects || isLoadingTemplates

  useEffect(() => {
    setTimeout(core.resizeView, 200)
  }, [core, isLoading, templates])

  if (maintenanceModeOn) {
    return <Maintenance />
  }

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    throw error
  }

  const queryFilter = queryFilterFor<Template>(
    (template) => `${template.name} ${template.issuetype.name}`
  )

  const openCopyDialog = (template: Template) => {
    if (!copyDialog.current) {
      return
    }

    copyDialog.current.open({ name: template.name, id: template.id })
  }

  const handleCopied = ({ name }: { id: string; name: string }) => {
    core.showFlag({
      id: "template-copied-flag",
      type: "success",
      title: name,
      description: "has been successfully copied!",
    })
  }

  const handleAdded = ({ name }: { id: string; name: string }) => {
    handleCreateDialogClose()
    core.showFlag({
      id: "template-created-flag",
      type: "success",
      title: name,
      description: "has been successfully created!",
    })
  }

  const handleCopyClick = openCopyDialog

  const handleDeleteClick = (id: string) => {
    if (confirm("Are you sure?")) {
      deleteTemplate(id)
    }
  }

  const handleAddClick = () => {
    dispatch(new CreateTemplateClicked())
  }

  const handleCreateDialogClose = () => {
    dispatch(new CreateDialogClosed())
  }

  const handleQueryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(new QueryChangedAction(event.target.value))
  }

  const pageActions = (
    <ButtonGroup>
      {templates.length > 0 && (
        <Button
          appearance="primary"
          onClick={handleAddClick}
          testId={testIdFor("create-template-button")}
        >
          Create Template
        </Button>
      )}
    </ButtonGroup>
  )

  return (
    <PageWrapper
      title="Easy Templates for Jira"
      actions={pageActions}
      bottomBar={
        templates.length > 0 && (
          <TextField
            isCompact
            onChange={handleQueryChanged}
            placeholder="Find by name or issue type"
            aria-label="Find by name or issue type"
            width={200}
            testId="query-field"
          />
        )
      }
    >
      {templates.length ? (
        <TemplatesList
          templates={queryFilter.filter(templates, query)}
          projects={projects}
          onCopyClick={handleCopyClick}
          onDeleteClick={handleDeleteClick}
        />
      ) : (
        <TemplatesEmptyState isInProject={!!project} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HelpLinks />
        {featureFlags.troubleShooting && (
          <NavLink to="/troubleshooting/storage">Troubleshooting</NavLink>
        )}
      </div>
      <CopyDialog ref={copyDialog} onCompleted={handleCopied} />

      {isCreateDialogOpen && (
        <NewTemplateFromIssueDialog
          onCompleted={handleAdded}
          onClose={handleCreateDialogClose}
        />
      )}
    </PageWrapper>
  )
}

export default ManagePage
