import { createUseStyles } from 'react-jss'

export default createUseStyles({
  root: {},
  issueContainer: {
    minHeight: 22,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px 4px 4px 6px',
    borderRadius: 4,
  },
  issueNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    flexGrow: 1,
  },
  issueIcon: {
    backgroundColor: '#dfdfdf',
    borderRadius: 2,
    textIndent: '-10000px',
    height: 16,
    width: 16,
    flexGrow: 0,
    marginRight: 4,
    marginTop: 2,
  },
  issueName: {
    flexGrow: 1,
  },
})
