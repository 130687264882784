import BasicField from './basic'

export default class UserPickerField extends BasicField {
  value() {
    if (Array.isArray(this.body)) {
      return this.body.map((item) => (
        item.accountId
      ))
    }

    return this.body
  }
}
