import React from "react";

import DropdownMenu, { DropdownItemGroup } from "components/ui/DropdownMenu";

import Trigger from "./Trigger";

type Props = {
  children: React.ReactNode;
};

const Actions = ({ children }: Props): JSX.Element => {
  return (
    <DropdownMenu trigger={Trigger}>
      <DropdownItemGroup>{children}</DropdownItemGroup>
    </DropdownMenu>
  );
};

export default Actions;
