import { Jira, Job, Template } from "@easy-templates/types"

export enum StepPage {
  SELECT = "select",
  VARIABLES = "variables",
  CREATION = "creation",
}

export enum StepStatus {
  CURRENT = "current",
  VISITED = "visited",
  UNVISITED = "unvisited",
}

type Step = {
  id: string
  label: string
  percentageComplete: number
  status: StepStatus
}

export type State = {
  allProjects: Jira.Project[]
  allTemplates: Template[]
  errorMessage: string | null | undefined
  fullSelectedTemplate: Template | null
  jobId?: string
  isLoading: boolean
  isPrefilling: boolean
  isValid: boolean
  noTemplates: boolean
  issuetypes: Jira.IssueTypeDetails[]
  projects: Jira.Project[]
  results: Job["results"]
  selectedIssueType: Jira.IssueTypeDetails | null | undefined
  selectedProject: Jira.Project | null | undefined
  selectedTemplate: Template | null | undefined
  steps: Step[]
  currentStep: StepPage
  templates: Template[]
  warnings: string[]
}
