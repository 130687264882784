/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Field,
  FieldToggleRequest,
  FieldUpdateBodyRequest,
} from '../models/index';
import {
    FieldFromJSON,
    FieldToJSON,
    FieldToggleRequestFromJSON,
    FieldToggleRequestToJSON,
    FieldUpdateBodyRequestFromJSON,
    FieldUpdateBodyRequestToJSON,
} from '../models/index';

export interface FieldToggleOperationRequest {
    templateId: string;
    issueId: string;
    fieldName: string;
    fieldToggleRequest?: FieldToggleRequest;
}

export interface FieldUpdateBodyOperationRequest {
    templateId: string;
    issueId: string;
    fieldName: string;
    fieldUpdateBodyRequest?: FieldUpdateBodyRequest;
}

/**
 * FieldApi - interface
 * 
 * @export
 * @interface FieldApiInterface
 */
export interface FieldApiInterface {
    /**
     * 
     * @summary Enable or disable the field of the template issue
     * @param {string} templateId ID of the template
     * @param {string} issueId ID of the issue
     * @param {string} fieldName Name of the field
     * @param {FieldToggleRequest} [fieldToggleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApiInterface
     */
    fieldToggleRaw(requestParameters: FieldToggleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Field>>;

    /**
     * Enable or disable the field of the template issue
     */
    fieldToggle(templateId: string, issueId: string, fieldName: string, fieldToggleRequest?: FieldToggleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Field>;

    /**
     * 
     * @summary Update the body of the field of the template issue
     * @param {string} templateId ID of the template
     * @param {string} issueId ID of the issue
     * @param {string} fieldName Name of the field
     * @param {FieldUpdateBodyRequest} [fieldUpdateBodyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApiInterface
     */
    fieldUpdateBodyRaw(requestParameters: FieldUpdateBodyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Field>>;

    /**
     * Update the body of the field of the template issue
     */
    fieldUpdateBody(templateId: string, issueId: string, fieldName: string, fieldUpdateBodyRequest?: FieldUpdateBodyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Field>;

}

/**
 * 
 */
export class FieldApi extends runtime.BaseAPI implements FieldApiInterface {

    /**
     * Enable or disable the field of the template issue
     */
    async fieldToggleRaw(requestParameters: FieldToggleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Field>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling fieldToggle().'
            );
        }

        if (requestParameters['issueId'] == null) {
            throw new runtime.RequiredError(
                'issueId',
                'Required parameter "issueId" was null or undefined when calling fieldToggle().'
            );
        }

        if (requestParameters['fieldName'] == null) {
            throw new runtime.RequiredError(
                'fieldName',
                'Required parameter "fieldName" was null or undefined when calling fieldToggle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/issues/{issue_id}/fields/{field_name}/toggle`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"issue_id"}}`, encodeURIComponent(String(requestParameters['issueId']))).replace(`{${"field_name"}}`, encodeURIComponent(String(requestParameters['fieldName']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FieldToggleRequestToJSON(requestParameters['fieldToggleRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldFromJSON(jsonValue));
    }

    /**
     * Enable or disable the field of the template issue
     */
    async fieldToggle(templateId: string, issueId: string, fieldName: string, fieldToggleRequest?: FieldToggleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Field> {
        const response = await this.fieldToggleRaw({ templateId: templateId, issueId: issueId, fieldName: fieldName, fieldToggleRequest: fieldToggleRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update the body of the field of the template issue
     */
    async fieldUpdateBodyRaw(requestParameters: FieldUpdateBodyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Field>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling fieldUpdateBody().'
            );
        }

        if (requestParameters['issueId'] == null) {
            throw new runtime.RequiredError(
                'issueId',
                'Required parameter "issueId" was null or undefined when calling fieldUpdateBody().'
            );
        }

        if (requestParameters['fieldName'] == null) {
            throw new runtime.RequiredError(
                'fieldName',
                'Required parameter "fieldName" was null or undefined when calling fieldUpdateBody().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/issues/{issue_id}/fields/{field_name}/body`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"issue_id"}}`, encodeURIComponent(String(requestParameters['issueId']))).replace(`{${"field_name"}}`, encodeURIComponent(String(requestParameters['fieldName']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FieldUpdateBodyRequestToJSON(requestParameters['fieldUpdateBodyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldFromJSON(jsonValue));
    }

    /**
     * Update the body of the field of the template issue
     */
    async fieldUpdateBody(templateId: string, issueId: string, fieldName: string, fieldUpdateBodyRequest?: FieldUpdateBodyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Field> {
        const response = await this.fieldUpdateBodyRaw({ templateId: templateId, issueId: issueId, fieldName: fieldName, fieldUpdateBodyRequest: fieldUpdateBodyRequest }, initOverrides);
        return await response.value();
    }

}
