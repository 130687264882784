import { Jira } from "@easy-templates/types";
import { getProjects } from "../project";

export const getPermittedProjects = async (
  requestJira: Jira.AdapterInterface["request"]
): Promise<Jira.Project[]> => {
  var bodyData = JSON.stringify({
    permissions: ["CREATE_ISSUES"],
  });

  const result = await requestJira<{ projects: Jira.Project[] }>(
    `/rest/api/3/permissions/project`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyData,
    }
  );

  const projectIds = result.projects.map(({ id }) => id);
  const projects = await getProjects(requestJira, { id: projectIds });

  return projects;
};

export default getPermittedProjects;
