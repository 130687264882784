import { useQueryClient, useMutation } from "@tanstack/react-query"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

export default () => {
  const { core } = useAppContext()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation({
    mutationFn: async ({ id, name }: { id: string; name: string }) => {
      const result = await core.copyTemplate(id, name)

      if (!result.isOk) {
        throw new Error(result.mainMessage())
      }

      return result.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.list(),
      })
    },
  })

  return { copyTemplate: mutateAsync }
}
