import React from 'react'

const style = {
  color: '#172B4D',
  backgroundColor: '#F4F5F7',
  fontSize: 9,
  padding: 10,
  borderRadius: 3,
  whiteSpace: 'pre-wrap',
  maxHeight: 200,
  overflowY: 'auto',
} as React.CSSProperties

type Props = {
  value: string | unknown
}

const Debug: React.FC<Props> = ({ value }) => (
  <pre style={style}>{JSON.stringify(value, null, 2)}</pre>
)

export default Debug
