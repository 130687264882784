// import { WikiMarkupTransformer as Transformer } from '@atlaskit/editor-wikimarkup-transformer'
import BasicField from "./basic";

// const transformText = (text: string) => {
//   const transformer = new Transformer()
//
//   const result = transformer.parse(text).toJSON()
//
//   return {
//     ...result,
//     version: result['version'] || 1,
//   }
// }

export default class Description extends BasicField {
  value() {
    return this.applyVariables();
  }

  prefillValue() {
    // NOTE: Forge requires the JSON, Connect - the text...
    // return transformText(this.value())
    return this.value();
  }
}
