import { useMutation } from "@tanstack/react-query"

import { VariableValues, Job } from "@easy-templates/types"
import { useAppContext } from "@easy-templates/ui"

interface Return {
  isSubmitting: boolean
  createIssuesFromTemplate: (params: {
    templateId: string
    projectId: string
    variableValues: VariableValues
    rootIssueKey?: string
  }) => unknown
}

type Result = { jobId?: string } | Job

type Props = {
  onError: (error: Error) => void
  onSuccess: (result: Result) => void
}

type MutationParams = {
  templateId: string
  projectId: string
  variableValues: VariableValues
  rootIssueKey?: string
}

export default ({ onSuccess, onError }: Props): Return => {
  const { core } = useAppContext()
  const { mutate: createIssuesFromTemplate, isLoading: isSubmitting } =
    useMutation<Result, Error, MutationParams>({
      mutationFn: async ({ templateId, projectId, variableValues, rootIssueKey }) => {
        const result = await core.createFromTemplate(
          templateId,
          projectId,
          variableValues,
          rootIssueKey
        )

        return result
      },
      onSuccess,
      onError,
    })

  return {
    isSubmitting,
    createIssuesFromTemplate,
  }
}
