import BasicField from "./basic"

export default class EpicLinkField extends BasicField {
  value() {
    if (this.context?.parentKey) {
      return this.context?.parentKey
    }

    return this.body
  }
}
