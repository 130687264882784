import BasicField from './basic'

export default class ServiceDeskOrganizationsField extends BasicField {
  value() {
    if (Array.isArray(this.body)) {
      return this.body.map((organization) => organization['id'])
    }

    return null
  }
}
