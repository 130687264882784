/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateCreationResult
 */
export interface TemplateCreationResult {
    /**
     * Whether child issues and sub-tasks should be created
     * @type {boolean}
     * @memberof TemplateCreationResult
     */
    createChildren?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplateCreationResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateCreationResult
     */
    name?: string;
    /**
     * Account ID of the user who created the template
     * @type {string}
     * @memberof TemplateCreationResult
     */
    createdBy?: string;
}

/**
 * Check if a given object implements the TemplateCreationResult interface.
 */
export function instanceOfTemplateCreationResult(value: object): value is TemplateCreationResult {
    return true;
}

export function TemplateCreationResultFromJSON(json: any): TemplateCreationResult {
    return TemplateCreationResultFromJSONTyped(json, false);
}

export function TemplateCreationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateCreationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'createChildren': json['createChildren'] == null ? undefined : json['createChildren'],
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

export function TemplateCreationResultToJSON(value?: TemplateCreationResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createChildren': value['createChildren'],
        'id': value['id'],
        'name': value['name'],
        'createdBy': value['createdBy'],
    };
}

