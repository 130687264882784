interface APUser {
  user: {
    getCurrentUser: (cb: UserCallback) => void;
  };
}

type Issue = {
  id: string;
  key: string;
  self: string;
  fields: { [name: string]: unknown };
};

interface APJira {
  jira: {
    openCreateIssueDialog: (
      callback: (createdIssues: Issue[]) => void,
      attributes: {
        pid: string;
        issueType: string;
        fields: { [key: string]: unknown };
      }
    ) => void;
    refreshIssuePage: () => void;
  };
}

interface APFlag {
  flag: {
    create: (options: {
      title?: string;
      body?: string;
      type?: "info" | "success" | "warning" | "error";
      close?: "manual" | "auto";
      actions?: { [key: string]: string };
    }) => void;
  };
}

interface APDialog {
  dialog: {
    create: (arg0: { [key: string]: string }) => void;
    close: () => void;
  };
}

interface APEvents {
  events: {
    on: (event: string, callback: (args: unknown) => unknown) => void;
    off: (event: string, callback: (args: unknown) => unknown) => void;
  };
}

interface JiraContext {
  jira: {
    project: {
      id: string;
      key: string;
    };
    issue: {
      id: string;
      key: string;
      issuetype: {
        id: string;
      };
    };
  };
}

interface APContext {
  context: {
    getContext: () => Promise<JiraContext>;
    getToken: () => Promise<string>;
  };
}

interface APNavigator {
  navigator: {
    go: (target: string, params: unknown) => void;
  };
}

type ParamRequest = {
  url: string;
  data?: string;
  type?: string;
  contentType: string;
};

interface APRequest {
  request: (options: ParamRequest) => Promise<{ body: string }>;
}

interface APTheming {
  theming: {
    initializeTheming: () => void;
  };
}

export type JiraError = {
  responseText: string;
  errorMessages: string[];
  errors: { [key: string]: string };
};

export type RequestParams = {
  url: string;
  contentType?: string;
  type?: "PUT" | "POST" | "GET" | "DELETE";
  data?: string;
};

type UserCallback = (user: { atlassianAccountId: string }) => void;

export interface APInterface
  extends APDialog,
    APContext,
    APUser,
    APFlag,
    APJira,
    APNavigator,
    APRequest,
    APTheming,
    APEvents {
  _data: {
    options: {
      uniqueKey: string;
    };
  };
  _hostOrigin: string;
  resize: () => void;
}

// @ts-ignore
const AP = window.AP as APInterface;

export default AP;
