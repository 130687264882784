import DateField from "./date";

export default class DateTimeField extends DateField {
  value() {
    return this.body;
  }

  prefillValue(): unknown {
    if (!Date.parse(this.body as string)) return;
    const date = new Date(this.value());

    return `${this.formatDate(date)} ${this.formatTime(date)}`;
  }

  formatTime(date: Date): string {
    return Intl.DateTimeFormat(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  }
}
