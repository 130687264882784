import ModalDialog, { ModalBody } from "components/ui/ModalDialog"

import NewTemplateFromIssue, {
  Props as NewTemplateFromIssueProps,
} from "./NewTemplateFromIssue"

type Props = NewTemplateFromIssueProps

const AddDialog = ({
  onClose,
  onCompleted
}: Props) => {
  return (
    <ModalDialog
      shouldScrollInViewport={true}
      autoFocus={false}
      onClose={onClose}
      testId="create-template-dialog"
    >
      <ModalBody>
        <NewTemplateFromIssue
          onCompleted={onCompleted}
          onClose={onClose}
        />
      </ModalBody>
    </ModalDialog>
  )
}

export default AddDialog
