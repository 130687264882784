/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateUpdatingResult
 */
export interface TemplateUpdatingResult {
    /**
     * Whether child issues and sub-tasks should be created
     * @type {boolean}
     * @memberof TemplateUpdatingResult
     */
    createChildren?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplateUpdatingResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateUpdatingResult
     */
    name?: string;
    /**
     * Template scope (0 - global, 1 - projects)
     * @type {number}
     * @memberof TemplateUpdatingResult
     */
    scope?: TemplateUpdatingResultScopeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateUpdatingResult
     */
    scopeValue?: Array<string>;
}


/**
 * @export
 */
export const TemplateUpdatingResultScopeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type TemplateUpdatingResultScopeEnum = typeof TemplateUpdatingResultScopeEnum[keyof typeof TemplateUpdatingResultScopeEnum];


/**
 * Check if a given object implements the TemplateUpdatingResult interface.
 */
export function instanceOfTemplateUpdatingResult(value: object): value is TemplateUpdatingResult {
    return true;
}

export function TemplateUpdatingResultFromJSON(json: any): TemplateUpdatingResult {
    return TemplateUpdatingResultFromJSONTyped(json, false);
}

export function TemplateUpdatingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateUpdatingResult {
    if (json == null) {
        return json;
    }
    return {
        
        'createChildren': json['createChildren'] == null ? undefined : json['createChildren'],
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'scope': json['scope'] == null ? undefined : json['scope'],
        'scopeValue': json['scopeValue'] == null ? undefined : json['scopeValue'],
    };
}

export function TemplateUpdatingResultToJSON(value?: TemplateUpdatingResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createChildren': value['createChildren'],
        'id': value['id'],
        'name': value['name'],
        'scope': value['scope'],
        'scopeValue': value['scopeValue'],
    };
}

