import { Jira } from "@easy-templates/types";

export const rankIssuesBefore = async (
  requestJira: Jira.AdapterInterface["request"],
  keys: string[],
  beforeKey: string
) => {
  var bodyData = JSON.stringify({
    rankBeforeIssue: beforeKey,
    issues: keys,
  });

  const response = await requestJira(`/rest/agile/1.0/issue/rank`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: bodyData,
  });

  return response;
};

export const rankIssuesAfter = async (
  requestJira: Jira.AdapterInterface["request"],
  keys: string[],
  afterKey: string
) => {
  var bodyData = JSON.stringify({
    rankAfterIssue: afterKey,
    issues: keys,
  });

  const response = await requestJira(`/rest/agile/1.0/issue/rank`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: bodyData,
  });

  return response;
};
