import React from "react";

import { Template } from "@easy-templates/types";

import DynamicTable from "components/ui/DynamicTable";
import IconText from "components/ui/IconText";
import Button from "components/ui/Button";


type Props = {
  onApply: (templateId: string) => void;
  templates: Template[];
};

const EmptyState = () => <>Not found</>;

const TemplatesList: React.FC<Props> = ({ templates, onApply }) => {
  const rows = templatesToRows({
    templates: templates || [],
    handleApply: onApply,
  });

  const head = {
    cells: [
      { content: "Name", isSortable: true, key: "name" },
      { key: "type", isSortable: true, content: "Issue type" },
      { key: "action", width: 15 },
    ],
  };

  return (
    <DynamicTable
      emptyView={<EmptyState />}
      head={head}
      rows={rows}
      rowsPerPage={10}
      defaultPage={1}
      loadingSpinnerSize="large"
      isLoading={false}
      isFixedSize={true}
      defaultSortKey="name"
      defaultSortOrder="ASC"
      testId="apply-templatesList"
    />
  );
};

const templateToRow = (
  template: Template,
  handleApply: (templateId: string) => void
) => {
  const handleApplyClick = () => handleApply(template.id);

  return {
    key: template.id,
    cells: [
      {
        key: template.name,
        content: template.name,
      },
      {
        key: template.issuetype.name,
        content: (
          <IconText iconUrl={template.issuetype.iconUrl}>
            {template.issuetype.name}
          </IconText>
        ),
      },
      {
        content: (
          <Button
            onClick={handleApplyClick}
            testId={`templates-list--apply-button--template-${template.id}`}
          >
            Apply
          </Button>
        ),
      },
    ],
  };
};

const templatesToRows = ({
  templates,
  handleApply,
}: {
  templates: Template[];
  handleApply: (templateId: string) => void;
}) => templates.map((template) => templateToRow(template, handleApply));

export default TemplatesList;
