import React, { useContext, useEffect, useState } from "react"
import { Jira, InitializerInterface, InitializationResult } from "@easy-templates/types"
import type Core from "@easy-templates/lib"

import Loading from "./Loading"

export type FeatureFlags = {
  prefill?: boolean
  troubleShooting?: boolean
  richTextEditor?: boolean
  variableNumber?: boolean
  variableDate?: boolean
  variableSelect?: boolean
  variableMultiSelect?: boolean
  variableUser?: boolean
}

export type AppContextState = Jira.PlatformContext & {
  apiUrl: string
  isReady: boolean
  path: string
  host: string
  shouldRedirect?: boolean
  maintenanceModeOn?: boolean
  platform?: string // "connect" | "dc"
  onMount: () => void
  featureFlags: FeatureFlags
  core: Core
}

type Props = {
  children: React.ReactElement
  context?: Jira.PlatformContext
  featureFlags?: FeatureFlags
  initializer?: InitializerInterface
  core: Core
}


const AppContext = React.createContext<AppContextState>(null)

export default ({
  children,
  featureFlags,
  context,
  core,
  initializer,
}: Props): JSX.Element => {
  const initialState = {
    core,
    accountId: "",
    apiUrl: "http://localhost:4000",
    cloudId: "",
    ...(context || {}),
    featureFlags: featureFlags || {
      prefill: true,
      troubleShooting: false,
      richTextEditor: true
    },
    host: "",
    isReady: false,
    onMount: () => { },
    path: "/start",
    shouldRedirect: false,
  }

  const [appContext, setContext] = useState<AppContextState>(initialState)

  useEffect(() => {
    if (!initializer) {
      return setContext({
        ...appContext,
        isReady: true
      })
    }

    initializer
      .run()
      .then(
        ({
          apiUrl,
          path,
          host,
          platform,
          onMount,
          maintenanceModeOn,
          ...rest
        }: InitializationResult) => {
          setContext({
            ...appContext,
            ...rest.context,
            core,
            host,
            path,
            isReady: true,
            shouldRedirect: false,
            apiUrl,
            platform,
            onMount,
            maintenanceModeOn,
          })
        }
      )
  }, [])

  return (
    <AppContext.Provider value={appContext}>
      {appContext.isReady ? children : <Loading />}
    </AppContext.Provider>
  )
}

export const useAppContext = (): AppContextState => {
  return useContext(AppContext)
}
