/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnalyticsSendRequestAnyOf1Payload
 */
export interface AnalyticsSendRequestAnyOf1Payload {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsSendRequestAnyOf1Payload
     */
    eventType?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsSendRequestAnyOf1Payload
     */
    userId?: string;
    /**
     * 
     * @type {object}
     * @memberof AnalyticsSendRequestAnyOf1Payload
     */
    eventProps?: object;
    /**
     * 
     * @type {object}
     * @memberof AnalyticsSendRequestAnyOf1Payload
     */
    userProps?: object;
}

/**
 * Check if a given object implements the AnalyticsSendRequestAnyOf1Payload interface.
 */
export function instanceOfAnalyticsSendRequestAnyOf1Payload(value: object): value is AnalyticsSendRequestAnyOf1Payload {
    return true;
}

export function AnalyticsSendRequestAnyOf1PayloadFromJSON(json: any): AnalyticsSendRequestAnyOf1Payload {
    return AnalyticsSendRequestAnyOf1PayloadFromJSONTyped(json, false);
}

export function AnalyticsSendRequestAnyOf1PayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticsSendRequestAnyOf1Payload {
    if (json == null) {
        return json;
    }
    return {
        
        'eventType': json['eventType'] == null ? undefined : json['eventType'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'eventProps': json['eventProps'] == null ? undefined : json['eventProps'],
        'userProps': json['userProps'] == null ? undefined : json['userProps'],
    };
}

export function AnalyticsSendRequestAnyOf1PayloadToJSON(value?: AnalyticsSendRequestAnyOf1Payload | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventType': value['eventType'],
        'userId': value['userId'],
        'eventProps': value['eventProps'],
        'userProps': value['userProps'],
    };
}

