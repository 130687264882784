import { useQuery } from "@tanstack/react-query"
import { TemplateVariable } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

const useTemplateVariables = (templateId: string) => {
  const { core } = useAppContext()

  const { data, isLoading, error } = useQuery<TemplateVariable[], Error>({
    enabled: !!templateId,
    queryKey: templateKeys.variables(templateId),
    queryFn: async () => await core.getTemplateVariables(templateId),
  })

  return {
    isLoading,
    error,
    variables: data || [],
  }
}

export default useTemplateVariables