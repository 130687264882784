import { useQuery } from "@tanstack/react-query"
import { Template, Jira } from "@easy-templates/types"
import { useAppContext } from "@easy-templates/ui"

import { formDataKeys } from "lib/queryKeys"

interface Return {
  isLoading: boolean
}

type Props = {
  onCompleted: (args: {
    templates: Template[]
    projects: Jira.Project[]
  }) => void
  onError: (error: Error) => void
}

const useTemplates = ({ onCompleted, onError }: Props): Return => {
  const { core, maintenanceModeOn } = useAppContext()

  return useQuery({
    enabled: !maintenanceModeOn,
    queryKey: formDataKeys.keyFor("new-issue"),
    queryFn: async () => {
      try {
        const { templates, projects } =
          await core.getIssueFormData()

        onCompleted({ templates, projects })

        return templates
      } catch (error) {
        onError(error)
      }
    },
  })
}

export default useTemplates
