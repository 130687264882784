export default {
  root: {
    color: "grey",
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  },
  logo: {
    margin: "0 5px"
  }
}
