import React from 'react'

import useStyles from './useStyles'

type Props = {
  children: React.ReactNode
  header: React.ReactNode
  help: React.ReactNode
  navigation?: React.ReactNode
}

const Layout = ({ children, header, help, navigation }: Props): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div data-testid="header" className={classes.head}>
        <div>{header}</div>
        {help}
      </div>
      <div className={classes.body}>
        <div className={classes.navigation}>{navigation}</div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  )
}

export default Layout
