/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableResponse
 */
export interface VariableResponse {
    /**
     * Label of the variable, used in markup
     * @type {string}
     * @memberof VariableResponse
     */
    label: string;
    /**
     * Description of the variable, used to explain its purpose
     * @type {string}
     * @memberof VariableResponse
     */
    description: string;
    /**
     * Whether the variable is required to have a value to create issues from its template
     * @type {boolean}
     * @memberof VariableResponse
     */
    required: boolean;
    /**
     * The type of the variable, specifies both the kind of data a variable can store and what widget is used for its display
     * @type {string}
     * @memberof VariableResponse
     */
    type: VariableResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VariableResponse
     */
    defaultValue: string | null;
    /**
     * Identifier of the variable
     * @type {string}
     * @memberof VariableResponse
     */
    id: string;
}


/**
 * @export
 */
export const VariableResponseTypeEnum = {
    Text: 'text',
    Longtext: 'longtext'
} as const;
export type VariableResponseTypeEnum = typeof VariableResponseTypeEnum[keyof typeof VariableResponseTypeEnum];


/**
 * Check if a given object implements the VariableResponse interface.
 */
export function instanceOfVariableResponse(value: object): value is VariableResponse {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('required' in value) || value['required'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function VariableResponseFromJSON(json: any): VariableResponse {
    return VariableResponseFromJSONTyped(json, false);
}

export function VariableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'description': json['description'],
        'required': json['required'],
        'type': json['type'],
        'defaultValue': json['defaultValue'],
        'id': json['id'],
    };
}

export function VariableResponseToJSON(value?: VariableResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'description': value['description'],
        'required': value['required'],
        'type': value['type'],
        'defaultValue': value['defaultValue'],
        'id': value['id'],
    };
}

