import { PageHeader } from "components/ui/Page"

type Props = {
  title?: string
  children: React.ReactNode
  actions?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  bottomBar?: React.ReactElement<any>
}

const PageWrapper = ({ title, children, actions, bottomBar }: Props) => {
  return (
    <div style={{ padding: 12, paddingBottom: 52 }}>
      {title && (
        <PageHeader actions={actions} bottomBar={bottomBar}>
          {title}
        </PageHeader>
      )}
      {children}
    </div>
  )
}

export default PageWrapper
