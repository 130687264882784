import BasicField from "./basic";

export default class RadioButtonsField extends BasicField {
  value() {
    return this.body;
  }

  prefillValue() {
    if (Array.isArray(this.value())) {
      return this.value().map((data: unknown) =>
        this.valueFinder.getValue(data)
      );
    }

    return this.valueFinder.getValue(this.value());
  }
}
