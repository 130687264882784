import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useAppContext } from "@easy-templates/ui"
import { Template } from "@easy-templates/types"

import { templateKeys } from "lib/queryKeys"

type Variable = {
  id: string
  name?: string
  createChildren?: boolean
  scope?: string
  scopeValue?: string[]
}

export default () => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutate } = useMutation({
    mutationFn: async ({ id, ...params }: Variable) => {
      console.debug(">> UI: Update template", { id, params })

      return await core.updateTemplate(id, params)
    },
    onMutate: async (attributes: Variable) => {
      const templateQueryKey = templateKeys.details(attributes.id)
      const templatesQueryKey = templateKeys.list()
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: templateQueryKey })

      // Snapshot the previous value
      const oldTemplate = queryClient.getQueryData(templateQueryKey)
      const oldTemplates = queryClient.getQueryData(templatesQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(templateQueryKey, (old: Template) => ({
        ...old,
        ...attributes,
      }))

      queryClient.setQueryData(templatesQueryKey, (oldTemplates: Template[]) => {
        if (!oldTemplates) {
          return []
        }

        return oldTemplates.map((tmpl) => {
          if (tmpl.id === attributes.id) {
            return {
              ...tmpl,
              ...attributes,
            }
          }

          return tmpl
        })
      })

      // Return a context object with the snapshotted value
      return { oldTemplate, oldTemplates }
    },
    onError: (_err, attributes, context) => {
      queryClient.setQueryData(templateKeys.details(attributes.id), context.oldTemplate)
      queryClient.setQueryData(templateKeys.list(), context.oldTemplates)
    },
  })

  return { updateTemplate: mutate }
}
