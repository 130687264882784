import { CSSProperties } from 'react'

const styles: { helpLinks: CSSProperties } = {
  helpLinks: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}

export default styles
