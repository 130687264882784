export const templateKeys = {
  list: () => ["templates-list"] as const,
  details: (templateId: string) => ["templates", templateId] as const,
  issues: (templateId: string) => ["templates", templateId, "issues"] as const,
  variables: (templateId: string) => ["templates", templateId, "variables"] as const,
  issue: (templateId: string, issueId: string) => ["templates", templateId, "issues", issueId] as const,
  variable: (templateId: string, variableId: string) => ["templates", templateId, "variables", variableId] as const,
}

export const jobKeys = {
  details: (jobId: string) => ["jobs", jobId] as const,
}

export const jiraKeys = {
  projects: {
    list: () => ["jira-projects-list"] as const,
  }
}

export const formDataKeys = {
  keyFor: (name: string, params: { [key: string]: unknown } = {}) => [name, "form-data", { params }] as const,
}

export const userKeys = {
  details: (accountId: string) => ["users", accountId] as const,
}
