import { Jira } from "@easy-templates/types";

export const addComment = async (
  requestJira: Jira.AdapterInterface["request"],
  issueIdOrKey: string,
  commentBody: any
) => {
  const response = await requestJira(
    `/rest/api/3/issue/${issueIdOrKey}/comment`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(commentBody),
    }
  );

  return response;
};

export default addComment;
