import { createUseStyles } from "react-jss";

export default createUseStyles({
  root: {
    position: "relative",
    minHeight: 300,
    overflow: "hidden",
  },
  wrapper: {
    minWidth: 200,
    background: "white",
    borderRadius: 10,
    maxWidth: 400,
    padding: 10,
    position: "absolute",
    top: "50%;",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  icon: {
    maxHeight: "300px",
    display: "block",
    margin: "0 auto",
  },
  supportButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 12,
  },
});
