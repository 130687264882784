import { VariableType } from '@easy-templates/types'
import { useAppContext } from 'components/AppContextProvider'

import {
  ShortTextIcon,
  LongTextIcon,
  NumberIcon,
  SelectIcon,
  MultiSelectIcon,
  DateIcon,
  UserIcon,
} from './Variable/TypeIcon'


export const useVariableOptions = () => {
  const { featureFlags } = useAppContext()

  const options = [
    { type: VariableType.text, Icon: ShortTextIcon, label: "Short text" },
    { type: VariableType.longtext, Icon: LongTextIcon, label: "Long text" },
    { type: VariableType.number, Icon: NumberIcon, label: "Number", comingSoon: !featureFlags.variableNumber },
    { type: VariableType.date, Icon: DateIcon, label: "Date", comingSoon: !featureFlags.variableDate },
    { type: VariableType.select, Icon: SelectIcon, label: "Select dropdown", comingSoon: !featureFlags.variableSelect },
    { type: VariableType.multiselect, Icon: MultiSelectIcon, label: "Multiselect dropdown", comingSoon: !featureFlags.variableMultiSelect },
    { type: VariableType.user, Icon: UserIcon, label: "User", comingSoon: !featureFlags.variableUser },
  ]

  return {
    options,
    getVariableTypeIcon: (type: VariableType) => {
      const Icon = options.find(predicate => predicate.type === type)?.Icon

      if (!Icon) return null

      return <Icon label={type} />
    }
  }
}