export { default as extensionHandlersFactory } from './extensionHandlers'
export { default as quickInsertProviderFactory } from './quickInsertProviderFactory'
export { default as Icon } from './Icon'
// import { extensionKey } from './const'

// Variable representation in ADF
// export const buildDocItem = (name) => ({
//   type: 'inlineExtension',
//   attrs: {
//     extensionType: 'com.atlassian.extensions.update',
//     // extensionType: 'com.atlassian.extensions.noupdate',
//     // extensionType: 'com.atlassian.confluence.macro.core',
//     extensionKey,
//     text: 'Inline extension demo',
//     parameters: {
//       name: name,
//     },
//   },
// })


export const buildTextItem = (label: string) => `<<${label}>>`

export const buildDocItem = (label: string) => ({
  type: 'text',
  text: `<<${label}>>`,
})
