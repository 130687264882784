import BasicField from "./basic";
import ValueFinder from "./value-finder";
import { Context, FieldData } from "./types";

export default class AgileSprintField extends BasicField {
  constructor(data: FieldData, context?: Context, valueFinder?: ValueFinder) {
    super(data, context);
    this.valueFinder = valueFinder || new ValueFinder(data.body);
  }

  value() {
    if (this.context?.isSubtask) {
      return null;
    }

    return this.valueFinder.sprintId();
  }
}
