import { Jira } from "@easy-templates/types";
import BaseAction from "./base";
import { State } from "../types";

export default class IssuePrefillCompletedAction extends BaseAction {
  constructor(public issues: Jira.IssueBean[]) {
    super();
  }

  apply(state: State): State {
    const createdIssue = this.issues[0];

    return {
      ...state,
      errorMessage: null,
      isPrefilling: false,
      // @ts-ignore
      results: createdIssue
        ? {
          ...state.results,
          [state.selectedTemplate.rootIssueId]: {
            status: "ok",
            result: createdIssue.key,
          },
        }
        : state.results,
    };
  }
}
