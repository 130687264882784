import React from "react"
import Button, { LoadingButton } from "components/ui/Button"
import { useFormState } from "components/ui/form"

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/ui/ModalDialog"

type FormChildrenProps = {
  ref: React.RefObject<HTMLFormElement>
  onSubmit: (event?: React.FormEvent<HTMLFormElement> | React.SyntheticEvent<HTMLElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void
}

type Props = {
  heading: string
  submitLabel: string
  closeLabel?: string
  onClose: () => void
  formProps: FormChildrenProps
  children: React.ReactNode
  testId?: string
}

const Layout = ({
  heading,
  closeLabel,
  submitLabel,
  children,
  onClose,
  formProps,
  testId
}: Props): JSX.Element => {
  // NOTE: Form state `dirty` doesn't work well with conditional/dynamic forms.
  // In our case it's the default value field.
  const formState = useFormState({ invalid: true, submitting: true })

  return (
    <Modal onClose={onClose} testId="variable-form-dialog" shouldScrollInViewport={false}>
      <form {...formProps} data-testid={testId}>
        <ModalHeader>
          <ModalTitle>{heading}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button type="reset" appearance="subtle-link" onClick={onClose} testId="cancel">
            {closeLabel || "Cancel"}
          </Button>
          <LoadingButton
            appearance="primary"
            autoFocus
            type="submit"
            isDisabled={formState?.submitting || formState?.invalid}
            isLoading={formState?.submitting}
            testId="submit"
          >
            {submitLabel}
          </LoadingButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default Layout
