import { Template } from "@easy-templates/types"
import BaseAction from "./base"
import { State } from "../types"

export default class TemplateSelectedAction extends BaseAction {
  selectedTemplate: Template

  constructor(selectedTemplate: Template) {
    super()
    this.selectedTemplate = selectedTemplate
  }

  apply(state: State): State {
    return {
      ...state,
      selectedTemplate: this.selectedTemplate,
      fullSelectedTemplate: null,
      errorMessage: null,
      warnings: [],
      results: {},
    }
  }
}
