import { useMutation, useQueryClient } from "@tanstack/react-query"
import { arrayMove } from '@dnd-kit/sortable'

import { TemplateVariable } from '@easy-templates/types'

import { templateKeys } from 'lib/queryKeys'
import { useAppContext } from "components/AppContextProvider"

type Params = {
  templateId: string,
  onError: (title: string, description?: string) => void,
}

type Values =
  { id: string, before: string } | { id: string, after: string }

export default ({ templateId, onError }: Params) => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutateAsync: rankVariable, isLoading: isCreating, error } = useMutation({
    mutationFn: async (params: Values) => {
      console.debug('Ranking the variable', { params })

      const { id, ...target } = params
      const result = await core.rankTemplateVariable(templateId, id, target)

      if (result.isOk) {
        console.debug('Ranked the variable', { result })
        return result
      } else if ("errors" in result.error) {
        const firstError = Object.values(result.error.errors)[0]

        if (firstError.length > 0) {
          console.debug(firstError[0].details)
          onError?.(firstError[0].details)
        }
      }

      console.debug('Variable ranking failed', { result })

      throw result.error
    },
    onMutate: async (params: Values) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const variablesQueryKey = templateKeys.variables(templateId)

      await queryClient.cancelQueries({ queryKey: variablesQueryKey })

      // Snapshot the previous value
      const oldVariables = queryClient.getQueryData(variablesQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(variablesQueryKey, (oldVariables: TemplateVariable[]) => {
        const oldIndex = oldVariables.findIndex(({ id }) => id === params.id)

        const newIndex = ("before" in params) ?
          oldVariables.findIndex(({ id }) => id === params.before) :
          oldVariables.findIndex(({ id }) => id === params.after)

        return arrayMove(oldVariables, oldIndex, newIndex)
      })

      // Return a context object with the snapshotted value
      return { oldVariables }
    },
    onSettled: (_data, _error, { }) => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.variables(templateId),
      })

      queryClient.invalidateQueries({
        queryKey: templateKeys.details(templateId),
      })
    },
  })

  return { rankVariable, isCreating, error }
}
