import { Template } from "@easy-templates/types";

export const generateNavigationTree = (
  tree: Template["tree"],
  issues: Template["issues"]
) => {
  const rootId = tree.root[0];

  const items = Object.fromEntries(
    Object.entries(issues).map((entry) => {
      const issue = entry[1];
      const children = tree[issue.id] || ([] as string[]);

      return [
        entry[0],
        {
          id: issue.id,
          name: issue.name,
          iconUrl: issue.iconUrl,
          rank: issue.rank,
          children,
        },
      ];
    })
  );

  return { rootId, items };
};

export default generateNavigationTree;
