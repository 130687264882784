import { TemplateVariable } from "@easy-templates/types"
import Variables from "components/Variables"

import useStyles from "./useStyles"

type Props = {
  variables: TemplateVariable[]
}

const Preview = ({ variables }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="variables-preview">
      <Variables variables={variables} />
    </div>
  )
}

export default Preview