import BaseAction from "./base";
import { State } from "../types";

export default class IssueCreatedAction extends BaseAction {
  constructor(public jobId: string) {
    super();
  }

  apply(state: State): State {
    return {
      ...state,
      jobId: this.jobId,
    };
  }
}
