import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Template } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

type Params = {
  id: string
}

const useDeleteIssue = ({ templateId }: { templateId: string }) => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()
  const templateQueryKey = templateKeys.details(templateId)
  const issuesQueryKey = templateKeys.issues(templateId)

  const { mutateAsync } = useMutation({
    mutationFn: async ({ id }: Params) => {
      return await core.deleteTemplateIssue(templateId, id)
    },
    onMutate: async ({ id }: Params) => {
      await queryClient.cancelQueries({ queryKey: templateQueryKey })

      const oldTemplate: Template = queryClient.getQueryData(templateQueryKey)

      queryClient.setQueryData(
        templateQueryKey,
        (oldTemplate: Template) => {
          const issues = Object.fromEntries(
            Object.values(oldTemplate.issues)
              .filter((issue) => issue.id !== id)
              .map((issue) => [issue.id, issue])
          )

          const tree = Object.fromEntries(
            Object.entries(oldTemplate.tree).map((entry) => [
              entry[0],
              entry[1].filter((item) => item !== id),
            ])
          )

          return {
            ...oldTemplate,
            issues,
            tree,
          }
        }
      )

      return { oldTemplate }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: templateQueryKey })
      queryClient.invalidateQueries({ queryKey: issuesQueryKey })
    },
    onError: (err, _id, context) => {
      console.error("Deleting issue error", err)
      queryClient.setQueryData(templateQueryKey, context.oldTemplate)
    },
  })

  return { deleteIssue: mutateAsync }
}

export default useDeleteIssue
