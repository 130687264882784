import Item from './Item'

export type TreeNode = {
  id: string
  children: TreeNode[]
  name: string
  iconUrl: string
}

type Props = {
  parentId?: string
  tree: TreeNode | null
  onCopy: (id: string, name: string) => void
  onDelete: (id: string) => void
  onSelect: (id: string) => void
  selectedId?: string
}

const Tree = ({
  onCopy,
  onDelete,
  onSelect,
  parentId,
  selectedId,
  tree,
}: Props): JSX.Element | null => {
  if (!tree) {
    return null
  }

  return (
    <Item
      id={tree.id}
      parentId={parentId}
      iconUrl={tree.iconUrl}
      title={tree.name || 'Unknown'}
      nodes={tree.children}
      onCopy={onCopy}
      onDelete={onDelete}
      onSelect={onSelect}
      selectedId={selectedId}
    />
  )
}

export default Tree
