/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldToggleRequest
 */
export interface FieldToggleRequest {
    /**
     * Whether the field should be enabled or disabled
     * @type {boolean}
     * @memberof FieldToggleRequest
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the FieldToggleRequest interface.
 */
export function instanceOfFieldToggleRequest(value: object): value is FieldToggleRequest {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function FieldToggleRequestFromJSON(json: any): FieldToggleRequest {
    return FieldToggleRequestFromJSONTyped(json, false);
}

export function FieldToggleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldToggleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

export function FieldToggleRequestToJSON(value?: FieldToggleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
    };
}

