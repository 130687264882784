import Loading from "components/Loading"
import ErrorState from "components/ErrorState"

import FieldsList from "./FieldsList"
import useIssueData from "./useIssueData"

type Props = {
  templateId: string
  issueId: string
}

const IssueForm = ({
  issueId,
  templateId,
}: Props): JSX.Element => {
  const { issue, isLoading, error } = useIssueData(templateId, issueId)

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  if (!issue?.fields) {
    return <ErrorState error={Error("Issue not found...")} />
  }

  const fieldsList = Object.values(issue.fields).map(
    // makes the Optimistic UI work
    (field) => ({
      ...field,
      disabled: issue.disabledFields.includes(field.name),
    })
  )

  return (
    <div data-testid="issue-form">
      <FieldsList
        fields={fieldsList}
        issueId={issue.id}
        templateId={templateId}
      />
    </div>
  )
}

export default IssueForm
