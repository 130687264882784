import { AnalyticsApi, Configuration } from "@easy-templates/lib"

import platform from './platform'

export default class ConnectAnalytics {
  constructor(public host: string, public accountId?: string) {
  }

  async getApi() {
    const basePath = window.document.getElementById('root').dataset.apiUrl + '/api'

    const jwt = await platform.context.getToken()
    const configuration = new Configuration({ apiKey: `JWT ${jwt}`, basePath, fetchApi: fetch })

    return new AnalyticsApi(configuration)
  }

  async logEvent(eventType, eventPropertiesIn, fn?) {
    try {
      const api = await this.getApi()

      api.analyticsSend({
        type: "EVT",
        payload: {
          eventType,
          eventProps: eventPropertiesIn,
          userId: this.host,
          userProps: { userId: this.accountId, host: this.host },
        }
      })
    } catch (_error) {
      console.error('analytics rejected')
    }

    if (fn) {
      fn()
    }
  }
}