import { createUseStyles } from "react-jss"

export default createUseStyles({
  root: {
    maxWidth: 960,
    minHeight: 600,
  },
  head: {
    padding: "0 0 8px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
  },
  navigation: {
    flexGrow: 0.1,
    paddingLeft: 16,
    paddingRight: 24,
    maxWidth: "40%",
    minWidth: 310,
  },
  content: {
    flexGrow: 0.9,
  },
})
