import { useQuery } from "@tanstack/react-query"
import { Template } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

type Result = {
  isLoading: boolean
  error: Error | undefined
  templates: Template[]
}

const useData = ({ projectId }): Result => {
  // const queryClient = useQueryClient();
  const { core } = useAppContext()

  const {
    isLoading,
    data: templates,
    error,
  } = useQuery<Template[], Error>({
    queryKey: templateKeys.list(),
    queryFn: () => core.getTemplatesList({ projectId }),
    onError: (error) => {
      console.debug("Error", error)
    },
  })

  return { isLoading, error, templates }
}

export default useData
