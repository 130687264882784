import WarningIcon from "@atlaskit/icon/glyph/warning";
import Banner from "@atlaskit/banner";

type Props = {
  message?: string;
};

{/* @ts-ignore */ }
const Icon = <WarningIcon label="Warning icon" secondaryColor="inherit" />;

export const WarningBanner = ({ message }: Props): JSX.Element => (
  <Banner icon={Icon} appearance="warning">
    {message}
  </Banner>
);

export default WarningBanner;
