import { createUseStyles } from "react-jss";

export default createUseStyles({
  root: {
    // marginTop: -8,
  },
  label: {
    display: "flex",
    alignItems: "end",
  },
  hint: {
    // marginLeft: 4,
  },
  content: {
    minHeight: 36,
  },
});
