import SVG from "@atlaskit/icon/svg"
import type { SVGProps } from "@atlaskit/icon/types"

const HiddenIcon = (props: SVGProps) => {
  const { primaryColor, size, label } = props
  return (
    <SVG primaryColor={primaryColor} size={size} label={label}>
      <text
        x="50%"
        y="50%"
        text-anchor="middle"
        font-size="15"
        fontWeight={600}
        fill="currentColor"
        dy="0.35em">
        42
      </text>
    </SVG>
  )
}

export default HiddenIcon
