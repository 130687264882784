import BasicField from './basic'
import ValueFinder from "./value-finder";
import { Context, FieldData } from "./types";

export default class LabelsField extends BasicField {
  constructor(data: FieldData, context?: Context, valueFinder?: ValueFinder) {
    super(data, context);
    this.valueFinder = valueFinder || new ValueFinder(data.body);
  }

  value() {
    if (Array.isArray(this.body)) {
      return this.body.map(this.valueFinder.getValue);
    }

    return this.body
  }
}
