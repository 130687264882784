import BasicField from "./basic";

export default class MultiSelectField extends BasicField {
  value() {
    return this.body;
  }

  prefillValue() {
    if (!Array.isArray(this.value())) return;

    const result = this.value().map((data: unknown) =>
      this.valueFinder.getValue(data)
    );

    return result;
  }
}
