import { Job, JobStatus } from "@easy-templates/types"
import BaseAction from "./base"
import { State } from "../types"

export default class JobUpdatedAction extends BaseAction {
  constructor(public job: Job) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      results: this.job.results || {},
      jobId: this.job.status === JobStatus.IN_PROGRESS ? this.job.id : null,
    }
  }
}
