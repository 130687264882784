import BaseAction from './base'
import { State } from '../types'

export default class StepChangedAction extends BaseAction {

  constructor(public step: State['currentStep']) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      jobId: null,
      errorMessage: undefined,
      results: {},
      currentStep: this.step,
    }
  }
}
