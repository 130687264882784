import React from 'react'
import InlineMessage from 'components/ui/InlineMessage'

type Props = {
  fields: string[]
}

const MissingFields: React.FC<Props> = ({ fields }) => {
  return (
    <InlineMessage title="Template is missing required fields" type="warning">
      <p>
        The template is missing some required fields (&quot{fields.join('", "')}
        &quot). An Issue may not be created.
      </p>
    </InlineMessage>
  )
}

export default MissingFields
