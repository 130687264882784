import SVG from "@atlaskit/icon/svg";
import type { SVGProps } from "@atlaskit/icon/types";

const HiddenIcon = (props: SVGProps) => {
  const { primaryColor, size, label } = props;
  return (
    <SVG primaryColor={primaryColor} size={size} label={label}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M11.983 15.984a4.005 4.005 0 01-4.002-4c0-2.206 1.795-4 4.002-4a4.005 4.005 0 014.002 4c0 2.206-1.795 4-4.002 4M12 4C6.48 4 2 8.84 2 12c0 3.086 4.577 8 10 8s10-4.914 10-8c0-3.16-4.481-8-10-8"></path>
        <circle cx="12" cy="12" r="2"></circle>
      </g>
      <path d="M3 3L20 20" stroke="currentColor" strokeWidth="2" />
    </SVG>
  );
};

export default HiddenIcon;
