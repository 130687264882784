import React from "react"
import appligerLogo from "images/appliger-logo.png"

import styles from "./styles"

const PoweredBy: React.FC = () => (
  <div style={styles.root}>
    <span>Powered by</span>
    <span>
      <img
        alt="Appliger"
        src={appligerLogo}
        height="20"
        style={styles.logo}
      />
    </span>
    <span>AppLiger</span>
  </div>
)

export default PoweredBy
