import BasicField from "./basic";

export default class SecurityLevelField extends BasicField {
  value() {
    return this.body;
  }

  prefillValue(): unknown {
    return this.valueFinder.getValue(this.value());
  }
}
