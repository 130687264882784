import { TelemetryInterface } from "@easy-templates/types"

export class Telemetry implements TelemetryInterface {
  async trackPage(moduleKey: string) {
    console.debug("> Page visited:", moduleKey)
  }

  async trackEvent(eventType: string, eventProps?: { [key: string]: unknown }) {
    console.debug(">", eventType, eventProps)
  }

  async captureMessage(message: string, extra: { [key: string]: unknown }) {
    console.debug(">", message, extra)
  }
}

export class SilentTelemetry implements TelemetryInterface {
  async trackPage(moduleKey: string) { }

  async trackEvent(eventType: string, eventProps?: { [key: string]: unknown }) { }

  async captureMessage(message: string, extra: { [key: string]: unknown }) { }
}


export default Telemetry
