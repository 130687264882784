import Link from "components/Link";
import QuestionCircleIcon from "components/ui/icons/QuestionCircleIcon";

import useStyles from "./useStyles";

type Props = {
  url?: string;
};

const Help = ({ url }: Props): JSX.Element => {
  const classes = useStyles();
  const href = url || "http://go.appliger.com/manage1e4b4";

  return (
    <div className={classes.root}>
      <Link href={href}>
        {/* @ts-ignore */}
        <QuestionCircleIcon label="documentation" primaryColor="#8777D9" />
      </Link>
      &nbsp;
      <Link href={href}>Help</Link>
    </div>
  );
};

export default Help;
