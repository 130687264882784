import BasicField from "./basic";

export default class IssueTypeField extends BasicField {
  value() {
    return this.valueFinder.objectValue(this.body);
  }

  prefillValue() {
    return this.valueFinder.objectValue(this.body);
  }
}
