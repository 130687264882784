import { LogOnMount, UIEvent } from "components/Analytics";
import EmptyState from "components/ui/EmptyState";

import lockImage from "./lock.png";

type Props = {
  header?: string;
  message?: string;
};

const LockedState = ({ message, header }: Props) => {
  return (
    <div
      data-testid="locked-state"
      style={{
        minWidth: 200,
        background: "white",
        borderRadius: 10,
        maxWidth: 400,
        padding: 10,
        margin: "100px auto",
      }}
    >
      <EmptyState
        header={header || ""}
        description={message}
        imageUrl={lockImage}
      />
      <LogOnMount eventType={UIEvent.LOCKED_STATE} />
    </div>
  );
};

export default LockedState;
