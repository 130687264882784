/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Issue,
  IssueCopyRequest,
  NotFound,
} from '../models/index';
import {
    IssueFromJSON,
    IssueToJSON,
    IssueCopyRequestFromJSON,
    IssueCopyRequestToJSON,
    NotFoundFromJSON,
    NotFoundToJSON,
} from '../models/index';

export interface IssueCopyOperationRequest {
    templateId: string;
    issueId: string;
    issueCopyRequest?: IssueCopyRequest;
}

export interface IssueDeleteRequest {
    templateId: string;
    issueId: string;
}

export interface IssueGetRequest {
    templateId: string;
    issueId: string;
}

export interface IssueListRequest {
    templateId: string;
}

/**
 * IssueApi - interface
 * 
 * @export
 * @interface IssueApiInterface
 */
export interface IssueApiInterface {
    /**
     * 
     * @summary Copy a template issue
     * @param {string} templateId ID of the template
     * @param {string} issueId ID of the issue
     * @param {IssueCopyRequest} [issueCopyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueCopyRaw(requestParameters: IssueCopyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Issue>>;

    /**
     * Copy a template issue
     */
    issueCopy(templateId: string, issueId: string, issueCopyRequest?: IssueCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Issue>;

    /**
     * 
     * @summary Delete a template issue
     * @param {string} templateId ID of the template
     * @param {string} issueId ID of the issue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueDeleteRaw(requestParameters: IssueDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a template issue
     */
    issueDelete(templateId: string, issueId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get a template issue
     * @param {string} templateId ID of the template
     * @param {string} issueId ID of the issue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueGetRaw(requestParameters: IssueGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Issue>>;

    /**
     * Get a template issue
     */
    issueGet(templateId: string, issueId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Issue>;

    /**
     * 
     * @summary Get a list of the template issues
     * @param {string} templateId ID of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApiInterface
     */
    issueListRaw(requestParameters: IssueListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Issue>>>;

    /**
     * Get a list of the template issues
     */
    issueList(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Issue>>;

}

/**
 * 
 */
export class IssueApi extends runtime.BaseAPI implements IssueApiInterface {

    /**
     * Copy a template issue
     */
    async issueCopyRaw(requestParameters: IssueCopyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Issue>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling issueCopy().'
            );
        }

        if (requestParameters['issueId'] == null) {
            throw new runtime.RequiredError(
                'issueId',
                'Required parameter "issueId" was null or undefined when calling issueCopy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/issues/{issue_id}/copy`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"issue_id"}}`, encodeURIComponent(String(requestParameters['issueId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueCopyRequestToJSON(requestParameters['issueCopyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueFromJSON(jsonValue));
    }

    /**
     * Copy a template issue
     */
    async issueCopy(templateId: string, issueId: string, issueCopyRequest?: IssueCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Issue> {
        const response = await this.issueCopyRaw({ templateId: templateId, issueId: issueId, issueCopyRequest: issueCopyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a template issue
     */
    async issueDeleteRaw(requestParameters: IssueDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling issueDelete().'
            );
        }

        if (requestParameters['issueId'] == null) {
            throw new runtime.RequiredError(
                'issueId',
                'Required parameter "issueId" was null or undefined when calling issueDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/issues/{issue_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"issue_id"}}`, encodeURIComponent(String(requestParameters['issueId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a template issue
     */
    async issueDelete(templateId: string, issueId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.issueDeleteRaw({ templateId: templateId, issueId: issueId }, initOverrides);
    }

    /**
     * Get a template issue
     */
    async issueGetRaw(requestParameters: IssueGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Issue>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling issueGet().'
            );
        }

        if (requestParameters['issueId'] == null) {
            throw new runtime.RequiredError(
                'issueId',
                'Required parameter "issueId" was null or undefined when calling issueGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/issues/{issue_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))).replace(`{${"issue_id"}}`, encodeURIComponent(String(requestParameters['issueId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IssueFromJSON(jsonValue));
    }

    /**
     * Get a template issue
     */
    async issueGet(templateId: string, issueId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Issue> {
        const response = await this.issueGetRaw({ templateId: templateId, issueId: issueId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of the template issues
     */
    async issueListRaw(requestParameters: IssueListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Issue>>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling issueList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/issues`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IssueFromJSON));
    }

    /**
     * Get a list of the template issues
     */
    async issueList(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Issue>> {
        const response = await this.issueListRaw({ templateId: templateId }, initOverrides);
        return await response.value();
    }

}
