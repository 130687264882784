import { Template } from "@easy-templates/types";
import { OptionType } from "components/ui/Select";

import { queryFilterFor } from "lib/query-filter";

const entityQueryFilter = queryFilterFor<OptionType["data"]>((option) => {
  return `${option.data.id} ${option.data.key} ${option.data.name}`;
});

export const filterProject = entityQueryFilter.match;
export const filterIssuetype = entityQueryFilter.match;

const templateQueryFilter = queryFilterFor<OptionType["data"]>(
  ({ data: template }: { data: Template }) =>
    `${template.name} ${template.issuetype.name}`
);

export const filterTemplate = templateQueryFilter.match;
