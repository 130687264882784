import { createUseStyles } from 'react-jss'

export default createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  fieldContainer: {
    flexGrow: 1,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 50,
    paddingTop: 32,
    height: 36,
  },
})
