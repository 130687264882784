import { Jira } from "@easy-templates/types";

type GetEditMeta = {
  projectIds: string[];
};

export const getEditMeta = async (
  requestJira: Jira.AdapterInterface["request"],
  { projectIds }: GetEditMeta
): Promise<Jira.IssueCreateMetadata> => {
  const response = await requestJira<Jira.IssueCreateMetadata>(
    `/rest/api/3/issue/editmeta?projectIds=${projectIds.join(
      ","
    )}&expand=projects.issuetypes.fields`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response;
};
