
import { useAnalytics, UIEvent } from "components/Analytics"
import Button from "components/ui/Button"
import { useAppContext } from "components/AppContextProvider"

type Props = {
  children: React.ReactNode
  href: string
}

export const Link = ({ children, href }: Props) => {
  const { logEvent } = useAnalytics()
  const { core } = useAppContext()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    logEvent(UIEvent.LINK_CLICKED, { href })

    core.openExternalLink(href)
  }

  return (
    <Button appearance="link" spacing="none" onClick={handleClick}>
      {children}
    </Button>
  )
}

export default Link
