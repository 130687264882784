import groupBy from "lodash/groupBy"
import { Template } from "@easy-templates/types"

import SectionMessage from "components/ui/SectionMessage"
import Select from "components/Select"
import Variables from "components/Variables"
import Labeled from "components/Field/Labeled"
import { ProgressTracker } from "components/ui/progress-tracker"
import CreationProgress from "components/ui/progress"

import {
  filterProject,
  // filterIssuetype,
  filterTemplate,
} from "./filters"

import { TemplateHint, ProjectHint } from "./Hints"
import ValidationError from "./ValidationError"
import { StepPage } from "./useHandlers/types"

const Form = ({
  creationResults,
  errorMessage,
  handleProjectChange,
  handleTemplateChange,
  isPrefilling,
  isSubmitting,
  missingFields,
  projects,
  selectedIssueType,
  selectedProject,
  selectedTemplate,
  steps,
  currentStep,
  templates,
  variables,
  warnings,
}) => {
  const templateOptions = Object.entries<Template[]>(
    groupBy(templates, "issuetype.name")
  ).map(([issuetypeName, templates]) => {
    return {
      label: issuetypeName,
      options: templates.map((template) => ({
        ...template,
        iconUrl: template.issuetype.iconUrl,
      })),
    }
  })

  return (
    <>
      <div
        data-testid="create-from-template-selects"
        style={{ paddingBottom: 8 }}
      >
        <ProgressTracker items={steps} />
        {currentStep === StepPage.SELECT && (
          <>
            <Labeled name="project" label="Project" hint={<ProjectHint />}>
              <Select
                // @ts-ignore
                onChange={handleProjectChange}
                value={selectedProject}
                options={projects}
                placeholder="Choose a Project"
                filterOption={filterProject}
                isDisabled={isSubmitting}
                classNamePrefix="project-field"
              />
            </Labeled>
            <Labeled name="template" label="Template" hint={<TemplateHint />}>
              <Select
                // @ts-ignore
                onChange={handleTemplateChange}
                value={selectedTemplate}
                options={templateOptions}
                placeholder="Choose a Template"
                isDisabled={!selectedIssueType || isSubmitting}
                filterOption={filterTemplate}
                classNamePrefix="template-field"
              />
            </Labeled>
            {!!missingFields.length && (
              <ValidationError fields={missingFields} />
            )}
          </>
        )}
        {/* Keep it mounted but hidden in order the form reference to remain */}
        <Variables
          isHidden={currentStep !== StepPage.VARIABLES}
          variables={variables}
          isDisabled={isSubmitting || isPrefilling}
        />
        {currentStep === StepPage.CREATION && (
          <div style={{ paddingTop: 24 }}>
            <CreationProgress items={creationResults} />
          </div>
        )}
      </div>
      {errorMessage && (
        <SectionMessage appearance="error">
          <p>{errorMessage}</p>
        </SectionMessage>
      )}
      {warnings.length > 0 && (
        <SectionMessage appearance="warning">
          {warnings.map((warning: string) => (
            <div>{warning}</div>
          ))}
        </SectionMessage>
      )}
    </>
  )
}

export default Form
