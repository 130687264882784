/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AnalyticsSendRequestAnyOf1 } from './AnalyticsSendRequestAnyOf1';
import {
    AnalyticsSendRequestAnyOf1FromJSON,
    AnalyticsSendRequestAnyOf1FromJSONTyped,
    AnalyticsSendRequestAnyOf1ToJSON,
} from './AnalyticsSendRequestAnyOf1';
import type { AnalyticsSendRequestAnyOf } from './AnalyticsSendRequestAnyOf';
import {
    AnalyticsSendRequestAnyOfFromJSON,
    AnalyticsSendRequestAnyOfFromJSONTyped,
    AnalyticsSendRequestAnyOfToJSON,
} from './AnalyticsSendRequestAnyOf';
import type { AnalyticsSendRequestAnyOf1Payload } from './AnalyticsSendRequestAnyOf1Payload';
import {
    AnalyticsSendRequestAnyOf1PayloadFromJSON,
    AnalyticsSendRequestAnyOf1PayloadFromJSONTyped,
    AnalyticsSendRequestAnyOf1PayloadToJSON,
} from './AnalyticsSendRequestAnyOf1Payload';

/**
 * 
 * @export
 * @interface AnalyticsSendRequest
 */
export interface AnalyticsSendRequest {
    /**
     * Type of the message
     * @type {string}
     * @memberof AnalyticsSendRequest
     */
    type?: AnalyticsSendRequestTypeEnum;
    /**
     * 
     * @type {AnalyticsSendRequestAnyOf1Payload}
     * @memberof AnalyticsSendRequest
     */
    payload?: AnalyticsSendRequestAnyOf1Payload;
}


/**
 * @export
 */
export const AnalyticsSendRequestTypeEnum = {
    Msg: 'MSG',
    Evt: 'EVT'
} as const;
export type AnalyticsSendRequestTypeEnum = typeof AnalyticsSendRequestTypeEnum[keyof typeof AnalyticsSendRequestTypeEnum];


/**
 * Check if a given object implements the AnalyticsSendRequest interface.
 */
export function instanceOfAnalyticsSendRequest(value: object): value is AnalyticsSendRequest {
    return true;
}

export function AnalyticsSendRequestFromJSON(json: any): AnalyticsSendRequest {
    return AnalyticsSendRequestFromJSONTyped(json, false);
}

export function AnalyticsSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticsSendRequest {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'payload': json['payload'] == null ? undefined : AnalyticsSendRequestAnyOf1PayloadFromJSON(json['payload']),
    };
}

export function AnalyticsSendRequestToJSON(value?: AnalyticsSendRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'payload': AnalyticsSendRequestAnyOf1PayloadToJSON(value['payload']),
    };
}

