import React from "react";
import InlineMessage from "components/ui/InlineMessage";
import Debug from "components/Debug";

type Props = {
  label: string;
  type: string;
  value?: unknown;
  schema?: unknown;
};

const UnsupportedField = ({ value, label, type }: Props): JSX.Element => {
  return (
    <div
      style={{
        height: 36,
        lineHeight: "36px",
        paddingLeft: 6,
      }}
    >
      <InlineMessage title="Unsupported field type" type="warning">
        <>
          <p>
            Currently &quot;{label}&quot; field of type &quot;{type}&quot; is
            not supported, the value can&apos;t be changed.
          </p>
          {value && (
            <>
              <p style={{ color: "grey" }}>Current value:</p>
              <Debug value={value} />
            </>
          )}
        </>
      </InlineMessage>
    </div>
  );
};

export default UnsupportedField;
