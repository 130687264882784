export class PerfTimer {
  startTime: number
  endTime: number
  lastReportTime: number

  constructor(public title: string) { }

  start() {
    this.startTime = Date.now()
    this.lastReportTime = Date.now()
  }

  end() {
    this.endTime = Date.now()
  }

  report(message?: string) {
    if (this.endTime) {
      console.info(`${this.title} executed in: ${this.endTime - this.startTime} ms`, message ? `(${message})` : '');
    } else {
      console.info(`${this.title} execution time: ${Date.now() - this.startTime} ms (${Date.now() - this.lastReportTime})`, message ? `(${message})` : '');
    }

    this.lastReportTime = Date.now()
  }

  debug(message: string, data?: unknown) {
    console.debug(`${this.title}: `, message, data)
  }

  warn(message: string, data?: unknown) {
    console.warn(`${this.title}: `, message, data)
  }

  info(message: string, data?: unknown) {
    console.info(`${this.title}: `, message, data)
  }

  finish() {
    this.end()
    this.report()
  }
}

export default PerfTimer
