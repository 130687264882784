import { Jira } from "@easy-templates/types";

type Result<T> = { values: T[]; total: number };

export type Searcher<T> = (
  requestJira: Jira.AdapterInterface["request"],
  offset: number,
  maxResults: number
) => Promise<T>;

export type Getter<T, V> = (result: T) => Result<V>;

// Searches for values page by page
export default class Search<T, V> {
  maxResults: number;
  searcher: Searcher<T>;
  getter: Getter<T, V>;

  constructor(
    public requestJira: Jira.AdapterInterface["request"],
    maxResults?: number
  ) {
    this.maxResults = maxResults || 50;
  }

  setSearcher(searcher: Searcher<T>) {
    this.searcher = searcher;
  }

  setGetter(getter: Getter<T, V>) {
    this.getter = getter;
  }

  async page(page = 1): Promise<Result<V>> {
    if (page < 1) {
      throw new Error("The page must be > 0");
    }

    const startAt = this.maxResults * (page - 1);

    return this.getter(
      await this.searcher(this.requestJira, startAt, this.maxResults)
    );
  }

  async all(): Promise<V[]> {
    const { values, total } = await this.page();

    const totalPages = Math.ceil(total / this.maxResults);

    if (totalPages > 1) {
      const results = await Promise.all(
        [...this.pagesRange(totalPages, 2)].map((page) => this.page(page))
      );

      return results.reduce((allValues, { values }) => {
        return [...allValues, ...values];
      }, values);
    }

    return values;
  }

  pagesRange(end = 1, start = 0, step = 1) {
    function* generateRange() {
      let x = start - step;
      while (x < end) yield (x += step);
    }
    return {
      [Symbol.iterator]: generateRange,
    };
  }
}
