/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents an operation of updating or creating an issue in Jira
 * @export
 * @interface JiraOperation
 */
export interface JiraOperation {
    /**
     * A key of the issue in Jira or an error description
     * @type {string}
     * @memberof JiraOperation
     */
    result?: string;
    /**
     * What did Jira return as a result
     * @type {object}
     * @memberof JiraOperation
     */
    resultData?: object;
    /**
     * 
     * @type {string}
     * @memberof JiraOperation
     */
    status?: JiraOperationStatusEnum;
    /**
     * ID of the issue in Jira at the time of creating a template from it
     * @type {string}
     * @memberof JiraOperation
     */
    originalJiraId?: string;
    /**
     * A payload used for the operation in Jira
     * @type {object}
     * @memberof JiraOperation
     */
    data?: object;
}


/**
 * @export
 */
export const JiraOperationStatusEnum = {
    Todo: 'todo',
    Ok: 'ok',
    Error: 'error',
    InProgress: 'in_progress'
} as const;
export type JiraOperationStatusEnum = typeof JiraOperationStatusEnum[keyof typeof JiraOperationStatusEnum];


/**
 * Check if a given object implements the JiraOperation interface.
 */
export function instanceOfJiraOperation(value: object): value is JiraOperation {
    return true;
}

export function JiraOperationFromJSON(json: any): JiraOperation {
    return JiraOperationFromJSONTyped(json, false);
}

export function JiraOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): JiraOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'result': json['result'] == null ? undefined : json['result'],
        'resultData': json['result_data'] == null ? undefined : json['result_data'],
        'status': json['status'] == null ? undefined : json['status'],
        'originalJiraId': json['original_jira_id'] == null ? undefined : json['original_jira_id'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function JiraOperationToJSON(value?: JiraOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'result': value['result'],
        'result_data': value['resultData'],
        'status': value['status'],
        'original_jira_id': value['originalJiraId'],
        'data': value['data'],
    };
}

