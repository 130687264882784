import { Template, TemplateVariable } from '@easy-templates/types'

import BaseAction from './base'
import { State } from '../types'

const isOptional = ({ required }: TemplateVariable) => !required

export default class FullTemplateLoadedAction extends BaseAction {
  template: Template

  constructor(template: Template) {
    super()
    this.template = template
  }

  apply(state: State): State {
    const variableValues = Object.fromEntries(
      this.template.variables.map(({ label }) => [label.toLocaleLowerCase(), ""])
    )

    return {
      ...state,
      fullSelectedTemplate: this.template,
      isValid: this.template.variables.every(isOptional),
    }
  }
}
