/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Job,
} from '../models/index';
import {
    JobFromJSON,
    JobToJSON,
} from '../models/index';

export interface JobGetRequest {
    jobId: string;
}

/**
 * JobApi - interface
 * 
 * @export
 * @interface JobApiInterface
 */
export interface JobApiInterface {
    /**
     * 
     * @summary Get a Job by ID
     * @param {string} jobId ID of a job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobGetRaw(requestParameters: JobGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>>;

    /**
     * Get a Job by ID
     */
    jobGet(jobId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job>;

}

/**
 * 
 */
export class JobApi extends runtime.BaseAPI implements JobApiInterface {

    /**
     * Get a Job by ID
     */
    async jobGetRaw(requestParameters: JobGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Job>> {
        if (requestParameters['jobId'] == null) {
            throw new runtime.RequiredError(
                'jobId',
                'Required parameter "jobId" was null or undefined when calling jobGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/jobs/{job_id}`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters['jobId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobFromJSON(jsonValue));
    }

    /**
     * Get a Job by ID
     */
    async jobGet(jobId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Job> {
        const response = await this.jobGetRaw({ jobId: jobId }, initOverrides);
        return await response.value();
    }

}
