import { Jira } from "@easy-templates/types"
import { FieldInterface, Context, FieldValue } from "./types"
import ValueFinder from "./value-finder"

type FieldData = {
  isDisabled?: boolean
  name: string
  body: any
}

const variablePattern = (label: string) =>
  new RegExp(`\<\<${label}[^\>]*\>\>`, "gi")

export default class BasicField implements FieldInterface {
  name: string
  data: FieldData
  body: FieldData["body"]
  meta: Jira.FieldMetadata
  valueFinder: any

  constructor(
    data: FieldData,
    public context?: Context,
    valueFinder?: any,
    meta?: any
  ) {
    this.data = data
    this.name = data.name
    this.context = context
    this.meta = meta || {}
    this.body = data.body
    this.valueFinder = valueFinder || new ValueFinder(this.body)
  }

  isSubmitable() {
    if (this.meta.required) {
      return true
    }

    return !this.data.isDisabled && this.isDefined()
  }

  isDefined() {
    const value = this.value()

    if (value === undefined || value === null) {
      return false
    }

    if (Array.isArray(value)) {
      return value.filter((v) => v).length > 0
    }

    if (typeof value === "object") {
      return Object.values(value).filter((v) => v).length > 0
    }

    return true
  }

  isCascading() {
    return false
  }

  value(): FieldValue {
    return this.body
  }

  prefillValue(): FieldValue {
    return this.value()
  }

  applyVariables(): string {
    const cannotApplyVariables =
      typeof this.body !== "string"
      || !this.context?.variableValues

    if (cannotApplyVariables) {
      return this.body
    }

    return Object.entries(this.context.variableValues).reduce(
      (result: string, variable) => {
        const [label, givenValue] = variable
        const value = givenValue || givenValue === 0 ? givenValue : ''

        return result.replace(variablePattern(label), String(value))
      },
      this.body
    )
  }
}
