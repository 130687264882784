import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { TextIcon } from "components/ui/icons"
import { CustomItem } from "components/ui/Menu"
import MoreActions, { Action } from "components/MoreActions"

import { useItemStyles } from "./useStyles"

type Props = {
  id: string
  description: string
  label: string
  isRequired: boolean,
  icon?: React.ReactNode,
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}


const Layout = ({ children, onEdit, onDelete }) => {
  const classes = useItemStyles({})
  return (
    <div className={classes.root}>
      <div className={classes.children} onClick={onEdit}>{children}</div>
      <div className="more-actions">
        <MoreActions>
          <Action onClick={onEdit}>Edit</Action>
          <Action onClick={onDelete}>Delete</Action>
        </MoreActions>
      </div>
    </div>
  )
}

const Item = ({ id, description, label, isRequired, icon, onEdit, onDelete }: Props) => {
  const classes = useItemStyles({ isRequired })

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
  }

  const handleEdit = () => {
    onEdit(id)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CustomItem
        iconBefore={icon}
        component={Layout}
        description={description}
        testId={`variable-item-${id}`}
        onEdit={handleEdit}
        onDelete={handleDelete}
      >
        <div className={classes.label}>{label}</div>
      </CustomItem>
    </div>
  )
}

export default Item
