import { useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import {
  Page,
  AnalyticsProvider,
  ErrorBoundary,
  useAppContext,
  ManageTemplatesPage,
  EditTemplatePage,
  EditTemplateDetails,
  EditTemplateIssue,
  EditTemplateVariables,
  NewTemplateDialog,
  ApplyTemplateDialog,
  NewIssuePage,
  NewIssueDialog,
  GetStartedPage,
  NotFoundPage,
} from "@easy-templates/ui"

import Analytics from "./adapter/analytics"

const PageWrapper = ({ children }: { children: JSX.Element }) => {
  const { core } = useAppContext()

  useEffect(() => {
    if (!core) {
      return
    }

    setTimeout(() => {
      core.resizeView()
    }, 200)
  }, [core])

  return <div style={{ minHeight: 900 }}>{children}</div>
}


const useAnalytics = (host, accountId) => {
  const analytics = new Analytics(host, accountId)

  const instrument = (eventType, fn, eventPropertiesIn?) => (
    (params) => {
      analytics.logEvent(eventType, eventPropertiesIn)
      return fn(params)
    }
  )

  const logEvent = (eventType, eventPropertiesIn) => {
    return analytics.logEvent(eventType, eventPropertiesIn)
  }

  return { logEvent, instrument }
}

const App = (): JSX.Element => {
  const { accountId, onMount, host } = useAppContext()
  const { instrument, logEvent } = useAnalytics(host, accountId)

  useEffect(() => {
    onMount()
  }, [])

  return (
    <AnalyticsProvider userId={accountId} host={host} logEvent={logEvent} instrument={instrument}>
      <ErrorBoundary userId={host}>
        <Page>
          <Routes>
            <Route
              path="/post-install"
              element={
                <PageWrapper>
                  <GetStartedPage />
                </PageWrapper>
              }
            />
            <Route path="/issue/new" element={<NewIssueDialog />} />
            <Route
              path="/pages/issue/new"
              element={
                <PageWrapper>
                  <NewIssuePage />
                </PageWrapper>
              }
            />
            <Route path="/template/new" element={<NewTemplateDialog />} />
            <Route
              path="/template/edit/"
              element={
                <PageWrapper>
                  <EditTemplatePage />
                </PageWrapper>
              }
            >
              <Route
                path=":templateId"
                element={<EditTemplateDetails />}
              />
              <Route
                path=":templateId/:section/:issueId"
                element={<EditTemplateIssue />}
              />
              <Route
                path=":templateId/:section"
                element={<EditTemplateVariables />}
              />
            </Route>
            <Route path="/template/apply" element={<ApplyTemplateDialog />} />
            <Route
              path="/templates/:projectId?"
              element={
                <PageWrapper>
                  <ManageTemplatesPage />
                </PageWrapper>
              }
            />
            <Route
              path="/start"
              element={
                <PageWrapper>
                  <GetStartedPage />
                </PageWrapper>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Page>
      </ErrorBoundary>
    </AnalyticsProvider>
  )
}

export default App
