import { TemplateScope, Jira } from "@easy-templates/types";

import HiddenIcon from "components/ui/icons/HiddenIcon";
import WorldIcon from "components/ui/icons/WorldIcon";

import TagGroup from "components/ui/tag-group";
import Tag from "components/ui/tag";
import Avatar from "components/ui/Avatar";
import Tooltip from "components/ui/Tooltip";

const IconWrapper = ({ children }) => (
  <div style={{ paddingTop: 2, paddingLeft: 3 }}>{children}</div>
);

const Projects = ({ items }) => {
  if (items.length === 0) {
    return (
      <Tooltip content="This template cannot be used as it is scoped to projects but no projects selected">
        <Tag
          appearance="rounded"
          key="invisible"
          text="Invisible"
          elemBefore={
            <IconWrapper>
              <HiddenIcon label="Invisible" size="small" />
            </IconWrapper>
          }
          isRemovable={false}
          testId="invisible-tag"
        />
      </Tooltip>
    );
  }

  return (
    <TagGroup>
      {items.map((project: Jira.Project) => {
        const iconUrl = project.avatarUrls ? project.avatarUrls["16x16"] : null;

        return (
          <Tooltip content={project.name} key={project.id}>
            <Tag
              appearance="rounded"
              text={project.key}
              isRemovable={false}
              elemBefore={iconUrl && <Avatar size="xsmall" src={iconUrl} />}
              testId={`project-${project.id}-tag`}
            />
          </Tooltip>
        );
      })}
    </TagGroup>
  );
};

const Scope = ({ type, projects }) => {
  if (type === TemplateScope.PROJECTS) {
    return <Projects items={projects} />;
  }

  return (
    <Tooltip content="This template can be used globally">
      <Tag
        appearance="rounded"
        key="global"
        text="Global"
        isRemovable={false}
        elemBefore={
          <IconWrapper>
            <WorldIcon label="Global" size="small" />
          </IconWrapper>
        }
        testId="global-tag"
      />
    </Tooltip>
  );
};

export default Scope;
