import { useState } from "react";
import { Label } from "components/ui/FieldBase";
import Popup from "components/ui/Popup";
import Button from "components/ui/Button";
import InfoIcon from "components/ui/icons/QuestionCircleIcon";

import useStyles from "./useStyles";

type Props = {
  label: string;
  name: string;
  isRequired?: boolean;
  hint?: React.ReactNode;
  children: React.ReactNode;
};

const Labeled = ({
  label,
  name,
  isRequired,
  hint,
  children,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {/* @ts-ignore */}
        <Label
          isFirstChild={false}
          htmlFor={name}
          label={label || name}
          isRequired={isRequired}
        />
        {hint && (
          <Popup
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            placement="auto"
            zIndex={999}
            content={() => <div style={{ padding: 18 }}>{hint}</div>}
            trigger={(triggerProps) => (
              <Button
                {...triggerProps}
                className={classes.hint}
                appearance="subtle-link"
                spacing="compact"
                onClick={() => setIsOpen(!isOpen)}
                iconBefore={
                  // @ts-ignore
                  <InfoIcon
                    size="small"
                    label={`${label || name} info`}
                    primaryColor="#a5adba"
                  />
                }
              ></Button>
            )}
          />
        )}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Labeled;
