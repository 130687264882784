import { SyntheticEvent } from "react"
import { IssueField } from "@easy-templates/types"
import { ValidationError } from "@easy-templates/lib"

import Toggle from "components/ui/Toggle"
import Field from "components/Field"
import { useAppContext } from "components/AppContextProvider"
import { useVariablesContext } from "components/VariablesProvider"


import useUpdateField from "./useUpdateField"
import useStyles from "./useStyles"

type Props = {
  id: string
  issueId: string
  templateId: string
  name: string
  meta: IssueField["meta"]
  isDisabled?: boolean
  isVirtual?: boolean
  value: unknown
}

const FieldSection = ({
  id,
  issueId,
  templateId,
  name,
  meta,
  isDisabled,
  isVirtual,
  value,
}: Props): JSX.Element => {
  const { core } = useAppContext()
  const { onVariableRequest, variables } = useVariablesContext()
  const { updateFieldValue, toggleField, isProcessing } = useUpdateField({
    onError: (error: Error | ValidationError) => {
      let validationErrorDetails: string

      if (error instanceof ValidationError) {
        const errors = error.errors["/value"]

        if (Array.isArray(errors) && errors.length > 0) {
          validationErrorDetails = errors[0].details
        }
      }

      core.showFlag({
        id: "field-update-error",
        title: "Field update error",
        description: validationErrorDetails || error.message,
        type: "error",
      })
    },
  })

  const classes = useStyles()
  const key = `${issueId}-${id}`

  const fieldValueHandler = (id: string) => (value: unknown) => {
    updateFieldValue({ issueId, templateId, name: id, value })
  }

  const fieldToggleHandler =
    (id: string) => (event: SyntheticEvent<HTMLInputElement>) =>
      toggleField({
        issueId,
        templateId,
        name: id,
        isEnabled: event.currentTarget.checked,
      })

  return (
    <div key={key} className={classes.root}>
      <div className={classes.fieldContainer} data-testid={`issue-field-${name}`}>
        <Field
          name={name}
          label={name}
          schema={meta.schema}
          allowedValues={meta.allowedValues}
          disabled={isProcessing || isDisabled}
          autoCompleteUrl={meta.autoCompleteUrl}
          value={value}
          onConfirm={fieldValueHandler(id)}
          required={meta.required}
          onVariableRequest={onVariableRequest}
          variables={variables}
          testId={`inline-${name}`}
          validateVariables={true}
        />
      </div>
      <div className={classes.actionContainer}>
        <Toggle
          isDisabled={isVirtual}
          onChange={fieldToggleHandler(id)}
          isChecked={!isDisabled && !isVirtual}
          name="disable"
        />
      </div>
    </div>
  )
}

export default FieldSection
