/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Issuetype } from './Issuetype';
import {
    IssuetypeFromJSON,
    IssuetypeFromJSONTyped,
    IssuetypeToJSON,
} from './Issuetype';

/**
 * 
 * @export
 * @interface TemplateListItem
 */
export interface TemplateListItem {
    /**
     * Identifier of the template
     * @type {string}
     * @memberof TemplateListItem
     */
    readonly id: string;
    /**
     * Name of the template
     * @type {string}
     * @memberof TemplateListItem
     */
    name: string;
    /**
     * Whether child issues and sub-tasks should be created
     * @type {boolean}
     * @memberof TemplateListItem
     */
    createChildren: boolean;
    /**
     * Identifier of the main (a.k.a. root) issue in the template
     * @type {string}
     * @memberof TemplateListItem
     */
    rootIssueId: string;
    /**
     * 
     * @type {Date}
     * @memberof TemplateListItem
     */
    insertedAt: Date;
    /**
     * 
     * @type {Issuetype}
     * @memberof TemplateListItem
     */
    issueType: Issuetype;
    /**
     * Template scope (0 - global, 1 - projects)
     * @type {number}
     * @memberof TemplateListItem
     */
    scope: TemplateListItemScopeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateListItem
     */
    scopeValue: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof TemplateListItem
     */
    updatedAt: Date;
    /**
     * Account ID of the user who created the template
     * @type {string}
     * @memberof TemplateListItem
     */
    createdBy: string | null;
}


/**
 * @export
 */
export const TemplateListItemScopeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type TemplateListItemScopeEnum = typeof TemplateListItemScopeEnum[keyof typeof TemplateListItemScopeEnum];


/**
 * Check if a given object implements the TemplateListItem interface.
 */
export function instanceOfTemplateListItem(value: object): value is TemplateListItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('createChildren' in value) || value['createChildren'] === undefined) return false;
    if (!('rootIssueId' in value) || value['rootIssueId'] === undefined) return false;
    if (!('insertedAt' in value) || value['insertedAt'] === undefined) return false;
    if (!('issueType' in value) || value['issueType'] === undefined) return false;
    if (!('scope' in value) || value['scope'] === undefined) return false;
    if (!('scopeValue' in value) || value['scopeValue'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    return true;
}

export function TemplateListItemFromJSON(json: any): TemplateListItem {
    return TemplateListItemFromJSONTyped(json, false);
}

export function TemplateListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateListItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'createChildren': json['createChildren'],
        'rootIssueId': json['rootIssueId'],
        'insertedAt': (new Date(json['insertedAt'])),
        'issueType': IssuetypeFromJSON(json['issueType']),
        'scope': json['scope'],
        'scopeValue': json['scopeValue'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdBy': json['createdBy'],
    };
}

export function TemplateListItemToJSON(value?: Omit<TemplateListItem, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'createChildren': value['createChildren'],
        'rootIssueId': value['rootIssueId'],
        'insertedAt': ((value['insertedAt']).toISOString()),
        'issueType': IssuetypeToJSON(value['issueType']),
        'scope': value['scope'],
        'scopeValue': value['scopeValue'],
        'updatedAt': ((value['updatedAt']).toISOString()),
        'createdBy': value['createdBy'],
    };
}

