/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Issuetype
 */
export interface Issuetype {
    /**
     * 
     * @type {string}
     * @memberof Issuetype
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Issuetype
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Issuetype
     */
    iconUrl?: string;
}

/**
 * Check if a given object implements the Issuetype interface.
 */
export function instanceOfIssuetype(value: object): value is Issuetype {
    return true;
}

export function IssuetypeFromJSON(json: any): Issuetype {
    return IssuetypeFromJSONTyped(json, false);
}

export function IssuetypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Issuetype {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'iconUrl': json['iconUrl'] == null ? undefined : json['iconUrl'],
    };
}

export function IssuetypeToJSON(value?: Issuetype | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'iconUrl': value['iconUrl'],
    };
}

