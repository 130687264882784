import { BasicFieldType, CustomFieldType, SystemFieldType } from '@easy-templates/lib'

export type Schema = {
  type?: string;
  system?: string;
  custom?: string;
  customId?: number;
};

export type ComponentType =
  | "textArea"
  | "numberField"
  | "textField"
  | "cascadingSelect"
  | "description"
  | "singleSelect"
  | "multiSelect"
  | "dateSelect"
  | "dateTimeSelect"
  | "checkboxes"
  | "radiobuttons"
  | "checkbox"
  | "parent"
  | "toggle"
  // "userPicker" |
  | "issueLinks"
  | "epicLink"
  | "sprint"
  | "hidden"
  | "unimplemented";

const hiddenFields = new Set(["attachment"]);

const getComponentFromSchema = (schema: Schema): ComponentType => {
  if (
    hiddenFields.has(String(schema.system)) ||
    Object.keys(schema).length === 0
  )
    return "hidden";

  if (schema.system === SystemFieldType.Parent) {
    return "parent";
  }

  if (schema.custom === CustomFieldType.AgileSprint) {
    return "sprint";
  }

  if (schema.system === SystemFieldType.Description) {
    return "description"
  }

  if (
    schema.custom === CustomFieldType.TextArea
  ) {
    return "textArea";
  }

  if (
    schema.custom === CustomFieldType.MultiCheckboxes
  ) {
    return "checkboxes";
  }

  if (
    schema.custom === CustomFieldType.RadioButtons
  ) {
    return "radiobuttons";
  }

  if (schema.custom === CustomFieldType.EpicLink) {
    return "epicLink";
  }

  if (schema.custom === CustomFieldType.Team || schema.custom === CustomFieldType.TeamLegacy) {
    return "singleSelect";
  }

  if (schema.system === SystemFieldType.IssueLinks) {
    return "issueLinks";
  }

  if (schema.type === BasicFieldType.String) {
    return "textField";
  }

  if (schema.type === BasicFieldType.Number) {
    return "numberField";
  }

  const singleSelectSet = new Set([
    SystemFieldType.Priority.toString(),
    SystemFieldType.Project.toString(),
    SystemFieldType.IssueType.toString(),
    SystemFieldType.User.toString(),
    SystemFieldType.Option.toString(),
  ]);

  // if(schema.type === "user") {
  //   return "userPicker"
  // }

  if (singleSelectSet.has(schema.type)) {
    return "singleSelect";
  }

  if (
    schema.custom === CustomFieldType.CascadingSelect
  ) {
    return "cascadingSelect";
  }

  if (
    (schema.type === "array" ||
      schema.custom === CustomFieldType.SingleSelect) &&
    schema.system !== SystemFieldType.Attachment &&
    schema.system !== SystemFieldType.IssueLinks) {
    return "multiSelect";
  }

  if (schema.type === "date") {
    return "dateSelect";
  }

  if (schema.type === "datetime") {
    return "dateTimeSelect";
  }

  if (schema.type === "toggle") {
    return "toggle";
  }

  if (schema.type === "checkbox") {
    return "checkbox";
  }

  return "unimplemented";
};

export default getComponentFromSchema;
