export enum JobStatus {
  SUCCESS = "success",
  ERROR = "error",
  IN_PROGRESS = "in_progress",
}

export enum SubJobStatus {
  TODO = "todo",
  OK = "ok",
  ERROR = "error",
  IN_PROGRESS = "in_progress",
}

export type SubJob = {
  issueId: string;
  status: SubJobStatus;
  result: string;
};

export type Job = {
  id: string;
  status: JobStatus;
  result: string;
  results: { [issueId: string]: SubJob };
};
