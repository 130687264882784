import Button from "@atlaskit/button";
import MoreIcon from "@atlaskit/icon/glyph/more";

const Trigger = ({ triggerRef, ...props }): JSX.Element => (
  <span data-testid="templates-list__item__actions">
    <Button
      {...props}
      style={{ height: 20 }}
      spacing="compact"
      appearance="subtle"
      ref={triggerRef}
    >
      {/* @ts-ignore */}
      <MoreIcon label="More" size="small" />
    </Button>
  </span>
);

export default Trigger;
