import { useQueryClient, useMutation } from "@tanstack/react-query"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from "lib/queryKeys"

type Params = {
  templateId: string
  issueId: string
  name: string
}

const useCloneIssue = () => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async ({ templateId, issueId, name }: Params) => {
      const result = await core.copyTemplateIssue(templateId, issueId, name)

      if (!result.isOk) {
        throw new Error(result.mainMessage())
      }

      return result.data
    },
    onSuccess: (_issue, { templateId }: Params) => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.details(templateId),
      })
    },
  })

  return { cloneIssue: mutateAsync, isLoading }
}

export default useCloneIssue
