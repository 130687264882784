import React from "react";

import Help from "components/Help";
import Spinner from "components/ui/Spinner";
import SupportUkraine from "components/SupportUkraine";

import useStyles from "./useStyles";

type Props = {
  actions: React.ReactNode;
  barContent?: React.ReactNode;
  children: React.ReactNode;
  header: React.ReactNode;
  toolbar?: React.ReactNode;
  isLoading?: boolean;
};

const DialogLayout = ({
  actions,
  children,
  header,
  isLoading,
  toolbar,
  barContent,
}: Props): JSX.Element => {
  const classes = useStyles({ isLoading });

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.spinner}>
          <Spinner size="xlarge" testId="dialog-loading-spinner" />
        </div>
      )}

      <div className={classes.container}>
        <div className={classes.title}>{header}</div>
        <div className={classes.barContent}>{barContent}</div>
        <div className={classes.content}>{!isLoading && children}</div>
        <div className={classes.footer}>
          <SupportUkraine />
          <div className={classes.buttons}>{actions}</div>
        </div>
      </div>
      <div className={classes.toolbar}>{toolbar || <Help />}</div>
    </div>
  );
};

export default DialogLayout;
