import { TemplateScope, Template, Jira } from "@easy-templates/types";
import { State } from "../types";

export default class BaseAction {
  getProjectIssuetypes(
    project: Jira.Project | null | undefined,
    templates: Template[]
  ): Jira.IssueTypeDetails[] {
    if (!project || templates.length < 1) {
      return [];
    }

    const allIssuetypeIds = templates.map(({ issuetype }) => issuetype?.id);

    return project.issueTypes.filter(({ id }) => allIssuetypeIds.includes(id));
  }

  getIssuetypeTemplates(
    currentIssuetype: Jira.IssueTypeDetails | null,
    allTemplates: Template[]
  ): Template[] {
    if (!currentIssuetype || allTemplates.length < 1) {
      return [];
    }

    return allTemplates.filter(({ issuetype }) => {
      if (!issuetype) {
        return false;
      }

      return issuetype.id === currentIssuetype.id;
    });
  }

  getIssueCreateMeta(
    projectId: string,
    issueTypeId: string,
    issueCreateMeta: Jira.IssueCreateMetadata
  ): Jira.IssueTypeIssueCreateMetadata["fields"] | undefined {
    const project = issueCreateMeta.projects.find(({ id }) => id === projectId);

    return project?.issuetypes.find(({ id }) => id === issueTypeId)?.fields;
  }

  getProjectTemplates(project: Jira.Project, templates: Template[]) {
    const issuetypes = this.getProjectIssuetypes(project, templates);

    const issuetypeIds = issuetypes.map(({ id }) => id);

    return templates.filter(({ issuetype, scope, scopeValue }) => {
      if (!issuetype) {
        return false;
      }

      if (scope === TemplateScope.PROJECTS && !scopeValue.includes(project.id)) {
        return false
      }

      return issuetypeIds.includes(issuetype.id);
    });
  }

  apply(state: State): State {
    console.error("Action not implemented!");
    return state;
  }
}
